import {
  Grid, Theme,
} from '@mui/material';
import { ReactComponent as ApplePodcastIcon } from '../../assets/images/apple_podcast.svg';
import { ReactComponent as GooglePodcastIcon } from '../../assets/images/google_podcast.svg';
import { ReactComponent as RssFeedIcon } from '../../assets/images/rss_feed.svg';
import { ReactComponent as SpotifyIcon } from '../../assets/images/spotify.svg';
import { ReactComponent as WebIcon } from '../../assets/images/web_podcast.svg';
import {
  ContentPodcast, PodcastChannelName, PodcastDestination,
} from '../../common/models/content/Content';
import PodcastChannelOptionsView from './PodcastChannelOptionsView';
import { useTracking } from '../../common/useTracking';
import { ContentEventType } from '../../common/constants';
import { useAppSelector } from '../../store/hooks';
import { selectMemberSession } from '../../store/slices/onboardingSlice';

interface PodcastListenSubscribeSheetProps {
  contentId: number
  contentCreatorName?: string | null
  contentTitle?: string
  podcast?: ContentPodcast
}

export default function PodcastListenSubscribeSheet(
  props: PodcastListenSubscribeSheetProps,
) {
  const trackEvent = useTracking();
  const { memberId } = useAppSelector(selectMemberSession);

  const firePodcastInteractionEvent = (channel: string, options?: {
    [k: string]: unknown;
  }) => {
    trackEvent({
      optimusEventType: ContentEventType,
      optimusEvents: [
        {
          name: 'content_podcast_interaction',
          detail: {
            channel: PodcastChannelName[channel as keyof typeof PodcastChannelName],
            contentId: props.contentId,
            memberId,
            podcastTitle: props.contentTitle || null,
            contentCreatorName: props.contentCreatorName || null,
            ...options,
          },
        },
      ],
    });
  };

  const openPodcast = (destination: PodcastDestination) => {
    firePodcastInteractionEvent(destination.platform);
    window.open(destination.url, '_blank');
  };

  const platforms = {
    web: {
      name: PodcastChannelName.web,
      logo: () => <WebIcon />,
    },
    spotify: {
      name: PodcastChannelName.spotify,
      logo: () => <SpotifyIcon />,
    },
    apple: {
      name: PodcastChannelName.apple,
      logo: () => <ApplePodcastIcon />,
    },
    google: {
      name: PodcastChannelName.google,
      logo: () => <GooglePodcastIcon />,
    },
    rss: {
      name: PodcastChannelName.rss,
      logo: () => <RssFeedIcon />,
    },
  };

  return (
    <Grid container textAlign={'center'} margin={0} marginTop={0}
      columnGap={{
        xs: 2, sm: 2, md: 2, lg: 2,
      }}
      sx={(theme: Theme) => ({
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 0,
        },
      })}
    >
      {props.podcast && props.podcast.destinations
        .filter((item) => item.platform.toLowerCase() !== 'google')
        .map((item, index) => {
          const platform = platforms[item.platform as keyof typeof platforms] || platforms.web;
          return (
            <Grid item
              sx={(theme: Theme) => ({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexBasis: '30%',
                width: '31%',
                margin: '0px',
                [theme.breakpoints.down('sm')]: {
                  width: '140px',
                  flexBasis: 'calc(50% - 10px)',
                },
              })}
              key={index}
              onClick={() => openPodcast(item)}
            >
              <PodcastChannelOptionsView
                Logo={platform.logo}
                channelName={platform.name}
              />
            </Grid>
          );
        })}
    </Grid >
  );
}
