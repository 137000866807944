import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {
  Container,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CallToActionButton from '../../../common/CallToActionButton';
import { ReactComponent as Step1Icon } from '../../../assets/images/step1_icon.svg';
import { ReactComponent as Step2Icon } from '../../../assets/images/step2_icon.svg';
import { ReactComponent as Step3Icon } from '../../../assets/images/step3_icon.svg';
import { ReactComponent as HomedepotLogo } from '../../../assets/images/homedepot_logo.svg';
import { useSolutionOrg } from '../../../config/useSolutionOrg';
import { useCompanyEnrollmentInfo } from '../../../common/useCompanyEnrollmentInfo';
import { useLogo } from '../../../config/useLogo';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';

export function OnboardHDHome() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isIframe } = useSolutionOrg();
  const { secondaryOne: Logo } = useLogo();
  const trackOnboardEvent = useOnboardEventTracking();

  // check company enrollment status
  useCompanyEnrollmentInfo({ calledFromOnboardingComponent: false });

  const navigateToHomeDepot = () => {
    trackOnboardEvent('home_depot_complete_setup_clicked');
    // this is an iframe
    if (isIframe) {
      window.parent.postMessage({
        payload: { hideOptimusApp: true },
        type: 'OPTIMUS_HIDE_REQUEST',
      }, '*');
    }
  };

  return (
    <Container maxWidth='xs'
      sx={() => ({
        textAlign: 'center',
        paddingTop: theme.spacing(3),
      })}
      >
        {Logo && <Logo style={{
          marginBottom: theme.spacing(3),
          width: '60%',
          textAlign: 'center',
        }}
          />}
      <Box>
        <Box>
          <Typography sx={{
            mt: theme.spacing(2),
            textAlign: 'center',
            paddingRight: theme.spacing(5),
            paddingLeft: theme.spacing(5),
          }}>{t('home.headingEdge')}</Typography>
          <Box
            sx={{
              width: '100%',
              mt: theme.spacing(0.25),
            }}
          >
            <Stack
              spacing={0}
              sx={() => ({
                borderRadius: theme.spacing(1),
                backgroundColor: theme.palette.common.white,
                padding: theme.spacing(2),
                marginBottom: theme.spacing(5),
                marginTop: theme.spacing(2),
              })}
            >
              <Box sx={{ display: 'flex', paddingLeft: '12px' }}>
                <Box>
                  <HomedepotLogo />
                </Box>
                <Box sx={{ marginLeft: '15px', textAlign: 'left' }}>
                  <Typography variant='body4'>{t('home.descriptionEdge1')}</Typography>
                  <Typography variant='body4'>{t('home.descriptionEdge2')}</Typography>
                  <Typography sx={{ marginTop: '0px' }}>{t('home.descriptionEdge3')}</Typography>
                </Box>
              </Box>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem sx={{
                  border: '1px solid #DADADA',
                  borderRadius: '13px',
                }}>
                  <ListItemAvatar>
                    <Step1Icon />
                  </ListItemAvatar>
                  <ListItemText primary={<div style={{ fontSize: '16px', marginTop: '0px', marginBottom: '0px' }}><div style={{ fontSize: '11px', color: theme.palette.grey56.main, fontWeight: 700 }}>{t('home.step1.heading')}</div><div><span style={{ fontWeight: '600' }}>{t('home.step1.description1')}</span> {t('home.step1.description2')} <span style={{ fontWeight: '600' }}>{t('home.step1.description3')}</span> {t('home.step1.description4')}</div> <div style={{ fontSize: '14px', color: theme.palette.grey56.main, fontWeight: 400 }}>{t('home.step1.description5')}</div></div>} />
                </ListItem>
                <ListItem sx={{
                  border: '1px solid #DADADA',
                  borderRadius: '13px',
                  marginTop: '8px',
                }}>
                  <ListItemAvatar>
                    <Step2Icon />
                  </ListItemAvatar>
                  <ListItemText primary={<div style={{ fontSize: '16px', marginTop: '0px', marginBottom: '0px' }}><div style={{ fontSize: '11px', color: theme.palette.grey56.main, fontWeight: 700 }}>{t('home.step2.heading')}</div><div style={{ fontWeight: '600' }}>{t('home.step2.description1')}</div> <div style={{ fontSize: '14px', color: theme.palette.grey56.main, fontWeight: 400 }}>{t('home.step2.description2')}</div></div>} />
                </ListItem>
                <ListItem sx={{
                  border: '1px solid #DADADA',
                  borderRadius: '13px',
                  marginTop: '8px',
                }}>
                  <ListItemAvatar>
                    <Step3Icon />
                  </ListItemAvatar>
                  <ListItemText primary={<div style={{ fontSize: '16px', marginTop: '0px', marginBottom: '0px' }}><div style={{ fontSize: '11px', color: theme.palette.grey56.main, fontWeight: 700 }}>{t('home.step3.heading')}</div><div><span style={{ fontWeight: '600' }}>{t('home.step3.descriptionEdge1')}</span> {t('home.step3.descriptionEdge2')} <span style={{ fontWeight: '600' }}>{t('home.step3.descriptionEdge2b')}</span></div> <div style={{ fontSize: '14px', color: theme.palette.grey56.main, fontWeight: 400 }}>{t('home.step3.descriptionEdge3')}</div></div>} />
                </ListItem>
              </List>
              <Box>
                <CallToActionButton
                  text={t('button.completeSetup') as string}
                  onBtnClick={navigateToHomeDepot}
                  sx={() => ({
                    position: 'static',
                    width: '100%',
                    maxWidth: theme.spacing(45), // 360px
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                    background: theme.palette.secondary.main,
                    borderRadius: '30px',
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.main,
                    },
                    [theme.breakpoints.up('sm')]: {
                      width: '100%',
                      marginLeft: '0px',
                    },
                  })}
                />
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
