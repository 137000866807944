import {
  Box, CircularProgress, Typography, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as JoistRewardsMainLogo }
  from '../assets/images/joist_rewards_main_logo.svg';

export function LoadingComponent() {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box sx={{
      background: theme.palette.color5.main,
      position: 'absolute',
      zIndex: '999',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '85vh',
      justifyContent: 'center',
    }}>
      <Box>
        <Box sx={{ textAlign: 'center' }}>
          <JoistRewardsMainLogo />
        </Box>
        <Typography sx={{ fontSize: '32px', fontWeight: 700 }}>{t('onboarding.loading.description')}</Typography>
        <Box sx={{ textAlign: 'center', marginTop: '16px' }}>
          <CircularProgress color="success" size={55} />
        </Box>
      </Box>
    </Box>
  );
}
