/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, MenuItem, Select, SelectChangeEvent, Theme, Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { QuestionOption } from './models/Question';

type GroupDropdownComponentProps = {
  subTitle?: string;
  options?: QuestionOption[];
  onChange?: (...event: unknown[]) => void;
  defaultValues?: QuestionOption[];
  borderStyle?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    borderRadius: '13px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.white,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.common.white} !important`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.common.white} !important`,
    },
    '&.Mui-focused': {
      borderColor: `${theme.palette.common.white} !important`,
    },
  },
  borderRoot: {
    width: '100%',
    borderRadius: '13px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.color2.main,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.color2.main} !important`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.color2.main} !important`,
    },
    '&.Mui-focused': {
      borderColor: `${theme.palette.color2.main} !important`,
    },
  },
}));

export default function GroupDropdownComponent({
  subTitle,
  options,
  onChange,
  defaultValues,
  borderStyle,
}: GroupDropdownComponentProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const [option1, setOption1] = useState<QuestionOption | undefined>(defaultValues?.find(
    (item) => item.order === 1,
  ));
  const [option2, setOption2] = useState<QuestionOption | undefined>(defaultValues?.find(
    (item) => item.order === 2,
  ));

  const optionsAvailable1 = options;
  const optionsAvailable2 =
    useMemo(() => options?.filter((item) => item.value !== option1?.value), [option1]);

  const reOrderOptions =
    () => {
      const optionsArray = Array.isArray(defaultValues) && defaultValues.length !== 0 ?
        defaultValues : options;
      const tempResult = optionsArray?.filter((item) => item.value !== option1?.value &&
        item.value !== option2?.value);
      if (option1) {
        tempResult?.unshift(option1);
      }
      if (option2) {
        tempResult?.splice(1, 0, option2);
      }

      // set the new "order"
      const result = tempResult?.map((item: QuestionOption, index: number) => (
        {
          display: item.display,
          value: item.value,
          order: index + 1,
        }
      ));
      return result;
    };

  useEffect(() => {
    // dispatch new order to parent component
    if (onChange && option1) {
      onChange(reOrderOptions());
    }
  }, [option1, option2]);

  return (
    <Box
      sx={{
        minWidth: 275,
        width: '100%',
      }}
    >
      <Select
        displayEmpty
        defaultValue={defaultValues?.find(
          (item) => item.order === 1,
        )?.value || ''}
        className={borderStyle ? classes.borderRoot : classes.root}
        onChange={(e: SelectChangeEvent<any>) => {
          setOption1(options?.find((option) => option.value === e.target.value));
          if (option2?.value === e.target.value) {
            setOption2(undefined);
          }
        }
        }
        inputProps={{
          sx: {
            borderRadius: '13px !important',
          },
        }}
      >
        <MenuItem value='' disabled>{t('onboarding.common.selectPlaceholder')}</MenuItem>
        {optionsAvailable1?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.display}
          </MenuItem>
        ))}
      </Select>
      <Typography sx={{
        mt: 3,
        textAlign: 'left',
        mb: '2px',
        color: theme.palette.color2.main,
      }}>
        {subTitle}
      </Typography>
      <Select
        displayEmpty
        defaultValue={defaultValues?.find(
          (item) => item.order === 2,
        )?.value || ''}
        className={borderStyle ? classes.borderRoot : classes.root}
        value={option2?.value || ''}
        onChange={(e: SelectChangeEvent<any>) => setOption2(
          options?.find(
            (option) => option.value === e.target.value,
          ),
        )}
        inputProps={{
          sx: {
            borderRadius: '13px !important',
          },
        }}
      >
        <MenuItem value='' disabled>{t('onboarding.common.selectPlaceholder')}</MenuItem>
        {optionsAvailable2?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.display}
          </MenuItem>
        ))}
      </Select>

    </Box>
  );
}
