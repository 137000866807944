import {
  Container, Divider, Theme, useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentHeroBanner from './ContentHeroBanner';
import ContentArticleView from './ContentArticleView';
import ContentCardHeader from './ContentCardHeader';
import * as contentAPI from '../../api/contentAPI';
import { Content, ContentHeroFormat } from '../../common/models/content/Content';
import { ContentViewHomeSkeleton } from './ContentViewHomeSkeleton';

const containerStyle = (theme: Theme) => ({
  marginTop: '8px',
  paddingBottom: '48px',
} as const);

interface ContentViewHomeProps {
  backUrl: string;
}

export function ContentViewHome({ backUrl }: ContentViewHomeProps) {
  const { id } = useParams();
  const theme = useTheme();
  const [content, setContent] = useState<Content>();

  const getImageUrl = (url: string | undefined) => {
    const imageBaseUrl = process.env.REACT_APP_STRAPI_URL;
    if (url && !url.includes('http')) {
      return imageBaseUrl?.concat(url);
    }
    return url;
  };

  useEffect(() => {
    contentAPI.getContentById(Number(id))
      .then((res: Content) => {
        setContent(res);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHeroFormat = () => (
    content?.attributes?.hero?.data?.attributes?.formats.medium ||
    content?.attributes?.hero?.data?.attributes?.formats.small ||
    content?.attributes?.hero?.data?.attributes?.formats.thumbnail
  ) as ContentHeroFormat;

  return (
    <Container maxWidth={'sm'} sx={[containerStyle]}>
      {!content ? <ContentViewHomeSkeleton /> :
        (<Box sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '0px',
        }}>
          <Box sx={{ marginBottom: '20px' }}>
            <Box>
              <ContentHeroBanner
                banner={{
                  url: getImageUrl(
                    getHeroFormat()?.url,
                  ),
                  width: getHeroFormat()?.width,
                  height: getHeroFormat()?.height,
                }
                }
                title={content?.attributes?.type}
                heading={content?.attributes?.title}
                contentId={content?.id as number}
                isContentDetailScreen={true}
                backUrl={backUrl}
              />
            </Box>
            <Box sx={{ padding: '8px 0' }}>
              <ContentCardHeader
                heading={content?.attributes?.content_owner?.data?.attributes?.display_name}
                publishedDate={content?.attributes?.source_published_date ||
                  content?.attributes?.publishedAt}
                categoryButtonText={content?.attributes?.type}
                categoryButtonColor={theme.palette.primary.main}
                logo={getImageUrl(
                  content?.attributes?.content_owner?.data?.attributes?.icon?.data?.attributes?.url,
                )}
              />
            </Box>
            <Divider />
            <ContentArticleView
              richText={content?.attributes?.body} />
          </Box>
        </Box>
        )
      }
    </Container >
  );
}
