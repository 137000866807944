import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import { selectMemberSession } from '../../store/slices/onboardingSlice';
import { addFollowTopic, deleteFollowTopic } from '../../api/contentAPI';
import { selectFollowTopics } from '../../store/slices/contentSlice';
import { useSolutionOrg } from '../../config/useSolutionOrg';

interface FollowTopicProps {
  topicIndx: number
}

interface FollowTopicsCardProps {
  onCardClose?: () => void
  closableCard?: boolean
}

export function FollowTopicsCard({ onCardClose, closableCard }: FollowTopicsCardProps) {
  const theme = useTheme();
  const { memberId } = useAppSelector(selectMemberSession);
  const { t } = useTranslation();
  const { solutionOrgSpecificAppName } = useSolutionOrg();
  const memberFollowedTopics = useAppSelector(selectFollowTopics);
  const [followTopics, setFollowTopics] = useState([
    { name: 'Hiring', followed: false },
    { name: 'Pricing', followed: false },
    { name: 'Marketing', followed: false },
    { name: 'Lead Generation', followed: false },
    { name: 'Technology', followed: false },
  ]);

  useEffect(() => {
    if (memberFollowedTopics.length > 0) {
      const newFollowTopics = followTopics.map((item) => {
        const followedTopic =
          memberFollowedTopics.find((topic) => topic.tagName === item.name);
        return followedTopic ? { ...item, followed: true } : item;
      });
      setFollowTopics(newFollowTopics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberFollowedTopics]);

  const onClose = () => {
    if (onCardClose) onCardClose();
  };

  const handleFollowTopic = async (name: string, followed: boolean) => {
    try {
      if (followed) {
        await addFollowTopic(name, memberId, { relationType: 'follow' });
      } else {
        await deleteFollowTopic(name, memberId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onFollowBtnClick = (index: number) => {
    const followTopicsCopy = [...followTopics];
    followTopicsCopy[index].followed = !(followTopicsCopy[index].followed);
    setFollowTopics(followTopicsCopy);
    // handle follow topic
    handleFollowTopic(followTopicsCopy[index].name, followTopicsCopy[index].followed);
  };

  const FollowTopic = ({ topicIndx }: FollowTopicProps) => (
    <Box
      sx={{
      }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Button
          variant="outlined"
          sx={[
            {
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '19.09px',
              letterSpacing: '0.5px',
              textAlign: 'center',
              textTransform: 'none',
              color: theme.palette.common.white,
              borderRadius: 100,
              borderColor: theme.palette.secondary.main,
              padding: '7.5px 20px 7.5px 10px',
              marginBottom: theme.spacing(1.5),
              margin: '0 10px 20px',
              border: `2px solid ${theme.palette.common.white}`,
              [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                padding: '5px 15px 5px 7.5px',
              },
            },
            {
              ...(followTopics[topicIndx].followed ? {
                background: theme.palette.common.white,
                color: theme.palette.secondary.main,
              } : {}),
            },
            {
              ...(
                {
                  '&:hover': {
                    background: theme.palette.common.white,
                    color: theme.palette.secondary.main,
                    border: `2px solid ${theme.palette.common.white}`,
                  },
                  svg: {
                    marginRight: theme.spacing(1),
                  },
                }
              ),
            },
          ]}
          onClick={() => onFollowBtnClick(topicIndx)}
        >
          { followTopics[topicIndx].followed ? <CheckIcon /> : <AddIcon /> }
          { followTopics[topicIndx].name}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Card sx={[
      {
        minWidth: 275,
        width: '100%',
        borderRadius: theme.spacing(1.25),
        boxShadow: 0,
        paddingLeft: closableCard ? theme.spacing(1.75) : 0,
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(3),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
      },
    ]}>
      <CardContent sx={{
        position: 'relative',
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(0.25),
        paddingRight: theme.spacing(1.5),
        paddingBottom: `${theme.spacing(1.5)} !important`,
        letterSpacing: '0.52px',
        [theme.breakpoints.down('sm')]: {
          paddingBottom: `${theme.spacing(1.25)} !important`,
        },

      }}>
        {closableCard &&
          <Box textAlign={'center'}>
            <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  [theme.breakpoints.down('sm')]: {
                    top: '-10px',
                    right: '-10px',
                  },
                }}
              >
                <IconButton sx={{ paddingTop: theme.spacing(0) }} onClick={onClose}>
                  <CloseIcon sx={{
                    color: theme.palette.common.white,
                    fontSize: theme.spacing(4),
                  }} />
                </IconButton>
              </Box>
            <Box sx={{ [theme.breakpoints.up('sm')]: { padding: '0 20px' } }} >
              <Box>
                <Typography variant='h9' paddingBottom={'25px'}
                  sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    lineHeight: '28.64px',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '18px',
                      paddingBottom: '15px',
                    },
                  }}
                >
                  {`${t('home.welcomCard.heading')}${solutionOrgSpecificAppName}!`}
                </Typography>
                <Typography paddingBottom={'25px'} sx={{
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '12px',
                    paddingBottom: '15px',
                  },
                }}>
                  {t('home.welcomCard.description1')}<br/>
                  {t('home.welcomCard.description2')}
                </Typography>
                <Typography variant='h3'
                  sx={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '24px',
                    paddingBottom: '25px',
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '12px',
                      paddingBottom: '15px',
                    },
                  }}
                >
                  {t('home.welcomCard.description3')}
                </Typography>
              </Box>
              <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
                {followTopics.map((topic, index) => (
                  <FollowTopic key={index} topicIndx={index} />
                ))}
              </Box>
            </Box>
          </Box>
        }
      </CardContent>
    </Card>
  );
}
