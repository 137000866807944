import { Box } from '@mui/system';
import {
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OnboardingCompanyInformation } from './OnboardingCompanyInformation';

export function OnboardingCompanyInformationWrapper() {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography sx={{ textAlign: 'left' }}>
        {t('onboarding.questionPlaceholder.Fill out your info')}
      </Typography>
      <Box
        sx={{
          width: '100%',
          mt: -5,
        }}
      >
        <OnboardingCompanyInformation disableEmail={true} />
      </Box>
    </Box>
  );
}
