/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import {
  EVERPRO_DOMAIN, JOIST_DOMAIN, SERVICE_FUSION_DOMAIN, getAppDomain,
} from './domains';

// TODO: update favicon to match domain specific favicon
export const JOIST_FAVICON = '/joist_favicon.ico';
export const SERVICE_FUSION_FAVICON = '/service_fusion_favicon.ico';
export const EVER_PRO_FAVICON = '/ever_pro_favicon.ico';
export const DEFAULT_FAVICON = '/favicon.ico';
// manage favicon based on the current domain
const FaviconManager = () => {
  useEffect(() => {
    // Get the current domain
    const appDomain = getAppDomain();

    // Define the favicon URL and app title based on the domain
    let faviconUrl;
    let appTitle;
    switch (appDomain) {
      case JOIST_DOMAIN:
        faviconUrl = JOIST_FAVICON;
        appTitle = process.env.REACT_APP_JOIST_SITE_TITLE;
        break;
      case SERVICE_FUSION_DOMAIN:
        faviconUrl = SERVICE_FUSION_FAVICON;
        appTitle = process.env.REACT_APP_SITE_TITLE;
        break;
      case EVERPRO_DOMAIN:
        faviconUrl = EVER_PRO_FAVICON;
        appTitle = process.env.REACT_APP_EVER_PRO_SITE_TITLE;
        break;
      default:
        faviconUrl = DEFAULT_FAVICON;
        appTitle = 'Edge';
        break;
    }

    // Set the favicon
    const favicon = document.querySelector("link[rel='icon']");
    if (favicon) {
      (favicon as unknown as { href: string }).href = faviconUrl;
    }
    // Set the title
    document.title = appTitle as string;
  }, []);

  return null; // FaviconManager component doesn't render anything
};

export default FaviconManager;
