import Box from '@mui/material/Box';
import { Theme, Container } from '@mui/material';
import Banner from './Banner';
import { useLogo } from '../../../../config/useLogo';

export interface OnboardingDesktopLayoutProps {
  children?: JSX.Element
  splashScreenNav?: JSX.Element
}
export default function OnboardingDesktopLayout({ children, splashScreenNav }:
  OnboardingDesktopLayoutProps) {
  const { ellipseDesktop: EllipseDesktop } = useLogo();

  return (
    <Box
    sx={{
      display: 'flex',
      postion: 'relative',
    }}
    >
        {EllipseDesktop && <EllipseDesktop style={{
          position: 'absolute',
          top: -32,
          right: 0,
          width: '180px',
          height: '108px',
        }}/>}
        <Box
        sx={{ flex: 1 }}
        >
          <Banner/>
          {splashScreenNav}
        </Box>
        <Box
        sx={{
          flex: 1,
        }}
        >
        <Container maxWidth='xs'
        sx={(theme: Theme) => ({
          textAlign: 'center',
          marginTop: theme.spacing(20),
          marginLeft: theme.spacing(15),
          marginRight: theme.spacing(0),

        })}
        >
      {children}
      </Container>
      </Box>
    </Box>
  );
}
