import React, { useMemo } from 'react';
import { ReactComponent as JoistSecondayOneLogo }
  from '../assets/images/joist_edge_green_logo.svg';
import { ReactComponent as JoistEdgeMainLogo }
  from '../assets/images/joist_edge_main_logo.svg';
import { ReactComponent as EdgeOnlyHDOnboardingHomeDepotLogo }
  from '../assets/images/homedepot_logo.svg';
import { ReactComponent as EdgeMorePartnersComingLogo }
  from '../assets/images/more_partners_coming.svg';
import { ReactComponent as ServiceFusionMainLogo }
  from '../assets/images/serviceFusion_main_logo.svg';
import { ReactComponent as ServiceFusionSecondayOneLogo }
  from '../assets/images/serviceFusion_red_logo.svg';
import { ReactComponent as ServiceFusionEllipseMobile }
  from '../assets/images/serviceFusion_ellipse_mobile.svg';
import { ReactComponent as EverProSecondayOneLogo }
  from '../assets/images/ever_pro_yellow_logo.svg';
import { ReactComponent as EverProMainLogo }
  from '../assets/images/ever_pro_main_logo.svg';
import {
  EVERPRO_DOMAIN,
  JOIST_DOMAIN, SERVICE_FUSION_DOMAIN, UNKNOWN_DOMAIN, getAppDomain,
} from './domains';
import { ReactComponent as ServiceFusionEllipseDesktop }
  from '../assets/images/serviceFusion_ellipse_desktop.svg';
import { useFeatureFlags } from './FeatureFlagsProvider';
import { ReactComponent as JoistEllipseDesktop }
  from '../assets/images/joist_ellipse_desktop.svg';
import { ReactComponent as JoistEllipseMobile }
  from '../assets/images/joist_ellipse_mobile.svg';
import { ReactComponent as EverProEllipseDesktop }
  from '../assets/images/everPro_ellipse_desktop.svg';
import { ReactComponent as EverProEllipseMobile }
  from '../assets/images/everPro_ellipse_mobile.svg';

// manage app Logos based on the current domain
export function useLogo() {
  const { homeDepotOnlySN } = useFeatureFlags();

  const logos = useMemo<{
    [key: string]: {
      [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }> | null
    }
  }>(() => ({
    [JOIST_DOMAIN]: {
      main: JoistEdgeMainLogo,
      secondaryOne: JoistSecondayOneLogo,
      onlyHDOnboardingHomeDepot: EdgeOnlyHDOnboardingHomeDepotLogo,
      morePartnersComing: EdgeMorePartnersComingLogo,
      ellipseMobile: JoistEllipseMobile,
      ellipseDesktop: JoistEllipseDesktop,
    },
    [SERVICE_FUSION_DOMAIN]: {
      main: ServiceFusionMainLogo,
      secondaryOne: ServiceFusionSecondayOneLogo,
      ellipseMobile: ServiceFusionEllipseMobile,
      ellipseDesktop: ServiceFusionEllipseDesktop,
      ...(homeDepotOnlySN ? {
        onlyHDOnboardingHomeDepot: EdgeOnlyHDOnboardingHomeDepotLogo,
        morePartnersComing: EdgeMorePartnersComingLogo,
      } : {}),
    },
    [EVERPRO_DOMAIN]: {
      main: EverProMainLogo,
      secondaryOne: EverProSecondayOneLogo,
      onlyHDOnboardingHomeDepot: EdgeOnlyHDOnboardingHomeDepotLogo,
      morePartnersComing: EdgeMorePartnersComingLogo,
      ellipseMobile: EverProEllipseMobile,
      ellipseDesktop: EverProEllipseDesktop,
    },
    [UNKNOWN_DOMAIN]: {
      main: null,
    }, // for backward compatibility with web.dev.optimusapp.io and web.staging.optimusapp.io
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const appDomain = useMemo(() => {
    const computedDomain = getAppDomain();
    console.log(`App domain: ${computedDomain}`);
    return computedDomain;
  }, []);

  return logos[appDomain];
}
