/* eslint-disable no-nested-ternary */
import {
  Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HomeDepotIcon } from '../../../assets/images/hd_logo.svg';
import { ReactComponent as PendingBadge } from '../../../assets/images/pending_badge.svg';
import { ReactComponent as CompletedBadge } from '../../../assets/images/completed_badge.svg';
import { ReactComponent as ErrorBadge } from '../../../assets/images/error_badge.svg';
import { HDVerificationStatus } from '../../../common/enums';

interface HDSetupCardHeaderProps {
  verificationStatus?: string;
  dashboardLayout?: boolean;
}

export default function HDSetupCardHeader({
  verificationStatus,
  dashboardLayout,
}: HDSetupCardHeaderProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(() => theme.breakpoints.up('md'));

  const getVerificationStatusBadge = () => {
    switch (verificationStatus) {
      case HDVerificationStatus.Pending: return <PendingBadge />;
      case HDVerificationStatus.Completed: return <CompletedBadge />;
      case HDVerificationStatus.Error: return <ErrorBadge />;
      default: return null;
    }
  };

  return (
    <Grid container
      sx={{
        alignItems: 'center',
        pt: theme.spacing(1),
      }}
    >
      {
        (!isDesktopScreen || dashboardLayout) && <Grid item xs={12} sm={12}
          sx={{
            marginBottom: theme.spacing(2),
            textAlign: 'left',
          }}
        >
          {getVerificationStatusBadge()}
        </Grid>
      }
      <Grid item xs={2} sm={1.2} md={dashboardLayout ? 3 : 1.5} lg={dashboardLayout ? 2 : 1}>
        <HomeDepotIcon />
      </Grid>
      <Grid item xs={10} sm={9} md={9} lg={9}>
        <Typography
          variant='h4'
          sx={{
            textAlign: 'center',
          }}
        >
          {t('homeDepot.heading')}
        </Typography>
      </Grid>
      {(isDesktopScreen && !dashboardLayout) && <Grid item xs={1} sm={1} md={1} lg={2}
        sx={{
          marginTop: theme.spacing(0.5),
        }}
      >
        {getVerificationStatusBadge()}
      </Grid>
      }
    </Grid>
  );
}
