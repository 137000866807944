import { Box, Theme } from '@mui/material';
import { useSolutionOrg } from '../config/useSolutionOrg';

export function TermsOfService() {
  const { termsOfServiceUrl } = useSolutionOrg();
  return (
    <Box sx={(theme:Theme) => ({
      marginTop: '20px',
      position: 'relative',
      paddingBottom: '100%',
      height: 0,
      overflow: 'hidden',
      [theme.breakpoints.down('lg')]: {
        height: '100%',
      },
    })}>
    <iframe
        title="Terms of Service"
        style={{
          position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
        }}
        src={termsOfServiceUrl as string || ''}
        allowFullScreen
      ></iframe>
    </Box>
  );
}
