import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function Notfound() {
  const { t } = useTranslation();
  return (
    <Container sx={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Typography sx={{
        fontSize: '20px',
        fontWeight: 500,
        paddingRight: '16px',
        borderRight: '1px solid #000',
      }}>{t('error.notFoundCode')}</Typography>
      <Typography sx={{ marginLeft: '16px' }}>{t('error.notFound')}</Typography>
    </Container>
  );
}
