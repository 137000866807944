import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OnboardBusinessInformation, OnboardBusinessInformationProps } from './OnboardBusinessInformation';
import { useSolutionOrg } from '../../../config/useSolutionOrg';

export function OnboardBusinessInformationWrapper({ setIsOnboarding }:
  OnboardBusinessInformationProps) {
  const { t } = useTranslation();
  const { solutionOrgHasOnlyHomeDepot, isIframe } = useSolutionOrg();

  return (
    <Box>
      <Typography sx={{ textAlign: 'left' }}>
        {t('onboarding.questionPlaceholder.A bit more info')}
      </Typography>
      <Box
        sx={{
          width: '100%',
          mt: -6,
        }}
      >
        <OnboardBusinessInformation
          setIsOnboarding={setIsOnboarding}
          submitOnboardForm={!!(solutionOrgHasOnlyHomeDepot)}
          onlyHomeDepotIsPartner={solutionOrgHasOnlyHomeDepot as boolean}
          isIframe={isIframe}
        />
      </Box>
    </Box>
  );
}
