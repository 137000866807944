import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import OnboardingMobileLayout from './OnboardingMobileLayout';
import OnboardingDesktopLayout from './OnboardingDesktopLayout';

export interface OnboardingLayoutProps {
  children?: JSX.Element,
  splashScreenNav?: JSX.Element
  reduceMobileTopPadding?:boolean;
}
export default function OnboardingLayout({
  children,
  splashScreenNav,
  reduceMobileTopPadding,
}:OnboardingLayoutProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    isDesktop ? <OnboardingDesktopLayout splashScreenNav={splashScreenNav}>
      {children}
      </OnboardingDesktopLayout> :
    <OnboardingMobileLayout reduceMobileTopPadding={reduceMobileTopPadding}>
      {children}
    </OnboardingMobileLayout>
  );
}
