import {
  EVERPRO_DOMAIN, JOIST_DOMAIN, SERVICE_FUSION_DOMAIN, getAppDomain,
} from './domains';

const getEnv = (name: string) => process.env[name] as string;

export const getGAMeasurementId = () => {
  const appDomain = getAppDomain();

  switch (appDomain) {
    case JOIST_DOMAIN:
      return getEnv('REACT_APP_JOIST_GOOGLE_ANALYTICS_MEASUREMENT_ID');
    case SERVICE_FUSION_DOMAIN:
      return getEnv('REACT_APP_SERVICE_FUSION_GOOGLE_ANALYTICS_MEASUREMENT_ID');
    case EVERPRO_DOMAIN:
      return getEnv('REACT_APP_EVER_PRO_GOOGLE_ANALYTICS_MEASUREMENT_ID');
    default:
      return getEnv('REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID');
  }
};
