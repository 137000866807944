/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  Backdrop, Box, CircularProgress, List, Snackbar, ThemeProvider, Typography, useTheme,
} from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { deletePollAnswer, getPollTotalVotes } from '../../api/contentAPI';
import { ContentEventType } from '../../common/constants';
import { Answer } from '../../common/models/Answer';
import { PollInteractionAction } from '../../common/models/content/Content';
import { SubmitSurveyFormRequest } from '../../common/models/content/SubmitSurveyFormRequest';
import { FormAnswer } from '../../common/models/FormAnswer';
import { QuestionOption } from '../../common/models/Question';
import QuestionComponent from '../../common/QuestionComponent';
import { useTracking } from '../../common/useTracking';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { removePollAnswer, selectSurveyFormAnswers } from '../../store/slices/contentSlice';
import { selectMemberSession } from '../../store/slices/onboardingSlice';
import { saveContentFormAnswers } from '../../store/thunks/contentThunk';
import ContentPollAnswerView from './ContentPollAnswerView';
import { ContentTheme } from './ContentTheme';

export interface ContentFormQuestionProps {
  questionId: string
  questionType: string
  title?: string
  options: QuestionOption[]
  totalVotes?: number
}

export interface ContentFormProps {
  formId: string,
  question: ContentFormQuestionProps,
  contentId: number
  contentCreatorName?: string | null;
}

export default function ContentFormView(props: ContentFormProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { memberId } = useAppSelector(selectMemberSession);
  const surveyFormAnswers = useAppSelector(selectSurveyFormAnswers);
  const [loading, setLoading] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [showPollResults, setShowPollResults] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [totalVotes, setTotalVotes] = useState(props.question?.totalVotes || 0);
  const [answerId, setAnswerId] = useState<string>('');
  const minimumVotesToShow = 10;
  const trackEvent = useTracking();

  useEffect(() => {
    const pollResult =
      surveyFormAnswers.find((item) => item.question?.id === props.question?.questionId);
    if (pollResult) {
      setShowPollResults(true);
      setAnswerId(pollResult.id);
      setSelectedOption(pollResult.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formId]);

  const updateTotalVotes = (value: any) => {
    setTotalVotes(value);
  };

  const firePollInteractionEvent = (action: PollInteractionAction, options?: {
    [k: string]: unknown;
  }) => {
    trackEvent({
      optimusEventType: ContentEventType,
      optimusEvents: [
        {
          name: 'poll_interaction',
          detail: {
            action,
            memberId,
            contentId: props.contentId,
            contentCreatorName: props.contentCreatorName || null,
            pollTitle: props.question.title,
            pollId: props.question.questionId,
            ...options,
          },
        },
      ],
    });
  };

  const submitSurvey = (answer: Answer) => {
    setLoading(true);
    const request: SubmitSurveyFormRequest = {
      formId: props.formId,
      memberId: memberId || process.env.REACT_APP_MEMBER_ID || '',
      answers: [answer],
    };
    dispatch(saveContentFormAnswers(request)).then((res) => {
      firePollInteractionEvent(PollInteractionAction.Responded);
      const answerResponse = res.payload as FormAnswer[];
      setLoading(false);
      setAnswerId(answerResponse[0].id); // considering poll will have only one answer
      setShowPollResults(true);
      setOpenSuccessSnackbar(true);
      setOpenErrorSnackbar(false);
    }).catch(() => {
      setLoading(false);
      setOpenSuccessSnackbar(false);
      setOpenErrorSnackbar(true);
    });
  };

  const recastVote = async () => {
    setLoading(true);
    const deletePollAnswerResponse = await deletePollAnswer(answerId);
    const getPollTotalVotesResponse = getPollTotalVotes(props.question.questionId);
    Promise.all([deletePollAnswerResponse, getPollTotalVotesResponse])
      .then(([pollAnswerResponse, totalVotesResponse]) => {
        firePollInteractionEvent(PollInteractionAction.RecastVote);
        dispatch(removePollAnswer(answerId));
        setTotalVotes(totalVotesResponse);
        setLoading(false);
        setShowPollResults(false);
      }).catch(() => {
        setLoading(false);
        setOpenErrorSnackbar(true);
      });
  };

  const toggleOptionSelection = (event: unknown) => {
    setSelectedOption(event as string);
    const answer: Answer = {
      questionId: props.question.questionId,
      value: event as string,
    };
    submitSurvey(answer);
  };

  const getContentPollComponent = () => {
    let pollComponent;
    if (showPollResults) {
      pollComponent = <ContentPollAnswerView
        key={props.question?.questionId}
        formId={props.formId}
        questionId={props.question?.questionId}
        questionType={props.question?.questionType}
        title={props.question?.title}
        selectedOption={selectedOption}
        options={props.question?.options}
        onGetTotalVotes={updateTotalVotes}
      />;
    } else {
      pollComponent = <QuestionComponent
        type={props.question.questionType}
        options={props.question.options}
        onChange={toggleOptionSelection}
        inputValue={''}
        placeholderText={''}
      />;
    }
    return pollComponent;
  };

  const handleClose = () => {
    setOpenErrorSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  return (
    <ThemeProvider theme={ContentTheme}>
      <Box>
        <Snackbar
          open={openSuccessSnackbar}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            {t('success.dashboard')}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {t('error.generalMessage')}
          </Alert>
        </Snackbar>
        <Backdrop
          sx={{ color: '#fff', zIndex: () => theme.zIndex.drawer + 1 }}
          open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {props.question &&
          <Box
            sx={{
              minWidth: 275,
              width: '100%',
            }}>
            <List sx={{ marginTop: theme.spacing(2) }}>
              {getContentPollComponent()}
            </List>
            <Typography
              sx={{
                color: '#65A7CF',
                cursor: 'pointer',
                textAlign: 'center',
                fontSize: '18px',
                mt: 1,
                mb: 1,
                [theme.breakpoints.down('sm')]: {
                  fontSize: '16px',
                },
              }}>
              <span
                style={{
                  cursor: 'auto',
                  color: theme.palette.color2.main,
                  fontWeight: 600,
                }}
              >{totalVotes > minimumVotesToShow && `${totalVotes} votes`}
                {showPollResults &&
                  totalVotes > minimumVotesToShow &&
                  <span
                    style={{
                      verticalAlign: 'text-bottom',
                      fontSize: '20px',
                    }} > . </span>
                }
              </span>
              {showPollResults &&
                <span onClick={recastVote}>
                  {t('content.poll.recast')}
                </span>
              }
            </Typography>
          </Box>
        }
      </Box>
    </ThemeProvider>);
}
