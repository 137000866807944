import { ONBOARDING_FORMS } from '../components/Onboarding/constants';
import { useSolutionOrg } from '../config/useSolutionOrg';
import { useAppDispatch } from '../store/hooks';
import { changeFormIndex } from '../store/slices/onboardingSlice';

export function useResetOnboarding() {
  const dispatch = useAppDispatch();
  const { isIframe } = useSolutionOrg();

  const resetOnboarding = () => {
    dispatch(changeFormIndex(ONBOARDING_FORMS.companyInformation.index));
    if (isIframe) {
      // show optimus app
      window.parent.postMessage({
        payload: { hideOptimusApp: false },
        type: 'OPTIMUS_HIDE_REQUEST',
      }, '*');
    }
  };

  return resetOnboarding;
}
