import {
  Autocomplete, Box, InputAdornment, TextField, Theme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { SxProps } from '@mui/system/styleFunctionSx';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useStylesForQuestionComponent } from '../../../common/QuestionComponent';
import { AutocompleteSx } from '../../../common/utils/AutocompleteSx';
import { PushSearchHistory, selectSearchHistory } from '../../../store/slices/contentSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface ContentSearchViewProps {
  sx?: SxProps<Theme>

  showBackBtn?: boolean;

  onBackBtnClick?: ()=> void;

  onSubmit?: (searchTerm?: string) => void;

  onClear?: () => void;

  value?: string
}

export default function ContentSearchView(props: ContentSearchViewProps) {
  const classes = useStylesForQuestionComponent();

  const [searchValue, setSearchValue] = useState(props.value || '');
  const dispatch = useAppDispatch();
  const history = useAppSelector(selectSearchHistory);

  const sxAlignCenter = (theme: Theme) => ({
    marginY: 'auto',
  }as const);
  const sxPaddingSquare = (theme: Theme) => ({
    paddingx: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(2),
    },
  }as const);

  const sxCursorPointer = (theme: Theme) => ({
    cursor: 'pointer',
  }as const);
  const sxFlex = {
    display: 'flex',
  };
  const sxFlexGrow = {
    flex: 1,
  };
  const { sx } = props;

  useEffect(() => {
    dispatch(PushSearchHistory(searchValue));
    setSearchValue(props.value || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, dispatch]);

  // This will avoid onSubmit to be invoked during startup
  const setSearchValueProxy = (value: string) => {
    setSearchValue(value);
    if (props.onSubmit && value) {
      props.onSubmit(value);
    }
  };

  return (
    <Box sx={[
      sxFlex,
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}>
      {props.showBackBtn &&
        <Box sx={[
          sxPaddingSquare,
          sxAlignCenter,
          sxCursorPointer,
        ]}
          onClick={props.onBackBtnClick}>
          <ArrowBackIosNewIcon/>
        </Box>}
      <Autocomplete
        sx={[
          sxFlexGrow,
          AutocompleteSx.hideClearButton,
        ]}
        disablePortal
        classes={classes}
        options={history}
        openOnFocus={false}
        clearOnBlur={false}
        handleHomeEndKeys
        freeSolo
        value={searchValue}
        onChange={(event, newValue) => setSearchValueProxy(newValue ?? '')}
        renderInput={(params) => <TextField
          variant="outlined"
          placeholder="Search"
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />}
      />
    </Box>
  );
}
