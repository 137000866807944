import { jwtDecode } from 'jwt-decode';
import { Location, NavigateFunction } from 'react-router-dom';
import { JoistSolutionOrgName, ServiceFusionSolutionOrgName } from '../constants';

export const extractTokenExpiry = (token: string): string | null => {
  try {
    // Decode the JWT token
    const decoded = jwtDecode(token);

    if (decoded.exp) {
      // Extract the expiration time from the payload
      const expirationDate = new Date(decoded.exp * 1000);
      return expirationDate.toLocaleString();
    }
    console.error('Invalid JWT token or payload.');
  } catch (error) {
    console.error('Error decoding JWT token:', error);
  }

  return null;
};

// Note: If the url of service-fusion pages is changed to exclude the word serviceFusion,
// this solution will break
export const getSolutionOrgName = () => (window.location.href?.includes('serviceFusion') ?
  ServiceFusionSolutionOrgName : JoistSolutionOrgName);

// custom history object to allow navigation outside react components
interface CustomHistory {
  navigate: NavigateFunction | null;
  location: Location | null;
}
export const customHistory: CustomHistory = {
  navigate: null,
  location: null,
};

export class ExponentialBackoff {
  private baseDelay: number;

  private maxDelay: number;

  /**
    * Creates an object that is used to implement exponential backoff strategy.
    * @param baseDelay base delay in milliseconds with which to multiply the exponential factor
    * @param maxDelay maximum delay in milliseconds that the exponential backoff cannot exceed
    */
  constructor(baseDelay: number, maxDelay: number) {
    this.baseDelay = baseDelay;
    this.maxDelay = maxDelay;
  }

  /**
    * Gets the wait time in milliseconds
    * @param retryCount current retry count
    * @return wait time in milliseconds
    */
  getWaitTime(retryCount: number): number {
    const waitTime = this.baseDelay * (2 ** retryCount);
    return Math.min(waitTime, this.maxDelay);
  }

  /**
    * Waits for the specified time
    * @param ms wait time in milliseconds
    * @return  a promise
    */
  wait(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}
