import {
  Box, Typography, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import { useAppSelector } from '../../../../store/hooks';
import { selectPartnersInformationQuestions } from '../../../../store/slices/onboardingSlice';
import { BannerLogos } from './BannerLogos';

interface BannerProps {
  isFullHeight?: boolean;
  centreAlign?: boolean;
  customHeight?: string;
}
export default function Banner({ isFullHeight, customHeight, centreAlign }: BannerProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const partnersInformationQuestions = useAppSelector(selectPartnersInformationQuestions) || [];
  const activePartners = partnersInformationQuestions[0]?.options?.filter((item) => item.active);
  // const { useHomeDepotOnlyBannerText } = useSolutionOrg();

  return (
    <Box maxWidth='xs'
      sx={() => ({
        textAlign: 'center',
        marginTop: theme.spacing(9),
        marginLeft: centreAlign ? 'auto' : '150px',
        marginRight: 'auto',
        background: '#171515',
        maxWidth: theme.spacing(81),
        borderRadius: theme.spacing(3.75),
        // commenting this to fix getting partners logo out of the banner
        // height: isFullHeight ? 'auto' : (customHeight || '85vh'),
        padding: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
          paddingTop: theme.spacing(0),
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(3.8),
          paddingBottom: theme.spacing(1),
          marginTop: theme.spacing(-4),
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      })}
    >
      <Box
        sx={() => ({
          [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
          },
        })}
      >
        <Typography
        component={'div'}
          sx={() => ({
            color: theme.palette.common.white,
            fontWeight: 600,
            fontSize: theme.spacing(4.5),
            textAlign: 'left',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.spacing(3),
            },
          })}
        >
          <div>{t('home.banner.description1')}</div>
          <div>{t('home.banner.description2')}</div>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color: theme.palette.common.white,
            mb: theme.spacing(6),
            mt: theme.spacing(3),
          }}
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: theme.spacing(2.5),
          }}>
            <Box display={'flex'} sx={{ flex: 1 }}>
              <DoneIcon
                sx={{
                  color: theme.palette.green.main,
                  mr: theme.spacing(2),
                  [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(2),
                    height: theme.spacing(2),
                    mr: theme.spacing(0.5),
                  },
                }}
              />
              <Typography variant='h3'
              sx={{
                [theme.breakpoints.down('sm')]: {
                  fontSize: theme.spacing(2),
                  textAlign: 'left',
                },
              }}
              >
                {t('home.banner.point1')}
              </Typography>
            </Box>
            <Box display={'flex'} sx={{ flex: 1 }}>
              <DoneIcon
                sx={{
                  color: theme.palette.green.main,
                  mr: theme.spacing(2),
                  [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(2),
                    height: theme.spacing(2),
                    mr: theme.spacing(0.5),
                  },
                }}
              />
              <Typography variant='h3'
              sx={{
                [theme.breakpoints.down('sm')]: {
                  fontSize: theme.spacing(2),
                  textAlign: 'left',
                },
              }}
              >
                {t('home.banner.point2')}
              </Typography>
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
            <Box display={'flex'} sx={{ flex: 1 }}>
              <DoneIcon
                sx={{
                  color: theme.palette.green.main,
                  mr: theme.spacing(2),
                  [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(2),
                    height: theme.spacing(2),
                    mr: theme.spacing(0.5),
                  },
                }}
              />
              <Typography variant='h3'
              sx={{
                [theme.breakpoints.down('sm')]: {
                  fontSize: theme.spacing(2),
                  textAlign: 'left',
                },
              }}
              >
                {t('home.banner.point3')}
              </Typography>
            </Box>
            <Box display={'flex'} sx={{ flex: 1 }}>
              <DoneIcon
                sx={{
                  color: theme.palette.green.main,
                  mr: theme.spacing(2),
                  [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(2),
                    height: theme.spacing(2),
                    mr: theme.spacing(0.5),
                  },
                }}
              />
              <Typography variant='h3'
              sx={{
                [theme.breakpoints.down('sm')]: {
                  fontSize: theme.spacing(2),
                  textAlign: 'left',
                },
              }}
              >
              {t('home.banner.point4')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <BannerLogos activePartners={activePartners} />
      </Box>
    </Box >
  );
}
