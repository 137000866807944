// PageTransition.js
import { motion } from 'framer-motion';

interface PageTransitionProps{
  children: JSX.Element
}
export function PageTransition({ children }:PageTransitionProps) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
    >
      {children}
    </motion.div>
  );
}
