import {
  AnyAction, combineReducers, configureStore, PreloadedState,
} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  createMigrate,
  FLUSH,
  MigrationManifest,
  PAUSE,
  PERSIST,
  PersistConfig,
  PersistedState,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import * as Sentry from '@sentry/react';
import onboardingReducer from './slices/onboardingSlice';
import dashboardReducer from './slices/dashboardSlice';
import contentReducer from './slices/contentSlice';

const appReducer = combineReducers({
  onboarding: onboardingReducer,
  dashboard: dashboardReducer,
  content: contentReducer,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
  if (action.type === 'logout/LOGOUT') {
    // for all keys defined in persistConfig(s)
    storage.removeItem('persist:root');

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>

// Use migrations if making changes (adding/removing fields, changing data types)
// to the redux store object at more than 2 levels deep
const LATEST_MIGRATION_VERSION = 0; // previous version is -1 (default)
const migrations: MigrationManifest = {
  LATEST_MIGRATION_VERSION: (state: PersistedState) => ({
    ...state,
    dashboard: {
      ...(state as unknown as RootState).dashboard,
      myAccountRenderIndex: { // field to update
        value: 0,
        descr: 'Active tab in my account page',
      },
    },
  }) as PersistedState,
};

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage,
  // Shallow merge at 2 levels (https://github.com/rt2zz/redux-persist#state-reconciler)
  // No need for migration up to 2 levels deep in the store object
  // since this automatically handles reconcilation
  stateReconciler: autoMergeLevel2,
  // use migrations for changes at more than 2 levels deep in the store object
  migrate: createMigrate(migrations, {
    debug:
      process.env.REACT_APP_STORE_MIGRATION_DEBUG === 'true',
  }),
  version: LATEST_MIGRATION_VERSION,
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

// persist state on page refresh
const persistedReducer = persistReducer(persistConfig, rootReducer);

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: persistedReducer, // used persistedReducer to persist state while store configuration
    preloadedState,
    devTools: process.env.ENV !== 'prod',
    // added middleware to avoid the error 'a non-serializable value was detected in the state'
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    enhancers: (defaultEnhancers) => defaultEnhancers.concat([sentryReduxEnhancer]),
  });
}

export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
