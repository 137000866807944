import {
  Theme, useMediaQuery, Grid, Box, useTheme,
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SideMenu from '../../common/SideMenu';
import AccountInformation from '../AccountInformation';
import { Preferences } from './Preferences';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectMyAccountRenderIndex, setMyAccountRenderIndex } from '../../store/slices/dashboardSlice';
import { logout } from '../../store/thunks/authenticationThunk';
import { isOnboarding } from '../../routes/namedRoutes';
import CallToActionButton from '../../common/CallToActionButton';

const optionalContainerStyle = (theme: Theme) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
} as const);

export default function MyAccount() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const myAccountRenderIndex = useAppSelector(selectMyAccountRenderIndex);
  const isDesktopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const theme = useTheme();

  const sideMenuItems = [
    {
      title: `${t('navigation.account')}`,
      componentToRender: <AccountInformation />,
    },
    {
      title: `${t('navigation.preferences')}`,
      componentToRender: <Preferences />,
    },
  ];
  return (
    <Box sx={isDesktopScreen ? [optionalContainerStyle] : []}>
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        columns={{ xs: 4, sm: 12, md: 12 }}
      >
        <Grid item xs={4} sm={12} md={4}
          sx={{
            borderRadius: theme.spacing(2.25),
          }}
        >
          <SideMenu
            menuItems={sideMenuItems}
            setRenderIndx={(indx) => {
              dispatch(setMyAccountRenderIndex(indx));
            }}
            renderIndx={myAccountRenderIndex.value}
          />
          <CallToActionButton
            optionalStackStyles={{
              width: '100%',
              margin: `${theme.spacing(4)} 0px`,
              display: 'flex',
              justifyContent: 'stretch',
              alignItems: 'center',
              padding: 0,
            }}
            onClick={() => {
              dispatch(logout({
                isOnboarding: isOnboarding(),
                navigate,
                customAfterLoginPath: '/',
              }));
            }}
            text={
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.common.white,
                fontSize: theme.spacing(2.5),
                height: theme.spacing(7),
                width: '100%',
                fontWeight: '400',
                borderRadius: theme.spacing(2.25),
                '&:hover': {
                  color: theme.palette.secondary.main,
                  backgroundColor: theme.palette.grey[200],
                },
              }}>
                {t('navigation.logout')}
                <ExitToAppIcon sx={{
                  fontSize: theme.spacing(2.75),
                  marginLeft: theme.spacing(1),
                }} />
              </Box>
            }
            sx={{
              position: 'static',
              width: '100%',
              paddingTop: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
              background: theme.palette.common.white,
              borderRadius: theme.spacing(2.25),
              color: theme.palette.grey[600],
              fontWeight: '400',
              flex: 1,
              padding: 0,
              margin: '0 !important',
              '&:hover': {
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.grey[200],
              },
            }}
            disableRipple={true}
            disableElevation={true}
          />
        </Grid>
        <Grid item xs={4} sm={12} md={6}>
          {sideMenuItems[myAccountRenderIndex.value].componentToRender}
        </Grid>
      </Grid>
    </Box>
  );
}
