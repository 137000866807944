import React, { useState, useEffect } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import { ContentEventType } from '../../common/constants';
import { useTracking } from '../../common/useTracking';
import '../../assets/contentYoutubeVideo.css';

export interface ContentYoutubePlayerProps {
  videoId: string;
  contentId: number;
  memberId: string;
  contentTitle?: string;
  contentCreatorName?: string | null
}

export default function ContentYoutubePlayer(
  {
    videoId, contentId, memberId, contentTitle, contentCreatorName,
  }:ContentYoutubePlayerProps,
) {
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval>
  | undefined>(undefined);
  const trackEvent = useTracking();

  useEffect(
    () => () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(undefined);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onPlayerStateChange: YouTubeProps['onStateChange'] = async (event) => {
    const { data, target } = event;

    if (data === YouTube.PlayerState.PLAYING) {
      console.log('Video started playing');
      setIntervalId(setInterval(async () => {
        const playCurrentTime = await target.getCurrentTime();
        const PlayDurationTime = await target.getDuration();
        trackEvent({
          optimusEventType: ContentEventType,
          optimusEvents: [
            {
              name: 'content_video_playing',
              detail: {
                type: 'video play',
                contentId,
                memberId,
                contentTitle: contentTitle || null,
                contentCreatorName: contentCreatorName || null,
                playedSeconds: playCurrentTime.toFixed(2),
                videoDuration: PlayDurationTime.toFixed(2),
                progress: `${((playCurrentTime / PlayDurationTime) * 100).toFixed(2)}%`,
              },
            },
          ],
        });
      }, 10000)); // every 10 seconds
    } else {
      const currentTime = await target.getCurrentTime();
      const durationTime = await target.getDuration();

      if (data === YouTube.PlayerState.PAUSED) {
        console.log('Video paused');
        trackEvent({
          optimusEventType: ContentEventType,
          optimusEvents: [
            {
              name: 'content_video_paused',
              detail: {
                type: 'video play',
                contentId,
                memberId,
                contentTitle: contentTitle || null,
                contentCreatorName: contentCreatorName || null,
                playedSeconds: currentTime.toFixed(2),
                videoDuration: durationTime.toFixed(2),
                progress: `${((currentTime / durationTime) * 100).toFixed(2)}%`,
              },
            },
          ],
        });
        clearInterval(intervalId);
        setIntervalId(undefined);
      } else if (data === YouTube.PlayerState.ENDED) {
        console.log('Video ended');
        trackEvent({
          optimusEventType: ContentEventType,
          optimusEvents: [
            {
              name: 'content_video_ended',
              detail: {
                type: 'video play',
                contentId,
                memberId,
                contentTitle: contentTitle || null,
                contentCreatorName: contentCreatorName || null,
                playedSeconds: currentTime.toFixed(2),
                videoDuration: durationTime.toFixed(2),
                progress: `${((currentTime / durationTime) * 100).toFixed(2)}%`,
              },
            },
          ],
        });
        clearInterval(intervalId);
        setIntervalId(undefined);
      }
    }
  };

  return (
      <YouTube
        videoId={videoId}
        style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}
        iframeClassName={'YoutubeIframe'}
        onStateChange={onPlayerStateChange}
        opts={{
          playerVars: {
            autoplay: 0,
            rel: 0,
          },
        }}
      />
  );
}
