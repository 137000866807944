import { ThemeProvider } from '@mui/material';
import { ContentTheme } from './ContentTheme';
import { SanitizeHTML } from './SanitizeHTML';

interface ContentArticleViewProps {
  richText?: string | null
}

export default function ContentArticleView(props: ContentArticleViewProps) {
  return (
    <ThemeProvider theme={ContentTheme}>
      {props.richText && <SanitizeHTML html={props.richText || ''} />}
    </ThemeProvider>);
}
