import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useLogo } from '../config/useLogo';

export default function NavHeader() {
  const theme = useTheme();
  const { secondaryOne: Logo } = useLogo();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.12),0px 0px 4px 0px rgba(0, 0, 0, 0.14)',
        width: '100%',
        height: '79px',
        paddingLeft: theme.spacing(3),
        position: 'sticky',
        top: 0,
        zIndex: 999,
      }}
    >
      {Logo && <Logo
        height={43}
        width={204}
        style={{
          marginTop: theme.spacing(2.25),
        }}
      />}
    </Box>
  );
}
