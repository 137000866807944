import { useCompanyEnrollmentInfo } from '../../common/useCompanyEnrollmentInfo';
import { ContentViewHome } from './ContentViewHome';

export default function ContentViewHomeJoist() {
  // check company enrollment status
  useCompanyEnrollmentInfo({
    calledFromOnboardingComponent: false,
    redirectPath: '/in-app/onboarding',
  });

  return (
    <ContentViewHome backUrl ={'/in-app/dashboard'}/>
  );
}
