import { Member } from './Member';
import { OnboardingAnswerForm } from './OnboardingAnswerForm';
import { Partner } from './Partner';

type EnrolledMember = Omit<Member, 'id' | 'email'>;

export interface EnrollmentRequest {
    rewardProgramId: string;
    memberId: string;
    member: EnrolledMember;
    forms: OnboardingAnswerForm[];
    partners: Partner[];
}

export function filterNullFields(member: EnrolledMember): Partial<EnrolledMember> {
  return Object.fromEntries(
    Object.entries(member).filter(([_, value]) => value !== null),
  );
}
