import {
  Box, Grid, Typography, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HDSetupFlowSteps } from '../../../common/enums';
import { UpdateHDSetupFlowStepsCompletionState } from '../../../store/slices/dashboardSlice';
import { useAppDispatch } from '../../../store/hooks';
import { HDSetupChildCardsProps } from './HDSetupFlowCard';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';

export default function HDStepsCompleteConfirmationCard({
  children, dashboardLayout,
}: HDSetupChildCardsProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const trackOnboardEvent = useOnboardEventTracking();

  const resetSetupFlow = () => {
    dispatch(UpdateHDSetupFlowStepsCompletionState(HDSetupFlowSteps.None));
    // Populate user event
    trackOnboardEvent('clicked-hd-card-setup-reset');
  };

  return (
    <Box >
      <Box
        sx={{
          marginBottom: theme.spacing(1.5),
          marginTop: theme.spacing(1.5),
          color: theme.palette.grey56.main,
        }}
      >
        <Typography variant='body1'>{t('homeDepot.instructions.completionConfirmation')}</Typography>
      </Box>
      {dashboardLayout ?
        <>{children}
          < Box
            sx={{
              textAlign: 'center',
              paddingBottom: theme.spacing(1),
            }}>
            <Typography
              variant='h4'
              onClick={resetSetupFlow}
              sx={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(3),
                color: theme.palette.secondary.main,
                cursor: 'pointer',
              }}>{t('homeDepot.instructions.completionNo')}</Typography>
          </Box>
        </> :
        <Grid container
          sx={{
            alignItems: 'center',
            textAlign: 'center',
            pb: theme.spacing(4),
            pt: theme.spacing(1),
          }}
        >
          <Grid item xs={12} sm={7} md={6} lg={4}
            sx={{
              [theme.breakpoints.between('sm', 'md')]: {
                ml: theme.spacing(13),
              },
            }}
          >
            {children}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}
            sx={{
              [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(2),
              },
            }}
          >
            <Typography
              variant='h4'
              onClick={resetSetupFlow}
              sx={{
                color: theme.palette.secondary.main,
                cursor: 'pointer',
              }}>{t('homeDepot.instructions.completionNo')}</Typography>
          </Grid>
        </Grid>
      }
    </Box >
  );
}
