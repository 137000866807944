import {
  CardContent,
  Container, Divider, Skeleton, Theme,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import ContentCardHeaderSkeleton from './ContentCardHeaderSkeleton';

const containerStyle = (theme: Theme) => ({
  marginTop: '8px',
  paddingBottom: '48px',
} as const);

export function ContentViewHomeSkeleton() {
  const theme = useTheme();
  return (
    <Container maxWidth={'sm'} sx={[containerStyle]}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '0px',
      }}>
        <Box sx={{ marginBottom: '20px' }}>
          <Skeleton
            sx={() => ({
              [theme.breakpoints.down('sm')]: {
                height: 180,
              },
              height: 250,
            })}
            animation="wave" variant="rectangular" />
        </Box>
        <CardContent sx={{
          padding: '0px',
          paddingLeft: '12px',
          paddingRight: '12px',
        }}>
          <ContentCardHeaderSkeleton />
        </CardContent>
        <Divider />
        <Skeleton
          animation="wave"
          height={5}
          width="90%"
          sx={{
            marginTop: 4,
          }}
        />
        <Skeleton
          animation="wave"
          height={5}
          width="70%"
          sx={{
            marginTop: 2,
          }}
        />
      </Box>
    </Container >
  );
}
