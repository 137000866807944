import { Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLogo } from '../../config/useLogo';
import { useSolutionOrg } from '../../config/useSolutionOrg';

export function DashboardRewardBanner() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { main: Logo } = useLogo();
  const { envSolutionOrgTitle } = useSolutionOrg();
  return (
    <Box
      sx={{
        background: `radial-gradient(104.99% 53.33% at 50% 46.67%, ${theme.palette.gradient.main} 0%, ${theme.palette.gradient.dark} 100%)`,
        borderRadius: '26px',
        paddingLeft: '24px',
        paddingTop: '56px',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        width: '100%',
        maxWidth: '320px',
        height: 'fit-content',
      }}
      onClick={() => {
        navigate('/rewards');
      }}
    >
      {Logo && <Logo />}
      <Box sx={{
        fontWeight: '600',
        color: theme.palette.common.white,
        letterSpacing: '0.335977px',
        paddingRight: '20px',
        fontSize: '30px',
        marginTop: '18px',
        lineHeight: '37px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '20px',
          lineHeight: '27px',
          paddingRight: '0px',
          letterSpacing: '0px',
        },
      }}>
        {t('dashboard.rewardBanner.description1').replace('$solutionOrgName', envSolutionOrgTitle as string)}
      </Box>
      <Box sx={{
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '24px',
        letterSpacing: '0.0898333px',
        color: theme.palette.common.white,
        marginTop: '8px',
        paddingRight: '20px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '16px',
          paddingRight: '0px',
        },
      }}>
        {t('dashboard.rewardBanner.description2')}
      </Box>
      <Box sx={{
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '12px',
        letterSpacing: '-0.287467px',
        color: theme.palette.common.white,
        marginTop: '24px',
        paddingRight: '20px',
        marginBottom: '36px',
      }}>
        {t('dashboard.rewardBanner.description3')}
      </Box>
    </Box>
  );
}
