import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import theme from '../../assets/theme';

export const ContentTheme = createTheme(deepmerge(theme, {
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          '> .MuiGrid-item': {
            padding: 0,
          },
        },
        item: {
          paddingLeft: 0,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'grey',
          },
        },
      },
    },
  },
}));
