import React, { useEffect, useState } from 'react';
import {
  Card, CardContent, Typography, Button, Divider, useTheme, Box, Theme,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import { selectMemberSession } from '../../store/slices/onboardingSlice';
import { addFollowTopic, deleteFollowTopic } from '../../api/contentAPI';
import { selectFollowTopics } from '../../store/slices/contentSlice';

interface FollowTopicProps {
  topicIndx: number
}

interface FollowTopicsCardProps {
  onCardClose?: () => void
  closableCard?: boolean
}

const dividerStyle = (theme: Theme) => ({
  height: '1px',
  color: theme.palette.color2.main,
  backgroundColor: theme.palette.color2.main,
} as const);

const followButtonStyle = (theme: Theme) => ({
  textTransform: 'none',
  color: theme.palette.secondary.main,
  borderRadius: 20,
  borderColor: theme.palette.secondary.main,
} as const);

export function FollowTopicsCardOld({ onCardClose, closableCard }: FollowTopicsCardProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { memberId } = useAppSelector(selectMemberSession);
  const memberFollowedTopics = useAppSelector(selectFollowTopics);
  const [followTopics, setFollowTopics] = useState([
    { name: 'Hiring', description: 'Hire The Best People', followed: false },
    { name: 'Pricing', description: 'Learn How The Best Do It', followed: false },
    { name: 'Lead Generation', description: 'Learn How To Get Quality Leads', followed: false },
    { name: 'Technology', description: 'New Technologies', followed: false },
    { name: 'Marketing', description: 'Up Your Game', followed: false },
  ]);

  useEffect(() => {
    if (memberFollowedTopics.length > 0) {
      const newFollowTopics = followTopics.map((item) => {
        const followedTopic =
          memberFollowedTopics.find((topic) => topic.tagName === item.name);
        return followedTopic ? { ...item, followed: true } : item;
      });
      setFollowTopics(newFollowTopics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberFollowedTopics]);

  const onClose = () => {
    if (onCardClose) onCardClose();
  };

  const handleFollowTopic = async (name: string, followed: boolean) => {
    try {
      if (followed) {
        await addFollowTopic(name, memberId, { relationType: 'follow' });
      } else {
        await deleteFollowTopic(name, memberId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onFollowBtnClick = (index: number) => {
    const followTopicsCopy = [...followTopics];
    followTopicsCopy[index].followed = !(followTopicsCopy[index].followed);
    setFollowTopics(followTopicsCopy);
    // handle follow topic
    handleFollowTopic(followTopicsCopy[index].name, followTopicsCopy[index].followed);
  };

  const FollowTopic = ({ topicIndx }: FollowTopicProps) => (
    <Box
      sx={{
        marginLeft: theme.spacing(3.375),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(3.5),
      }}>
        <Box>
          <Typography variant='h4'>{followTopics[topicIndx].name}</Typography>
          <Typography variant='body1' sx={{
            color: theme.palette.color2.main,
            marginBottom: theme.spacing(2),
          }}>
            {followTopics[topicIndx].description}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          sx={[
            followButtonStyle,
            {
              ...(followTopics[topicIndx].followed ? {
                background: theme.palette.secondary.main,
                color: theme.palette.common.white,
              } : {}),
            },
            {
              ...(
                {
                  '&:hover': {
                    backgroundColor: followTopics[topicIndx].followed ?
                      theme.palette.secondary.main : theme.palette.common.white,
                    color: followTopics[topicIndx].followed ?
                      theme.palette.common.white : theme.palette.secondary.main,
                    borderColor: theme.palette.secondary.main,
                  },
                }
              ),
            },
          ]}
          onClick={() => onFollowBtnClick(topicIndx)}
        >
          {followTopics[topicIndx].followed ? `${t('button.following')}` : `${t('button.follow')}`}
        </Button>
      </Box>
      {topicIndx < followTopics.length - 1 &&
        <Divider sx={[{
          margin: `${theme.spacing(1)}px 0`,
        }, dividerStyle]} />
      }
    </Box>
  );

  return (
    <Card sx={{
      minWidth: 275,
      width: '100%',
      borderRadius: theme.spacing(1.25),
      boxShadow: 0,
      paddingLeft: closableCard ? theme.spacing(1.75) : 0,
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(3),
    }}>
      <CardContent sx={{
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(0.25),
        paddingRight: theme.spacing(1.5),
        paddingBottom: `${theme.spacing(1.5)} !important`,
        [theme.breakpoints.down('sm')]: {
          paddingBottom: `${theme.spacing(1.25)} !important`,
        },

      }}>
        {closableCard &&
          <Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box>
                <Typography variant='h4' sx={{ color: theme.palette.color11.main }}>
                  Anything you want to learn more about?
                </Typography>
                <Typography variant='h9'>Customize Your Feed</Typography>
              </Box>
              <Box>
                <IconButton sx={{ paddingTop: theme.spacing(0) }} onClick={onClose}>
                  <CloseIcon sx={{
                    color: theme.palette.color2.main,
                    fontSize: theme.spacing(4),
                  }} />
                </IconButton>
              </Box>
            </Box>
            <Divider sx={[{
              marginTop: theme.spacing(2),
              marginLeft: theme.spacing(3.375),
              marginBottom: theme.spacing(0.5),
            }, dividerStyle]} />
          </Box>
        }
        {followTopics.map((topic, index) => (
          <FollowTopic key={index} topicIndx={index} />
        ))}
      </CardContent>
    </Card>
  );
}
