import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import * as contentAPI from '../../api/contentAPI';
import { RootState } from '../storeSetup';
import { Content, ContentStatus } from '../../common/models/content/Content';
import { OnboardingForm } from '../../common/models/OnboardingForm';
import { SubmitSurveyFormRequest } from '../../common/models/content/SubmitSurveyFormRequest';
import { FormAnswer } from '../../common/models/FormAnswer';
import { GetContentRequest } from '../../common/models/content/GetContentRequest';
import { FollowTopic } from '../../common/models/FollowTopic';
import { TopicsCount } from '../../common/models/content/TopicsCount';

export interface ResponseError {
  errorMessage?: string
}

export const getContentStatus = createAsyncThunk<
  // Return type of the payload creator
  ContentStatus,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
  }>(
    'content/getContentStatus',
    async (contentIds, { rejectWithValue }) => {
      try {
        const contentStatus = await contentAPI.getContenStatus(contentIds);
        return contentStatus as ContentStatus;
      } catch (err) {
        console.log(err);
        const responseError: ResponseError = { errorMessage: 'failed to get content status' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const getContent = createAsyncThunk<
  // Return type of the payload creator
  Content[],
  // First argument to the payload creator
  GetContentRequest,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'content/getContent',
    async (request, { rejectWithValue, dispatch, getState }) => {
      try {
        let contentList: Content[];
        if (request.searchTerm || request.topic || request.businessGoal) {
          request.setHasNoResults?.(false);
          contentList = await contentAPI.getSearchContent(request);
          if (contentList?.length === 0) {
            contentList = await contentAPI.getContent(request) as Content[];
            request.setHasNoResults?.(true);
          }
        } else if (request.onlySavedContent) {
          contentList = await contentAPI.getSavedContent(request) as Content[];
        } else {
          contentList = await contentAPI.getContent(request) as Content[];
        }

        const contentIds = contentList.map((content) => content.id).join(',');
        if (contentIds) {
          dispatch(getContentStatus(contentIds));
        }
        return contentList;
      } catch (err) {
        console.log(err);
        const responseError: ResponseError = { errorMessage: 'failed to get content' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const getContentById = createAsyncThunk<
  // Return type of the payload creator
  Content,
  // First argument to the payload creator
  number,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'content/getContentById',
    async (contentId, { rejectWithValue, getState }) => {
      try {
        const response = await contentAPI.getContentById(contentId);
        return response as Content;
      } catch (err) {
        const responseError: ResponseError = { errorMessage: 'failed to get content' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const getContentForm = createAsyncThunk<
  // Return type of the payload creator
  OnboardingForm,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'content/getForm',
    async (formId, { rejectWithValue, getState }) => {
      try {
        const response = await contentAPI.getContentForm(formId);
        return response as OnboardingForm;
      } catch (err) {
        const responseError: ResponseError = { errorMessage: 'failed to get content form' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const getContentFormAnswers = createAsyncThunk<
  // Return type of the payload creator
  FormAnswer[],
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'content/getFormAnswers',
    async (formId, { rejectWithValue, getState }) => {
      try {
        const { memberSession: { memberId } } = getState().onboarding;
        const response = await contentAPI.getFormAnswers(formId, memberId);
        return response as FormAnswer[];
      } catch (err) {
        const responseError: ResponseError = { errorMessage: 'failed to get content form answers' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const saveContentFormAnswers = createAsyncThunk<
  // Return type of the payload creator
  FormAnswer[],
  // First argument to the payload creator
  SubmitSurveyFormRequest,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'content/saveContentFormAnswers',
    async (request, { rejectWithValue, getState }) => {
      try {
        const response = await contentAPI.saveContentFormAnswers(request);
        return response as FormAnswer[];
      } catch (err: unknown) {
        // TODO: handle request errors appropriately
        const responseError = ((err as AxiosError)?.response?.data ||
          { errorMessage: 'something went wrong' }) as ResponseError;
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const getFollowTopics = createAsyncThunk<
  // Return type of the payload creator
  FollowTopic[],
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'content/getFollowTopics',
    async (_, { rejectWithValue, getState }) => {
      try {
        const { memberSession: { memberId } } = getState().onboarding;
        const response = await contentAPI.getFollowTopics(memberId);
        return response as FollowTopic[];
      } catch (err) {
        const responseError: ResponseError = { errorMessage: 'failed to get follow topics' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const getTopTopics = createAsyncThunk<
  // Return type of the payload creator
  TopicsCount[],
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'content/getTopTopics',
    async (_, { rejectWithValue, getState }) => {
      try {
        const response = await contentAPI.getTopTopics();
        return response as TopicsCount[];
      } catch (err) {
        const responseError: ResponseError = { errorMessage: 'failed to get top topics' };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );
