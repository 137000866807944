import {
  Autocomplete, Box, TextField, Theme, Typography,
  styled,
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { AutocompleteInputChangeReason } from '@mui/base/useAutocomplete/useAutocomplete';
import cloneDeep from 'lodash/cloneDeep';
import { QuestionOption } from '../models/Question';
import { BusinessTrade } from '../models/Member';

type BusinessTradeAutocompleteList = {
  id: number;
  availableOptions: QuestionOption[];
  selectedOption: BusinessTrade;
  searchString: string;
  open: boolean;
}

type GroupAutocompleteComponentProps = {
  placeholderText: string;
  options?: QuestionOption[];
  selectedValues?: BusinessTrade[];
  onChange: (newValue: BusinessTrade[] | null) => void;
  onBlur: () => void;
} & Omit<AutocompleteProps<QuestionOption, false, false, false>, 'inputValue' | 'renderInput'>;

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.palette.color2.main} !important`,
    borderRadius: '13px !important',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.palette.color2.main} !important`,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.palette.color2.main} !important`,
  },
  '&.Mui-focused': {
    borderColor: `${theme.palette.color2.main} !important`,
  },
})) as typeof Autocomplete;

export const GroupAutocompleteComponent = (props: GroupAutocompleteComponentProps) => {
  const {
    onChange, options, onBlur, placeholderText,
  } = props;
  const maxNumberOfList = 5;
  // maintain a global unique key for each BusinessTradeAutocompleteList item, which should
  // never be changed for an item. This way we avoid rerendering issues
  const [currentAutocompleteIndx, setCurrentAutocompleteIndx] = useState(0);

  const calculateAvailableOptions = (index: number, selectedVals: BusinessTrade[] | undefined) => {
    const otherSelectedTrades = cloneDeep(selectedVals || []);
    otherSelectedTrades.splice(index, 1);
    const tempAvailableOptions: QuestionOption[] = options.filter((obj1) => !otherSelectedTrades
      .some((obj2) => obj2.name === obj1.value));
    return tempAvailableOptions;
  };

  const [businessTrades, setBusinessTrades] = useState<BusinessTradeAutocompleteList[]>(() => {
    const tempBusinessTrades: BusinessTradeAutocompleteList[] = [];
    if (options) {
      let id = currentAutocompleteIndx;
      if (props.selectedValues && props.selectedValues.length > 0) {
        props.selectedValues.forEach((value, index) => {
          tempBusinessTrades.push({
            id,
            availableOptions: calculateAvailableOptions(index, props.selectedValues),
            selectedOption: value,
            searchString: value.name,
            open: false,
          });
          id += 1;
        });
      } else {
        tempBusinessTrades.push({
          id,
          availableOptions: options,
          selectedOption: { name: '', order: 1 },
          searchString: '',
          open: false,
        });
        id += 1;
      }
      setCurrentAutocompleteIndx(id);
    }
    return tempBusinessTrades;
  });

  const onOpen = (index: number) => {
    if (businessTrades[index].searchString) {
      setBusinessTrades((prevBusinessTrades) => prevBusinessTrades.map((item, idx) => {
        if (idx === index) {
          return ({
            ...item,
            open: true,
          });
        }
        return item;
      }));
    }
  };
  const onClose = (index: number) => {
    setBusinessTrades((prevBusinessTrades) => prevBusinessTrades.map((item, idx) => {
      if (idx === index) {
        return ({
          ...item,
          open: false,
        });
      }
      return item;
    }));
  };

  const onInputChange = (inputIndx: number) => (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (reason !== 'clear') {
      const foundIdx = businessTrades.findIndex((item, indx) => indx === inputIndx);
      setBusinessTrades((prevBusinessTrades) => prevBusinessTrades.map((item, itemIndx) => {
        if (itemIndx === foundIdx) {
          return ({
            ...item,
            searchString: value,
          });
        }
        return item;
      }));
    }
  };

  const onTradeSelection = (inputIndx: number, questionOption: QuestionOption | null) => {
    if (questionOption) {
      // update selected value
      const foundIndx = businessTrades.findIndex((item, indx) => indx === inputIndx);
      let newBusinessTrades = businessTrades.map((item, itemIndx) => {
        if (itemIndx === foundIndx) {
          return ({
            ...item,
            selectedOption: { ...item.selectedOption, name: questionOption?.value },
          });
        }
        return item;
      });

      // update Available Options
      const selectedVals = newBusinessTrades.map((trade) => trade.selectedOption);
      newBusinessTrades = newBusinessTrades.map((item, newItemIdx) => ({
        ...item,
        availableOptions: calculateAvailableOptions(newItemIdx, selectedVals),
      }));
      setBusinessTrades((prevBusinessTrades) => newBusinessTrades);

      // dispatch new values to parent component
      // only dispatch to parent component non-empty business trades
      let parentBusinessTrades = newBusinessTrades.map(
        (trade) => trade.selectedOption,
      ).filter((item) => !!(item.name));

      // update the order
      parentBusinessTrades = parentBusinessTrades.map((item, parentIndx) => ({
        ...item,
        order: parentIndx + 1,
      }));

      onChange(parentBusinessTrades.length > 0 ? parentBusinessTrades : null);
    }
  };

  const removeTrade = (inputIndx: number) => {
    const removeIdx = businessTrades.findIndex((item, indx) => indx === inputIndx);
    if (removeIdx > -1) { // only splice array when item is found
      let newBusinessTrades = businessTrades.filter((item, itemIndx) => itemIndx !== removeIdx);
      // update order
      newBusinessTrades = newBusinessTrades.map((item, indx) => ({
        ...item,
        selectedOption: { ...item.selectedOption, order: indx + 1 },
      }));

      // update Available Options
      const selectedVals = newBusinessTrades.map((trade) => trade.selectedOption);
      newBusinessTrades = newBusinessTrades.map((item, newItemIdx) => ({
        ...item,
        availableOptions: calculateAvailableOptions(newItemIdx, selectedVals),
      }));

      setBusinessTrades((prevBusinessTrades) => newBusinessTrades);

      // dispatch new values to parent component
      // only dispatch to parent component non-empty business trades
      let parentBusinessTrades = newBusinessTrades.map(
        (trade) => trade.selectedOption,
      ).filter((item) => !!(item.name));

      // update the order
      parentBusinessTrades = parentBusinessTrades.map((item, parentIndx) => ({
        ...item,
        order: parentIndx + 1,
      }));

      onChange(parentBusinessTrades.length > 0 ? parentBusinessTrades : null);
    }
  };

  const getValue = (inputIndx: number) => {
    const foundIndx = businessTrades.findIndex((item, indx) => indx === inputIndx);
    const res = options
      .find((item: QuestionOption) => item.display ===
        businessTrades[foundIndx]?.selectedOption?.name);
    return res;
  };

  const sxHideClearButton = {
    '& div .MuiAutocomplete-popupIndicator': {
      display: 'none !important',
    },
  };

  const sxTextField = (theme: Theme) => ({
    width: '100%',
    paddingBottom: theme.spacing(1),
  });

  const addAdditionalTrade = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (options && businessTrades) {
      const selectedValues = businessTrades?.map((trade) => trade.selectedOption);
      const tempAvailableOptions = options?.filter((obj1) => !selectedValues
        .some((obj2) => obj2.name === obj1.value));
      const newIndx = businessTrades.length;
      const id = currentAutocompleteIndx;
      setCurrentAutocompleteIndx(currentAutocompleteIndx + 1);
      const newTrade = {
        id,
        availableOptions: tempAvailableOptions,
        selectedOption: {
          name: '',
          order: newIndx + 1, // order are indexed starting from 1
        },
        open: false,
        searchString: '',
      };
      setBusinessTrades((previousBusinessTrades) => [...previousBusinessTrades, newTrade]);
    }
  };

  return (
    <Box>
      {businessTrades && businessTrades?.map((trade, index) => (
        <Box key={`box-${trade.id}`}>
          {index !== 0 &&
            <Typography
              sx={{
                mt: index === 0 ? 0 : 3,
                textAlign: 'left',
                color: 'rgba(60, 60, 67, 0.6)',
              }}>
              Additional Business or Trade
            </Typography>
          }
          <StyledAutocomplete
            key={`autcomplete-${trade.id}`}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: 'flip',
                    enabled: false,
                  },
                  {
                    name: 'preventOverflow',
                    enabled: false,
                  },
                ],
              },
            }}
            classes={{ ...props.classes }}
            clearIcon={
              <CloseIcon />
            }
            sx={{
              ...sxHideClearButton,
              '& div .MuiAutocomplete-clearIndicator': {
                display: index === 0 ? 'none !important' : '',
              },
            }}
            disablePortal
            onBlur={onBlur}
            options={trade?.availableOptions}
            value={getValue(index) || null}
            open={trade?.open}
            onOpen={() => onOpen(index)}
            onClose={() => onClose(index)}
            onInputChange={onInputChange(index)}
            onChange={(e, newValue, reason) => {
              if (reason === 'clear' && index !== 0) { // remove addition business trade
                removeTrade(index);
                return;
              }
              onTradeSelection(index, newValue);
            }}
            getOptionLabel={(option: QuestionOption) => option?.display || ''}
            openOnFocus={false}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                sx={sxTextField}
                {...params}
                placeholder={placeholderText}
                id={placeholderText}
              />
            )}
          />
        </Box>
      ))}
      {businessTrades.length < maxNumberOfList &&
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Typography
            sx={(theme: Theme) => ({
              color: theme.palette.secondary.main,
              cursor: 'pointer',
            })}
            variant='h6' onClick={addAdditionalTrade}>+ Add Additional</Typography>
        </Box>
      }
    </Box>
  );
};
