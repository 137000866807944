import {
  Box, Theme, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSolutionOrg } from '../config/useSolutionOrg';
import { DashboardQuestion, SFDashboardQuestions, SFDashboardQuestionsHDOnly } from '../assets/dashboardQuestions';
import { isPriorCompanyEnrollment } from './utils/DateUtils';
import { useAppSelector } from '../store/hooks';
import { selectCompanyEnrollmentInformation } from '../store/slices/onboardingSlice';

const optionalQuestionStyle = (theme: Theme) => ({
  width: '100%',
} as const);

const questionStyle = (theme: Theme) => ({
  marginTop: '11px',
  [theme.breakpoints.down('md')]: {
    marginTop: '11px',
  },
  marginBottom: '16px',
} as const);

interface RewardsQuestionsProps {
  noTitle?: boolean;
}

export default function RewardsQuestions({ noTitle }: RewardsQuestionsProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>(false);
  const isDesktopScreen = useMediaQuery(() => theme.breakpoints.up('lg'));
  const { FAQs } = useSolutionOrg();
  const companyEnrollmentInformation = useAppSelector(selectCompanyEnrollmentInformation);
  const { solutionOrgHasOnlyHomeDepot, hasDateBasedPartnerListCard } = useSolutionOrg();
  // Memoize the computation of showing SF FAQs with all partners or with Home Depot only
  const showFAQsForAllPartners = useMemo(() => !solutionOrgHasOnlyHomeDepot || (
    hasDateBasedPartnerListCard && isPriorCompanyEnrollment(
      companyEnrollmentInformation?.createdDate,
    )
  ), [solutionOrgHasOnlyHomeDepot,
    hasDateBasedPartnerListCard,
    companyEnrollmentInformation?.createdDate]);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getFAQs = () => ((FAQs as DashboardQuestion[]) ||
    (showFAQsForAllPartners ? SFDashboardQuestions : SFDashboardQuestionsHDOnly));

  return (
    <>
      {!noTitle && <Box sx={{
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '25px',
        textAlign: 'center',
        letterSpacing: '0.38px',
        color: '#181818',
        marginTop: '23px',
      }}>
        {t('onboarding.rewardsQuestion.description1')} <span style={{
          color: theme.palette.secondary.main,
        }}>{t('onboarding.rewardsQuestion.description2')}</span>
      </Box>}
      <Box sx={isDesktopScreen ? [questionStyle, optionalQuestionStyle] : [questionStyle]}>
        {getFAQs().map((question, index) => (
          <Accordion key={index}
            expanded={expanded === question.name} onChange={handleChange(question.name)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{
                color: theme.palette.secondary.main,
              }} />}
              sx={{
                borderRadius: '13px',
                margintBottom: '8px',
              }}
            >
              <Typography sx={{
                lineHeight: '25px',
                letterSpacing: '0.38px',
              }}
                variant='h3'
                component={'div'}
              >
                {question.summary}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* uses default variant of body1 */}
              <Typography
                component={'div'}
              >
                {<question.detail />}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  );
}
