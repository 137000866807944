import { useState, useEffect } from 'react';
import { MktoForms2Type } from '../../../common/models/Marketo';

interface MarketoProps {
  baseUrl: string;
  munchkinId: string;
  formId: number;
}

function appendScript(baseUrl: string, setScriptLoaded: (value: boolean) => void) {
  if ((window as unknown as Window & { MktoForms2?: MktoForms2Type }).MktoForms2) {
    setScriptLoaded(true);
  } else {
    const script = document.createElement('script');
    script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
    script.onload = () => ((window as unknown as Window & { MktoForms2?: MktoForms2Type })
      .MktoForms2 ? setScriptLoaded(true) : null);
    document.head.appendChild(script);
  }
}

function useMarketo({ baseUrl, munchkinId, formId }: MarketoProps) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);

  useEffect(() => {
    if (scriptLoaded) {
      if (!formLoaded) {
        (window as unknown as Window & { MktoForms2?: MktoForms2Type })
          .MktoForms2?.loadForm(baseUrl, munchkinId, formId);
        setFormLoaded(true);
      }
    } else {
      appendScript(baseUrl, setScriptLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded]);
}

export default useMarketo;
