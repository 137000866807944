import {
  Grid, Theme, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PodcastChannelViewProps {
  Logo?: () => JSX.Element,
  channelName?: string | null,
}

export default function PodcastChannelView({ Logo, channelName }: PodcastChannelViewProps) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1} alignItems={'center'}
      sx={(theme: Theme) => ({
        border: '1.5px solid #A6A6A6',
        borderRadius: '8px',
        margin: `${theme.spacing(0.75)} ${theme.spacing(1)}`,
        minWidth: theme.spacing(21.25),
        padding: `0 ${theme.spacing(0.75)}`,
      })}
    >
      <Grid item xs={3} sm={3} md={3} lg={2.5}>
        {Logo ? <Logo /> : null}
      </Grid>

      <Grid item
        sx={(theme: Theme) => ({
          textAlign: 'left',
          margin: `${theme.spacing(0.5)} 0 0 ${theme.spacing(1)}`,
          [theme.breakpoints.down('xs')]: {
            margin: 0,
          },
        })}
      >
        <Typography
          color="text.secondary"
          sx={{ fontSize: '12px' }}
        >
          {t('content.podcast.listen')}
        </Typography>
        <Typography
          sx={(theme: Theme) => ({
            position: 'relative',
            bottom: theme.spacing(0.375),
            fontSize: theme.spacing(1.5),
            fontWeight: 600,
            [theme.breakpoints.up('sm')]: {
              fontSize: theme.spacing(1.75),
            },
          })}
        >
          {channelName}
        </Typography>
      </Grid>
    </Grid >
  );
}
