/* eslint-disable no-nested-ternary */
import {
  Alert, Snackbar, Box, TextField, Theme, useTheme,
  Typography,
  Grid,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import _isEqual from 'lodash/isEqual';
import { AddMemberToPartnerInput } from '@optimus/models';
import CallToActionButton from '../../../common/CallToActionButton';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  UpdateHDSetupFlowStepsCompletionState,
  selectGetHomeDepotMemberRelationState,
  selectHomeDepotMemberRelation,
  selectUpdateMemberToPartnerState,
} from '../../../store/slices/dashboardSlice';
import { HDSetupFlowSteps } from '../../../common/enums';
import {
  updateMemberToPartner,
} from '../../../store/thunks/dashboardThunk';
import { selectMemberSession } from '../../../store/slices/onboardingSlice';
import LennoxAccountNumberCardSkeleton from '../../../common/LennoxAccountNumberCardSkeleton';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';
import { emailRegex } from '../../../common/constants';

interface IFormInputs {
  homeDepotProXtraEmail: string
}

interface HDEmailAddressCardProps {
  optionalCardStyle?: (theme: Theme) => {
    [key: string]: unknown;
  },
  optionalBtnStyles?: {
    [k: string]: unknown;
  };
  inputStyles?: {
    [k: string]: unknown;
  };
  dashboardLayout?: boolean;
}

export default function HDEmailAddressCard({
  optionalBtnStyles,
  inputStyles,
  dashboardLayout,
}: HDEmailAddressCardProps) {
  const { t } = useTranslation();
  const getHDMemberRelationState = useAppSelector(selectGetHomeDepotMemberRelationState);
  const setHDProXtraEmailState = useAppSelector(selectUpdateMemberToPartnerState);
  const hdMemberRelation = useAppSelector(selectHomeDepotMemberRelation);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const dispatch = useAppDispatch();
  const { memberId } = useAppSelector(selectMemberSession);
  const theme = useTheme();
  const [isDirty, setIsDirty] = useState(false);
  const {
    handleSubmit,
    control,
    formState,
  } = useForm<IFormInputs>({ mode: 'onChange' });
  const trackOnboardEvent = useOnboardEventTracking();

  const onSubmit = handleSubmit((data: IFormInputs) => {
    if (data.homeDepotProXtraEmail) {
      const request: AddMemberToPartnerInput = {
        partnerMemberEmail: data.homeDepotProXtraEmail,
      };

      dispatch(updateMemberToPartner({
        memberId,
        partnerId: process.env.REACT_APP_HOME_DEPOT_PARTNER_ID || '',
        data: request,
      }))
        .then(unwrapResult)
        .then(() => {
          dispatch(UpdateHDSetupFlowStepsCompletionState(
            HDSetupFlowSteps.EmailCaptured,
          ));
          setOpenSuccessSnackbar(true);
          setOpenErrorSnackbar(false);
          setIsDirty(false);
        })
        .catch((error: unknown) => {
          setOpenSuccessSnackbar(false);
          setOpenErrorSnackbar(true);
        });

      // Populate user event
      trackOnboardEvent('clicked-hd-card-confirm-email', {
        hdEmail: data.homeDepotProXtraEmail,
      });
    }
  });

  const SubmitCTA = () => (<CallToActionButton
    text={`${t('button.confirm')}`}
    type={'submit'}
    optionalStackStyles={!dashboardLayout ? {
      display: 'flex',
      justifyContent: 'center',
    } : undefined}
    sx={{
      position: 'static',
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      background: theme.palette.secondary.main,
      borderRadius: theme.spacing(3.75),
      fontWeight: '600',
      fontSize: theme.spacing(2.3),
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&:disabled': {
        backgroundColor: theme.palette.secondary.main,
        opacity: '0.4',
        color: theme.palette.common.white,
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        marginLeft: '0px',
      },
      ...(optionalBtnStyles && optionalBtnStyles),
      ...(!dashboardLayout && {
        [theme.breakpoints.between('sm', 'md')]: {
          width: '60% !important',
          marginLeft: '0px',
        },
      }),
    }}
    disable={(!isDirty) || !formState.isValid || setHDProXtraEmailState === 'pending'}
  // disable={(editMode && !isDirty) || !formState.isValid
  // || setHDProXtraEmailState === 'pending'}
  />);

  const AlertComp = () => (<Alert
    sx={{
      background: theme.palette.common.white,
      color: theme.palette.error.main,
      p: 0,
      ...(!dashboardLayout && {
        '&.MuiAlert-root': {
          marginBottom: '-32px',
        },
      }),
    }}
    severity='error'>
    {formState?.errors.homeDepotProXtraEmail?.message}
  </Alert>);

  const handleClose = () => {
    setOpenErrorSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  const controllerInputProps = () => (
    {
      sx: {
        borderRadius: theme.spacing(13),
        border: `1px solid ${theme.palette.color8.main}`,
        background: theme.palette.common.white,
        fontWeight: '400',
        fontSize: theme.spacing(2),
        paddingLeft: theme.spacing(2.1),
        ...(!dashboardLayout && {
          width: '100%',
          [theme.breakpoints.up('sm')]: {
            alignSelf: 'center',
          },
        }),
      },
    }
  );

  const controllerRules = () => (
    {
      required: `${t('homeDepot.requiredRule')}`,
      pattern: {
        value: emailRegex,
        message: `${t('error.invalidEmail')}`,
      },
    }
  );

  return (
    <>
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {t('success.dashboard')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {t('error.generalMessage')}
        </Alert>
      </Snackbar>
      {getHDMemberRelationState === 'pending' ? <LennoxAccountNumberCardSkeleton /> :
        // (formNotShowing ? null :
        <Box >
          <Box
            sx={{
              marginBottom: theme.spacing(1.5),
              marginTop: theme.spacing(1.5),
              color: theme.palette.grey56.main,
            }}
          >
            <Typography variant='body1'>{t('homeDepot.description')}</Typography>
          </Box>
          <form onSubmit={onSubmit}>
            {dashboardLayout ?
              <><Box sx={{
                marginTop: theme.spacing(1),
              }}>
                <Controller
                  name="homeDepotProXtraEmail"
                  control={control}
                  defaultValue={hdMemberRelation?.partnerMemberEmail || ''}
                  render={({
                    field: {
                      onChange, onBlur, value,
                    },
                  }) => <TextField
                      onChange={(event) => {
                        setIsDirty(!(_isEqual(
                          event.target.value,
                          hdMemberRelation?.partnerMemberEmail,
                        )));
                        onChange(event);
                      }}
                      onBlur={onBlur}
                      value={value}
                      type="text"
                      placeholder={`${t('homeDepot.emailInputPlaceholder')}`}
                      InputProps={controllerInputProps()}
                      sx={{
                        width: '100%',
                        ...(inputStyles && inputStyles),
                      }}
                    />}
                  rules={controllerRules()}
                />
                {(formState?.errors?.homeDepotProXtraEmail &&
                  formState.errors.homeDepotProXtraEmail.type !== 'required') &&
                  <AlertComp />
                }
              </Box>
                <SubmitCTA /></> : <Grid container
                  sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    pb: theme.spacing(4),
                    pt: theme.spacing(1),
                  }}
                >
                <Grid item xs={12} sm={7} md={8} lg={7.5}
                  sx={{
                    [theme.breakpoints.between('sm', 'md')]: {
                      ml: theme.spacing(13),
                    },
                  }}
                >
                  <Controller
                    name="homeDepotProXtraEmail"
                    control={control}
                    defaultValue={hdMemberRelation?.partnerMemberEmail || ''}
                    render={({
                      field: {
                        onChange, onBlur, value,
                      },
                    }) => <TextField
                        onChange={(event) => {
                          // if (editMode) {
                          setIsDirty(!(_isEqual(
                            event.target.value,
                            hdMemberRelation?.partnerMemberEmail,
                          )));
                          // }
                          onChange(event);
                        }}
                        onBlur={onBlur}
                        value={value}
                        type="text"
                        placeholder={`${t('homeDepot.emailInputPlaceholder')}`}
                        InputProps={controllerInputProps()}
                        sx={{
                          width: '100%',
                          ...(inputStyles && inputStyles),
                        }}
                      />}
                    rules={controllerRules()}
                  />
                  {(formState?.errors?.homeDepotProXtraEmail &&
                    formState.errors.homeDepotProXtraEmail.type !== 'required') &&
                    <AlertComp />
                  }
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={4}
                  sx={{
                    ml: 'auto',
                    [theme.breakpoints.down('md')]: {
                      marginTop: theme.spacing(2),
                    },
                  }}
                >
                  <SubmitCTA />
                </Grid>
              </Grid>
            }
          </form>
        </Box>
      }
    </>
  );
}
