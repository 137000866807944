/* eslint-disable no-param-reassign */
/**
 * @param featureList List of features separated by ','
 * @param defaultValue Value to be assigned to the flags in flag list
 * @returns Feature flag object containing the feature flag name as key and
 * boolean value.
 */
const getFeatureFlagsFromFeatureList =
  (featureList: string, defaultValue: boolean) => featureList.split(',').reduce((flags, flagName) => {
    const flag = flagName.trim();
    if (flag) {
      flags[flag] = defaultValue;
    }
    return flags;
  }, {} as Record<string, boolean>);

const allowedFlags = getFeatureFlagsFromFeatureList(
  process.env.REACT_APP_ALLOWED_FEATURE_FLAGS || '',
  true,
);

const blockedFlags = getFeatureFlagsFromFeatureList(
  process.env.REACT_APP_BLOCKED_FEATURE_FLAGS || '',
  false,
);

/**
 * Blocked flags would override the allowed flags in case of a conflict.
 * eg: If allowed flags are feat1,feat2 and blocked flags are feat2,feat3
 * then, the featureFlags object will have feat2 as false.
 */
export const featureFlags = {
  ...allowedFlags,
  ...blockedFlags,
};
