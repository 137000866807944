import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { CustomerSupportFormInput } from '@optimus/models';
import { LoginMemberRequest, LoginMemberResponse, LogoutResponse } from '../../common/models/AuthenticationRequest';
import * as loginAPI from '../../api/loginAPI';
import { extractTokenExpiry, customHistory } from '../../common/utils/loginUtils';
import { RootState } from '../storeSetup';
import {
  HOME_AUTH_LOGIN_ROUTE, JOIST_ONBOARDING_AUTH_LOGIN_ROUTE,
  JOIST_V2_ONBOARDING_AUTH_LOGIN_ROUTE, ONBOARDING_AUTH_LOGIN_ROUTE,
} from '../../routes/namedRoutes';
import { JOIST_DOMAIN, getAppDomain, getJoistVersion } from '../../config/domains';
import { SubmitSupportFormResponse } from '../../common/models/SubmitSupportFormResponse';

// fallback navigation
const windowNavigation = (path: string) => {
  window.location.href = path;
};

export const logout = createAsyncThunk<
  // Return type of the payload creator
  LogoutResponse | null,
  // First argument to the payload creator
  {
    isOnboarding?: boolean;
    navigate?: NavigateFunction;
    customAfterLoginPath?: string;
  },
  // Types for ThunkAPI
  {
    rejectValue: AxiosError;
    state: RootState;
  }>(
    'auth/logout',
    async (
      { isOnboarding, navigate, customAfterLoginPath },
      { rejectWithValue, dispatch, getState },
    ) => {
      const appDomain = getAppDomain();

      // get refresh token if present
      const { memberSession: { refreshToken } } = getState().onboarding;

      // clear cache
      dispatch({ type: 'logout/LOGOUT' });

      // redirect to login page
      let loginPath;

      // get joist version if joist domain
      const joistVersion = getJoistVersion();

      if (isOnboarding) {
        if (appDomain === JOIST_DOMAIN) {
          if (joistVersion === 'v2') {
            loginPath = JOIST_V2_ONBOARDING_AUTH_LOGIN_ROUTE;
          } else {
            loginPath = JOIST_ONBOARDING_AUTH_LOGIN_ROUTE;
          }
        } else {
          loginPath = ONBOARDING_AUTH_LOGIN_ROUTE;
        }
      } else {
        loginPath = HOME_AUTH_LOGIN_ROUTE;
      }

      if (window.location.pathname !== loginPath) {
        const navigateFunc = customHistory?.navigate || navigate || windowNavigation;
        navigateFunc(
          `${loginPath}?afterLoginPath=${customAfterLoginPath || window.location.pathname}`,
        );
      }

      try {
        const response = await loginAPI.logoutMember(refreshToken);
        console.log('logged out successfully');
        return response as LogoutResponse;
      } catch (err: unknown) {
        console.log(err);
        return rejectWithValue(err as AxiosError);
      } finally {
        // fallback to force redirecting to login page after logout
        if (window.location.pathname !== loginPath) {
          windowNavigation(
            `${loginPath}?afterLoginPath=${customAfterLoginPath || window.location.pathname}`,
          );
        }
      }
    },
  );

export const loginMember = createAsyncThunk<
  // Return type of the payload creator
  LoginMemberResponse,
  // First argument to the payload creator
  LoginMemberRequest,
  // Types for ThunkAPI
  {
    rejectValue: AxiosError;
  }>(
    'onboarding/loginMember',
    async (request, { rejectWithValue }) => {
      try {
        const response = await loginAPI.loginMember(request);
        const tokenExpiry = extractTokenExpiry(response.accessToken);
        return { ...response, tokenExpiry } as LoginMemberResponse;
      } catch (err: unknown) {
        return rejectWithValue(err as AxiosError);
      }
    },
  );

export const submitCustomerSupportForm = createAsyncThunk<
  // Return type of the payload creator
  SubmitSupportFormResponse,
  // First argument to the payload creator
  CustomerSupportFormInput,
  // Types for ThunkAPI
  {
    rejectValue: AxiosError;
  }>(
    'submitCustomerSupportForm',
    async (request, { rejectWithValue }) => {
      try {
        const response = await loginAPI.submitCustomerSupportForm(request);
        return response as SubmitSupportFormResponse;
      } catch (err: unknown) {
        return rejectWithValue(err as AxiosError);
      }
    },
  );
