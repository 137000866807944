/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable max-len */
export function PrivacyPolicy() {
  return (
<div>
<h1 style={{ textAlign: 'center' }}>JOIST PRIVACY POLICY</h1>
<p><strong><em>Last Modified: September 1, 2022</em></strong></p>
<p>This Privacy Policy (“<strong>Policy</strong>”) discloses the privacy practices for joist.com and joistapp.com (including all sub-domains, the “Sites”), which are owned and operated by Joist Software Inc. (“Joist,” “our,” “we” or “us”) as well as the use of any mobile device application created by Joist (each, the “Mobile App”) and our web-based business management applications for contractors (collectively, the “Web App”). The Sites, Mobile App, and Web App may be referred to collectively as our “Services.” &nbsp;This Privacy Policy applies to our collection, use, and disclosure of your information from our Services, including, without limitation, visitors to the Sites and unpaid users of the Services. This Policy is incorporated into our&nbsp;<span style={{ textDecorationLine: 'underline' }}><strong>Terms of Service</strong></span>.</p>
<ol>
<li>
<h2>ACCEPTANCE</h2>
</li>
</ol>
<p>By accessing or using the Services, you warrant and represent that you accept the data practices and terms detailed in this Policy. If you do not agree with this Policy, please discontinue your access or use of the Services immediately. Please note that this Privacy Policy does not address the privacy practices of third parties, including those with whom we may share information as set forth below. Please review the privacy policies of any third parties before you disclose information to them.</p>
<ol start={2}>
<li>
<h2>CHANGES TO THIS PRIVACY POLICY</h2>
</li>
</ol>
<p>We may revise this Privacy Policy from time to time and without prior notice to you. Changes may apply to any personal information we already hold about you, except as otherwise set forth in this Privacy Policy, and any new personal information collected after the Policy is modified. If we make changes, we will notify you by revising the “Last Modified” date at the top of this Policy which will always be at <span style={{ textDecorationLine: 'underline' }}>https://joist.wpengine.com/privacy</span>. By continuing to access or use the Services after those changes become effective, you agree to be bound by the revised Privacy Policy.</p>
<p>In addition, we may provide you with “just-in-time” disclosures or additional information about the data handling practices of specific parts of our Services. Such notices may supplement this Policy or provide you with additional choices about how we process your personal information.</p>
<ol start={3}>
<li>
<h2>OUR RELATIONSHIP TO YOU</h2>
</li>
</ol>
<p>To understand Joist's data protection obligations and your rights to your personal information under this Policy, it is important that you identify which relationship(s) you have with Joist.</p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li>“<strong>Contractors</strong>” refers to the registered users of the Joist Services including Contractors with both paid and free or trial accounts, on whose behalf Joist provides contractor business management services, including collecting information about a Contractor's customers and the jobs estimated and/or executed for those customers. Authorized users of a Contractor's Joist paid, free, and/or demo accounts are collectively and individually referred to as “Contractor Users.”Joist has a service provider relationship with Contractors, and acts as a “data processor” when it stores, processes, transmits, or otherwise shares the consumer data it collects on behalf of the Contractor.&nbsp; Joist is in a “data controller” or direct relationship with Contractor Users.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li>“<strong>End Customers</strong>” refers to individuals doing business with a Contractor utilizing Joist Services to provide estimates and otherwise service their accounts. If you are an End Customer, Joist will collect and share your Personal Information solely on behalf of a Contractor, and solely based on the instructions provided by the Contractor. Your agreement with the relevant Contractor should explain how the Contractor shares your Personal Information with Joist and other third parties, and if you have questions about this sharing, then you should direct those questions to the Contractor.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li>“<strong>Visitors</strong>” refers to any individual accessing the Sites, as well as to any individual submitting Personal Information via the Sites for any reason, including but not limited to submitting a “contact us” or other online inquiry form, subscribing to a newsletter or blog, registering for a demo or webinar, or completing an online survey. Joist is in a “data controller” or direct relationship with Visitors.</li>
</ul>
</li>
</ul>
<p>Hereinafter we may refer to Contractors, End Customers, and Visitors individually and collectively as “you.”</p>
<ol start={4}>
<li>
<h2>COLLECTION OF PERSONAL INFORMATION</h2>
</li>
</ol>
<p>For purposes of this policy, “Personal Information” refers to any information about an identified or identifiable individual, including financial account information, and any device information that may be linked with an identifiable individual. Any information that is anonymized or aggregated is no longer Personal Information and we may use it and share it for any reason.</p>
<p>We collect Personal Information from and about Visitors to our Sites, we collect Personal Information from and about Contractors and Contractor Users through our Sites and Services, and we collect Personal Information from and about End Customers and other individuals who do business with Contractors that use our Services.</p>
<p>Parts of our Sites are public, such as our blog, and any information that is disclosed on such public parts of our Sites may appear on search engines or other publicly available platforms, and may be “crawled,” searched and used by other Visitors, Contractors, Contractor Users, End Customers, or other third parties. Please do not post any information that you do not want to reveal publicly.</p>
<p>In all cases where we share Personal Information with third parties, we will use a “minimum necessary” standard to disclose only that information required for satisfying the purpose of or performing the service for which the information is disclosed.</p>
<p>We generally collect and use information as follows:</p>
<h3></h3>
<h3></h3>
<h3><strong>Information from Visitors to the Sites</strong></h3>
<p><strong>Information we collect from Visitors and when we collect it:</strong></p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Information You Provide to Us:</strong> We collect the Personal Information you provide to us when you submit a “contact us” or other online inquiry form, when you comment to a blog, or when you email, call, write, fax or otherwise initiate contact with Joist. We record your contact information (name, address, phone number, and email address, and any other information you provide) in our CRM (Customer Relationship Management) system.</li>
<li><strong>Performance and Log Data:</strong> When you visit the Sites our servers (which may be hosted by a third party service provider) automatically record information created by your use of our Sites, which may include information such as, but not limited to, your IP address, browser type, operating system, command line information, diagnostic information related to the Services (i.e., crash activity reports), the referring web page, pages visited, location, your mobile carrier, device and application IDs, and search terms. Please read the “Automated Data Collection Technologies” section of this Policy for more information.</li>
<li><strong>Cookies and Similar Tracking Technologies:</strong> We use technologies like cookies, web beacons, pixel tags and other similar tracking technologies to gather information about how you are interacting with the Sites and Services, which may include identifying your IP address, browser type, and referring page. User sessions originating from an EU IP address are provided the opportunity to opt-out of accepting all non-essential cookies. Please read our</li>
</ul>
</li>
</ul>
<p><strong>How we use the information we collect from Visitors:</strong></p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>To Provide Customer Service:</strong> When you contact us with information requests, newsletter subscriptions, questions, concerns, disputes, feedback, or any for other reason, we use your Personal Information to respond to you.</li>
<li><strong>To Engage in Marketing and Promotional Activities: </strong>In order to streamline certain business operations, develop products and services that better meet the interests and needs of our customers, and inform our customers about relevant products and services, we may share your Personal Information with any of our current or future affiliated entities, subsidiaries and parent companies (“Affiliates”). You hereby agree to our sharing some or all of your information and Personal Information with our Affiliates. We may engage with you through marketing and promotional activities, including: sending you marketing communications; online surveys; alerting you about events, webinars, or other materials, including those of our partners and Affiliates; and updating you about our relevant products and services. You can opt-out of our marketing activities at any time.</li>
<li><strong>For Research and Development:</strong> We typically use information collected via Automated Data Collection Technologies for functional purposes, to improve the performance and usability of our Sites, and to analyze how users interact with the Services, as described in “Automated Data Collection Technologies” (below). We may also create anonymous records from Personal Information for certain business purposes of Joist or its Affiliates, including but not limited to, reporting, directing future development efforts, and analyzing usage patterns so that we may enhance our Sites and Services.</li>
<li><strong>For Security Purposes:</strong> We process your Personal Information to enhance the security of our Services and to combat spam, malware or other security risks. Our processing includes monitoring your activities on our Services. Without processing your personal information for such purposes, we may not be able to ensure the security of our Services.</li>
</ul>
</li>
</ul>
<p><strong>How we share the information we collect from Visitors:</strong></p>
<p>Please read the “How We Share Information with Third Parties” section of this Policy for detailed information on how we share your Personal Information. We may share anonymized information with any third party for any reason.</p>
<p>Generally, we share a Visitor's Personal Information as follows:</p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Consent:</strong> When we have your consent to do so, we share your Personal Information as you have agreed.</li>
<li><strong>Referral Partners:</strong> When you complete an online inquiry form to which you were referred by a third party, any information collected on the Joist hosted online inquiry form may be shared with the referring party, and that referring party may use it for their own, non-Joist related, marketing communication programs.</li>
<li><strong>Marketing Partners.</strong> We share your Personal Information with third parties for marketing or advertising purposes, as permitted by law. For example, when you sign up to a webinar co-hosted by us and a third-party partner, we may share your personal information with the third-party partner.</li>
<li><strong>Service Providers:</strong> We share your Personal Information with our trusted service providers who provide certain services on our behalf. Our contracts dictate that these service providers only use your information in connection with the services they perform for us and in accordance with this Privacy Policy.</li>
<li><strong>Business Transfers:</strong> If we are involved in a merger or acquisition or some or all of our assets are acquired by another company, any Personal Information we possess may be a part of the assets transferred, and that company will possess any rights granted to us under this Privacy Policy. We will require that your Personal Information is protected in accordance with this Policy.</li>
<li><strong>Compliance, Safety, and Protection:</strong> Personal information we collect may be shared with law enforcement, public authorities, or other third parties for purposes of compliance with the law or in response to subpoenas.</li>
</ul>
</li>
</ul>
<h3></h3>
<h3><strong>From Contractors and Contractor Users Utilizing the Sites and Services</strong></h3>
<p><strong>Information we collect from Contractors and Contractor Users and when we collect it:</strong></p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Account Information</strong>: We collect certain information from you when you register for an account with us, including registering for a free or demo account, such as your name, business name, phone number, e-mail address and business industry. To obtain a Joist paid account, we collect additional Personal Information, including but not limited to, your social security number (we may use the last 4 digits provided to obtain and store the full social security number), driver's license state and number, Employer Identification Number (Tax ID), and billing information. We also collect information about your business (such as payment types accepted, products and services offered, business hours, staff names and contact information, etc.) as part of the account configuration process that enables you to use the Services.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Financial Information</strong>: When you obtain a Joist paid account we collect information about your or your business' payment methods, such as credit or debit card numbers, bank account numbers, merchant account identifiers, and billing address.</li>
<li><strong>Third Party Public Databases, Credit Bureaus &amp; ID Verification Partners</strong>: When you sign up to use the payment processing portion of our paid Services, we may obtain information about you from public databases, credit bureaus, and ID verification partners as part of determining eligibility for a Joist account and underwriting for merchant processing accounts with third parties. We may obtain information about your current and past name, address, job role, public employment profile, credit history, status on any sanctions lists maintained by public authorities, and other relevant data.</li>
<li><strong>Transaction Information:</strong> We collect information when you engage in transactions via our Services, such as when you process payments and refunds, configure recurring payment schedules, send and configure invoices, generate estimates, and other transaction-related information.</li>
<li><strong>Contractor User Information:</strong> When user accounts are created that enable individual Contractor employees to utilize the Services as Contractor Users we collect Personal Information for that user including name, phone number, email address, position, User ID and other required account security information.</li>
<li><strong>End Customer Information:</strong> We collect information about your customers when you enter it into our systems as part of using the Services for estimating, project management, processing payments, configuring recurring payment schedules, sending invoices to your customers, booking appointments, and other uses and activities relating to our provision of and your use of the Services. We also collect Personal Information directly from your End Customers when they enter it into our systems as part of using payment processing services associated with your account.</li>
</ul>
</li>
</ul>
<p>In cases where Contractors or Contractor Users enter a consumer's Personal Information into our systems, they certify that they have received any required authorization to do so from the consumer, and that the disclosure does not violate the Payment Card Industry Data Security Standard (PCI DSS), the Personal Information Protection and Electronic Documents Act (PIPEDA), the EU General Data Protection Regulation (GDPR), The Australian Privacy Principles (APPs) or any other law or regulation.</p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Device Information</strong>: When you download and use a Mobile App we may collect certain information automatically, such as the type of mobile device you use, your unique device ID, the IP address of your mobile device, your mobile phone number, your mobile operating system, the type of mobile internet browsers you use, and information about the way you use the Mobile App.</li>
<li><strong>Geolocation Data:</strong> If you provide us with permission while accessing the Services via a web browser or downloading and installing a Mobile App we may also collect precise information about the location of your computer or device.</li>
<li><strong>Information You Provide to Us:</strong> We collect information that you provide to us, such as when you create an account, submit a support ticket, engage in an online Chat, email or call our sales or service team, when you comment to a blog, or when you email, call, write, fax or otherwise initiate contact with Joist regarding our Sites and/or Services. We record your contact information (name, address, phone number, email address, account name and number, User ID, and any other information you provide) in our CRM (Customer Relationship Management) and support ticketing system.</li>
<li><strong>Performance and Log Data:</strong> When you visit the Sites and/or utilize the Services our servers (which may be hosted by a third party service provider) automatically record information created by your use of our Sites and Services, which may include information such as, but not limited to, your IP address, browser type, operating system, command line information, diagnostic information related to the Services (i.e., crash activity reports), the referring web page, pages visited, location, your mobile carrier, device and application IDs, and search terms. Please read the “Automated Data Collection Technologies” section of this Policy for more information.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Cookies and Similar Tracking Technologies:</strong> We use technologies like cookies, web beacons, pixel tags and other similar tracking technologies to gather information about how you are interacting with the Sites and Services, which may include identifying your IP address, browser type, and referring page. User sessions originating from an EU IP address are provided the opportunity to opt-out of accepting all non-essential cookies. Please read our</li>
</ul>
</li>
</ul>
<p><strong>How we use the information we collect from Contractors and Contractor Users:</strong></p>
<p>Generally, we collect and use information from you to provide, protect, and improve our Services, and to provide you with a personalized experience when using our Services. Some specific examples of how we may use your Personal Information include:</p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>To Provide You with the Services:</strong> We process your Personal Information when you sign up for and use our Services and when Contractor User accounts are created for authorized users of the Services. For example, we use your Personal Information to collect fees, to configure user accounts, to provide requested location services, to configure your account for generating estimates and invoices, to provision your account for payment processing, generating reports, enabling marketing activities, and other system related functions. Additionally, we use Personal Information about your customers, whether entered or imported by you directly or entered into our systems by an End Customer. We share this Personal Information with our service providers and partners to the extent necessary to provide you with the Services.</li>
<li><strong>To Qualify and Underwrite You for Payment Processing Services:</strong> When you register for a paid account that includes payment processing functions, we use your Personal Information to verify your identity, perform a credit check (if we obtain any necessary consents), ensure that you are not on any sanctions lists maintained by public authorities, and to otherwise qualify you to use Joist's paid services. We may share this information with our service providers as part of the process of underwriting you for a payment processing merchant account.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>For Payment Processing:</strong> For paid Contractor account holders, we use billing and financial information (bank account numbers/credit card numbers) to collect monthly subscription and usage fees as applicable. For Contractors utilizing payment processing services we use billing and financial information to facilitate the payments you process via the Services.Joist does not store any bank account or credit card information in its own systems. All payment card data and bank account data is stored by our third party vendors who are contractually required to maintain compliance with the PCI DSS and to adhere to all NACHA rules for ACH payment processing.</li>
<li><strong>To Enhance Your Experience on the Services:</strong> We process your personal information to provide you with a unique and personalized experience on our Services. For example, we keep track of your preferences (e.g., display name, etc.) to customize your application screens. We also monitor your site usage to identify bugs, analyze usage patterns, and optimize our Sites and Services. Without such processing, we may not be able to ensure your continued enjoyment of part or all of our Services.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>To Enforce Our Terms, Agreements and Policies:</strong> We process your Personal Information to actively monitor, investigate, prevent and mitigate any alleged or actual prohibited, illicit or illegal activities on our Services or violations of our terms and agreements with you. In addition, we may process your Personal Information to enforce our agreements with third parties and partners, and/or collect fees based on your use of our Services.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>To Maintain Legal/Regulatory Compliance and to Prevent Fraud:</strong> We process your Personal Information, and your customers' Personal Information, whether entered or imported by you directly, or whether entered directly by an End Customer, to comply with certain legal obligations, such as the PCI-DSS, tax law, anti-spam laws, or as otherwise necessary to fulfill our other business obligations; to identify, prevent, and mitigate fraud on our Services; and/or to manage risk as required under applicable law.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>To Provide You with Service-related Communications:</strong> We will contact you to keep you updated about your account and the Services, such as to provide account-specific technical support, to send notifications of system activity, to email you any reports to which you subscribe, to notify you of changes to the Services and our policies, to provide security updates, or to provide other service-related information to you.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>For Security Purposes:</strong> We process your Personal Information to enhance the security of our Services and to combat spam, malware or other security risks. Our processing includes monitoring your activities on our Services and verifying your identity and access to the Services.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>To Provide Customer Service:</strong> When you contact us with information requests, newsletter subscriptions, questions, concerns, disputes, feedback, or any for other reason, we use your Personal Information to respond to you.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>To Engage in Marketing and Promotional Activities:</strong> In order to streamline certain business operations, develop products and services that better meet the interests and needs of our customers, and inform our customers about relevant products and services, we may share your Personal Information with any of our current or future affiliated entities, subsidiaries and parent companies (“Affiliates”). You hereby agree to our sharing some or all of your information and Personal Information with our Affiliates. We may engage with you through marketing and promotional activities, including: sending you marketing communications; online surveys; alerting you about events, webinars, or other materials, including those of our partners and Affiliates; and updating you about our relevant products and services. You can opt-out of our marketing activities at any time.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>For Research and Development:</strong> To offer you new, customized, or improved features on our Services, we process your Personal Information for research and development purposes. Your Personal Information helps us better understand how Contractors, Contractor Users, and End Customers use and interact with our Services. Additionally, we typically use information collected via Automated Data Collection Technologies for functional purposes, to improve the performance and usability of our Sites and Services as described in “Automated Data Collection Technologies” (below). We may also create anonymous records from Personal Information for certain business purposes of Joist or its Affiliates, including but not limited to, reporting, directing future development efforts, and analyzing usage patterns so that we may enhance our Sites and Services.</li>
</ul>
</li>
</ul>
<p><strong>How we share the information we collect from Contractors and Contractor Users:</strong></p>
<p>Please read the “How We Share Information with Third Parties” section of this privacy policy for detailed information on how we share your Personal Information. We may share anonymized information with any third party for any reason.</p>
<p>Generally, we share Contractor and Contractor User Personal Information as follows:</p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Consent:</strong> When we have your consent to do so, we share your Personal Information as you have agreed.</li>
<li><strong>Service Providers:</strong> We share your Personal Information with our trusted service providers who provide certain services on our behalf, including but not limited to fraud prevention, business intelligence, customer relationship management, bill collection, payment processing, marketing, hosting, and other common technology services. Our contracts dictate that these service providers only use your information in connection with the Services they perform for us and in accordance with this Privacy Policy.</li>
<li><strong>Business Partners:</strong> We may share your Personal Information with our Affiliates as permitted by applicable law. We will require our Affiliates to comply with this Privacy Policy.</li>
<li><strong>Referral Partners:</strong> We may provide information, including Contractor and Contractor User Personal Information, to third party referral partners that work on behalf of or with us to provide some of the aspects of our services, to help us communicate with you, or to provide you with ancillary services offered by such referral partners. We may also receive information about you, including Personal Information, from our third party referral partners. However, these service providers and referral partners do not have any independent right to disclose this information (except to the same extent we would have a right to disclose that information under this Privacy Policy).</li>
<li><strong>Business Transfers:</strong> If we are involved in a merger or acquisition or some or all of our assets are acquired by another company, any Personal Information we possess may be a part of the assets transferred, and that company will possess any rights granted to us under this Privacy Policy. We will require that your Personal Information is protected in accordance with this Policy.</li>
<li><strong>Compliance, Safety, and Protection:</strong> Personal information we collect may be shared with law enforcement, public authorities, or other third parties for purposes of: compliance with the law; cooperation with law enforcement or national security requirements; in response to subpoenas; responding to lawful requests; compliance with law or credit card rules; participation in a lawful federal, state or local government investigation; protecting the rights of Joist, other Joist Contractors, Contractors Users or End Customers, and third parties; or to investigate violations of or to enforce our Terms of Service and other policies or procedures.</li>
</ul>
</li>
</ul>
<h3></h3>
<h3><strong>From End Customers Using the Sites and Services</strong></h3>
<p>Any Personal Information we collect about a consumer or other individual, whether entered directly into our systems by the consumer as an End Customer or entered or imported by an authorized Contractor User, is used solely for the purpose of providing our Services and is not shared with third parties for any reason other than providing our Services, unless otherwise set forth herein.</p>
<p>We may share anonymized information with any third party for any reason.</p>
<p><strong>Information we collect from End Customers and when we collect it:</strong></p>
<p>All collection of Personal Information from and about consumers is performed on behalf of the Contractor with which the consumer interacts or otherwise does business via our Services. Generally, we collect End Customer Personal Information as follows:</p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Transaction/Order Information: </strong>When you use our Services to receive an estimate, receive an invoice, submit a payment, authorize a recurring payment plan or schedule, or otherwise do business with a Contractor, we collect information necessary to process that transaction, that may include your name, address, zip/postal code, email address, phone number, credit card or financial account number, IP address, project details, and any other information necessary to process or authenticate the transaction and/or generate or process an invoice or estimate.Joist does not store any bank account or credit card information in its own systems. All payment card data and bank account data are stored by our third party vendors who are contractually required to maintain compliance with the PCI DSS and to adhere to all NACHA rules for ACH payment processing.</li>
<li><strong>Geolocation Data:</strong> If you provide us with permission while accessing the Services via a web browser on a computer, laptop, or mobile device we may collect precise information about the location of your computer or device.</li>
<li><strong>Information You Provide to Us:</strong> While an End Customer's direct relationship is with the Contractor, and all questions or concerns should be directed to that Contractor, in the event an End Customer does contact Joist directly we collect information that you provide to us, such as when you engage in an online Chat, email or call our sales or service team, when you comment to a blog, or when you email, call, write, fax or otherwise initiate contact with Joist regarding our Sites and/or Services. We record your contact information (name, address, phone number, email address, account name and number, User ID, and any other information you provide) in our CRM (Customer Relationship Management) and support ticketing system.</li>
<li><strong>Performance and Log Data:</strong> When you visit the Sites and/or utilize the Services our servers (which may be hosted by a third party service provider) automatically record information created by your use of our Sites and Services, which may include information such as, but not limited to, your IP address, browser type, operating system, command line information, diagnostic information related to the Services (i.e., crash activity reports), the referring web page, pages visited, location, your mobile carrier, device and application IDs, and search terms. Please read the “Automated Data Collection Technologies” section of this Policy for more information.</li>
<li><strong>Cookies and Similar Tracking Technologies:</strong> We use technologies like cookies, web beacons, pixel tags and other similar tracking technologies to gather information about how you are interacting with the Sites and Services, which may include identifying your IP address, browser type, and referring page. User sessions originating from an EU IP address are provided the opportunity to opt-out of accepting all non-essential cookies. Please read our</li>
</ul>
</li>
</ul>
<p><strong>How we use the information we collect from End Customers:</strong></p>
<p>Generally, we collect and use information from End Customers on behalf of our Contractors to provide, protect, and improve our Services. Some specific examples of how we may use your Personal Information include:</p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>To Provide You with the Services:</strong> We process an End Customer's Personal Information when they use the Services as part of a business relationship with a Joist Contractor. For example, we use your Personal Information to facilitate payments through the Joist system via an integrated third party payment processor, to enable online invoice payments, to enable emailed invoices and estimates, and other system related functions performed on behalf of Contractors.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>To Send Receipts and Notifications</strong>: We use the email address you provide when making a payment, accepting an estimate, or utilizing any other feature of a Contractor's instance of our Services to send you, on behalf of the Contractor, payment receipts, payment schedule confirmations and notifications, invoices and invoice notifications, estimates, password reset emails, and other system notifications as required and as directed by the Contractor.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>To Enforce Our Terms, Agreements and Policies:</strong> We process your Personal Information to actively monitor, investigate, prevent and mitigate any alleged or actual prohibited, illicit or illegal activities on our Services or violations of our terms and agreements with you. In addition, we may process your Personal Information to enforce our agreements with third parties and partners.</li>
<li><strong>To Maintain Legal/Regulatory Compliance and to Prevent Fraud:</strong> We process your Personal Information to comply with certain legal obligations, such as the PCI-DSS, tax law, anti-spam laws, or as otherwise necessary to fulfill our other business obligations; identify, prevent, and mitigate fraud on our Services; and/or to manage risk as required under applicable law.</li>
<li><strong>For Security Purposes:</strong> We process your Personal Information to enhance the security of our Services and to combat spam, malware or other security risks. Our processing includes monitoring your activities on our Services.</li>
<li><strong>To Provide Customer Service:</strong> While an End Customers direct relationship is with the Contractor, and all questions or concerns should be directed to that Contractor, in the event an End Customer does contact Joist directly with information requests, questions, concerns, disputes, feedback, or any for other reason, we use your Personal Information to respond to you.</li>
<li><strong>For Research and Development: </strong>To offer new, customized or improved features on our Services we process your Personal Information for research and development purposes. Your Personal Information helps us better understand how Contractors and End Customers use and interact with our Services. Additionally, we typically use information collected via Automated Data Collection Technologies for functional purposes, to improve the performance and usability of our Sites and Services as described in “Automated Data Collection Technologies” (below). We may also create anonymous records from Personal Information for certain business purposes of Joist or its Affiliates, including but not limited to, reporting, directing future development efforts, and analyzing usage patterns so that we may enhance our Sites and Services.</li>
</ul>
</li>
</ul>
<p><strong>How we share the information we collect from End Customers:</strong></p>
<p>Please read the “How We Share Information with Third Parties” section of this privacy policy for detailed information on how we share your Personal Information. We may share anonymized information with any third party for any reason.</p>
<p><strong>Generally, we share End Customer Personal Information as follows:</strong></p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Consent:</strong> When we have your consent to do so, we share your Personal Information as you have agreed.</li>
<li><strong>Contractors:</strong> If you make a payment, receive or review an invoice or estimate, or otherwise interact with a Contractor via our Services, we may grant access to or share with the Contractor any and all information we collect as part of that transaction or interaction, except where that disclosure is prohibited by law, regulation or other obligations.</li>
<li><strong>Service Providers:</strong> We share your Personal Information with our trusted service providers who provide certain services on our behalf, including but not limited to fraud prevention, business intelligence, customer relationship management, payment processing, hosting, and other common technology services. Our contracts dictate that these service providers only use your information in connection with the Services they perform for us and in accordance with this Privacy Policy.</li>
<li><strong>Business Partners:</strong> We may share your Personal Information with our Affiliates as permitted by applicable law. We will require our Affiliates to comply with this Privacy Policy.</li>
<li><strong>Business Transfers:</strong> If we are involved in a merger or acquisition or some or all of our assets are acquired by another company, any Personal Information we possess may be a part of the assets transferred, and that company will possess any rights granted to us under this Privacy Policy. We will require that your Personal Information is protected in accordance with this Policy.</li>
<li><strong>Compliance, Safety, and Protection:</strong> Personal information we collect may be shared with law enforcement, public authorities, or other third parties for purposes of: compliance with the law; cooperation with law enforcement or national security requirements; responding to subpoenas, responding to lawful requests; compliance with law or credit card rules; participation in a lawful federal, state or local government investigation; protecting the rights of Joist, other Joist Contractors, Contractor Users, or End Customers, and third parties; or to investigate violations of or to enforce our Terms of Service and other policies or procedures.</li>
</ul>
</li>
</ul>
<ol start={5}>
<li>
<h2>AUTOMATED DATA COLLECTION TECHNOLOGIES</h2>
</li>
</ol>
<p>When you access the Site or Services, or open one of our HTML emails, we may automatically record certain information from your system by using cookies and other types of click-stream tracking technologies. This “automatically collected” information may include Internet Protocol address (“IP Address”), a unique user ID, device type, device identifiers, browser types and language, referring and exit pages, platform type, version of software installed, system type, the content and pages that you access on the Sites and Service, the number of clicks, the amount of time spent on pages, the dates and times that you visit the Sites and Service, and other similar information. Depending on the law of your country of residence, your IP address may legally be considered personally identifiable information.</p>
<p>We typically use these automated data collection technologies for essential and functional purposes (e.g. to maintain an active session), to improve the performance and usability of our Sites, and to analyze how users interact with the Services (e.g. to understand how long users stay on a page, how often they return, and how they arrived at our Site). On certain portions of our Sites we may collect data through these technologies for advertising, remarketing, or other similar purposes. Click-stream and related data is typically used for purposes of system administration, to improve our Services, for marketing and advertising-related purposes, and other similar uses.</p>
<ol start={6}>
<li>
<h2>COOKIES AND SIMILAR TECHNOLOGIES</h2>
</li>
</ol>
<p>Certain portions of our Services may collect information via cookies, web beacons, pixel tags, and similar digital tracking technologies. These technologies can be used to operate, secure and provide our Services by collecting and analyzing information related to your use of the Services.</p>
<p>Generally, this information is collected when you request pages from our Services, and typically includes information such as the page served, the time, the source and type of browser making the request, the most recent page view, what you clicked on to arrive at our Services, the content you viewed on our Services, and other similar information relating to your use of our Services. In some cases, we may use cookies and similar technologies to collect personal information, or information that becomes personal information if we combine it with other information.</p>
<p>Joist utilizes only strictly necessary and functional cookies as part of its application Services. Other cookies including analytics and marketing related cookies are utilized on our public-facing websites. User sessions originating from an EU or UK IP address are provided the opportunity to opt-out of these analytics and marketing cookies.</p>
<ol start={7}>
<li>
<h2>THIRD-PARTY INTEGRATED PAYMENTS SERVICES</h2>
</li>
</ol>
<p>Joist provides the ability to integrate the Services with select third party systems for payment processing (“Payments Service”). When a Contractor elects to establish a Joist integration with a Payments Service, all data in the Contractor account may be shared with the Payments Service (and all data in the Payments Service account may be shared with Joist) including personally identifiable information about the Contractor's consumer and non-consumer customers and prospects. Joist does not control the policies or procedures of these Payments Services, even though the integrations are provided through our Services, and Joist is not responsible for and has no control over how these third-party Payments Services function.</p>
<p>This Policy does not cover the collection or use of information, including consumer End Customer data, by Payments Services, and Contractors acknowledge that use and access to these Payments Services is solely at their own risk; and Contractors should consult the Terms and Privacy Policy for any Payments Services accessed via a Joist account to determine how they store, process, transmit, and otherwise utilize and protect personally identifiable information and the personally identifiable information Contractors provide about their customers, prospects, and other consumers, and/or that End Customers provide directly via the payment processing integration.</p>
<p>Any third-party vendors who are selected to provide a Payments Service integration with Joist are contractually required to maintain compliance with the PCI DSS and to adhere to all NACHA rules for ACH payment processing.&nbsp; However, Joist Contractors control the Payments Service they choose to integrate with their Joist account and are responsible for making sure that they do so in compliance with relevant privacy and data protection requirements.&nbsp; A list of all Payment Service providers and their associated privacy policies can be found at</p>
<p>Joist may provide the Personal Information we have collected from and/or about Contractors to Payments Services providers for the purpose of enabling them to market their products or services to you, if you have not opted out of these disclosures.</p>
<p><strong>&nbsp;</strong></p>
<ol start={8}>
<li>
<h2>HOW WE SHARE INFORMATION</h2>
</li>
</ol>
<p>Except as otherwise set forth in this Privacy Policy or in accordance with any request or consent you provide, Joist does not sell, share, or in any other way transmit Personal Information (names, address, phone numbers, financial account information, etc.) submitted by End Customers to any persons or companies other than the Contractor on whose behalf &nbsp;the data was obtained. We reserve the right, however, to use and disclose anonymous information to third parties, at our discretion. Except as limited below, third parties may use information we share with them for any purpose for which we may use such information.</p>
<p>Personal Information we collect from Visitors, Contractors, Contractor Users, and End Customers may be shared with service providers who provide certain services on our behalf, including but not limited to, identity verification, fraud prevention, business intelligence, customer relationship management, bill collection, payment processing, marketing, hosting, and other common technology services. Our contracts dictate that these service providers only use your information in connection with the services they perform for us and in accordance with this Privacy Policy, and you consent to our sharing with these parties by using our Sites and Services and accepting the terms of this Privacy Policy.</p>
<p>We may provide information, including Personal Information of Contractors and Contractor Users to third party service providers and referral partners that work on behalf of or with us to provide some of the aspects of our services, to help us communicate with you, or to provide Contractors with ancillary services offered by such service providers and referral partners. We may also receive information about you, including Personal Information, from our third party service providers and referral partners. However, these service providers and referral partners do not have any independent right to disclose this information (except to the same extent we would have a right to disclose that information under this Privacy Policy).</p>
<p>If you make a payment to or otherwise interact with a Contractor using our Services, we may grant access to or share with the Contractor any and all information we collect as part of that transaction, including credit card and other financial account information, except where that disclosure is prohibited by law, regulation or other obligations.</p>
<p>You hereby agree that we may share some or all of your Personal Information with our Affiliates. We will require our Affiliates to comply with this Privacy Policy. If our company or some or all of our assets are acquired by another company, any personal or other information we possess may be a part of the assets transferred, and that company will possess any rights granted to us under this Privacy Policy.</p>
<p>Finally, in extraordinary circumstances, we may share any personal or other information we possess, including but not limited to credit card and other financial account information, when necessary or appropriate to: comply with the law; cooperate with law enforcement or national security requirements; respond to subpoenas; &nbsp;respond to lawful requests; comply with law or credit card rules; participate in a lawful federal, state or local government investigation; protect the rights of Joist, other Joist Contractors, Contractor Users, and End Customers, and third parties; or to investigate violations of or to enforce our Terms of Service. However, in doing so, we may: (i) dispute demands for release to the extent we believe, in our sole discretion, are unwarranted, illegitimate or overbroad; and (ii) when we determine that it is necessary or appropriate, we will notify you of any requests for release.</p>
<p>In all cases where we share Personal Information with third parties, we will use a “minimum necessary” standard to disclose only that information required to perform the service for which the information is disclosed.<strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<ol start={9}>
<li>
<h2>DATA RETENTION</h2>
</li>
</ol>
<p>We will retain Personal Information for Contractors and Contractor Users as long as you remain an active user of our Services and for a reasonable time thereafter, to serve the purpose(s) for which your Personal Information was processed, or as necessary to comply with our legal obligations, to resolve disputes, or to enforce our agreements to the extent permitted by law. While retention requirements can vary by country, we generally apply the retention periods noted below.</p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>End Customer Personal Information:</strong> We may store on behalf of Contractors, for as long as a valid business reason exists, which may be indefinitely, any Personal Information collected about a consumer or other individual, whether entered directly into our systems by the End Customer, whether entered or imported by an authorized Contractor User, or whether imported via a Contractor integration with a third party service.Note that Contractors control any consumer data we collect and process on their behalf and it is up to the Contractor to determine how long they will store their customers' Personal Information in our systems.</li>
<li><strong>Your Interactions on Our Services:</strong> We may store any information about your interactions on our Sites or Services or any content created, posted or shared by you on our Sites or Services (e.g., pictures, comments, support tickets, and other content) for a period of time after the closure your account, which may be indefinitely, where a valid business reason exists for such storage such as to maintain the integrity of our systems and logs, for the establishment or defense of legal claims, audit and crime prevention purposes.</li>
<li><strong>Marketing:</strong> We store information used for marketing purposes indefinitely until you unsubscribe. Once you unsubscribe from marketing communications, we add your contact information to our suppression list to ensure we respect your unsubscribe request.</li>
<li><strong>Cookie Data:</strong> We retain any personal information collected via cookies, clear gifs, flash cookies, webpage counters and other technical or analytics tools up to one year from expiry of the cookie or the date of collection. Cookies owned by third parties may have other retention policies. See our for more information.</li>
<li><strong>Telephone Records:</strong> As required by applicable law, we will inform you that a call will be recorded before doing so. Any telephone calls with you may be kept for a period of up to six years.</li>
<li><strong>Anonymized and Aggregate Data:</strong> We may retain anonymized and aggregate data indefinitely.</li>
</ul>
</li>
</ul>
<p><strong>&nbsp;</strong></p>
<ol start={10}>
<li>
<h2>SECURITY</h2>
</li>
</ol>
<p>Joist has implemented measures designed to secure your Personal Information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our thirty party vendor's servers which utilize industry standard security practices.</p>
<p>The Joist Sites utilize secure SSL for all form data submissions in conjunction with a 256-bit Sectigo RSA certificate, but accommodate the majority of browsers at 128-bit encryption.</p>
<p>Joist does not directly store, process, or transmit bank account and credit card information. All payment processing, and collection of payment account data, is accomplished via integration with third-party Payments Services providers.&nbsp; These providers are contractually required to maintain compliance with the PCI DSS and with all NACHA rules for ACH transaction processing.&nbsp; This includes secure transmission of credit card/bank account information, and encrypted storage of all payment account information.</p>
<p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your Personal Information, we cannot guarantee the security of your Personal Information transmitted via our Sites or Services. Any transmission of Personal Information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Sites or the Services.</p>
<p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Sites or the Services, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. Additionally, do not leave your device unlocked so that other individuals may access your device or account. Joist is not in control of your wireless connection or the devices you use to log into the Services, so you should make sure you trust the devices and connections you use to access the Services.</p>
<p>If you believe that you have experienced unauthorized access or use of your account, please contact us immediately at privacy@joist.com</p>
<p><strong>&nbsp;</strong></p>
<ol start={11}>
<li>
<h2>CHOICES AND OPT-OUTS</h2>
</li>
</ol>
<p>You may opt-out of receiving marketing communications from us by following the opt-out instructions we include in such communications. Any communications from us that are not Service-related or transactional in nature will offer you an “unsubscribe” option so that you can opt out of receiving such messages.</p>
<p>You may opt-out of all of our information collection from your mobile device by uninstalling the Mobile App. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.</p>
<p>To the extent required by law, you may choose to opt out of sharing with any other parties with whom we may share your personal information; however, you may be unable to use the Services or certain features if you wish to limit such sharing.</p>
<p>For individuals located in the European Economic Area, United Kingdom and/or Switzerland, please refer to the “Designated Countries Privacy Rights” section below.</p>
<ol start={12}>
<li>
<h2>ACCESSING, CORRECTING AND DELETING YOUR INFORMATION</h2>
</li>
</ol>
<p>Joist acknowledges the right of individuals to access their Personal Information. Note that Joist will require you to verify your identity prior to releasing any Personal Information.</p>
<p>Generally, you may access, correct, and/or delete your Personal Information as follows:</p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li>Contractors and Contractor Users may access and modify Personal Information and other account information using the account settings page. You may also access, change and modify information previously provided or collected by sending an email to Joist at privacy@joist.com to initiate changes or modifications or to obtain a file for review.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li>Contractors may close their Joist account by contacting Joist Customer Care at 800.976.9261 or</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li>Contractors (and authorized representatives of Contractors on behalf of Contractor Users) may request that their Personal Information be removed from all Joist systems. To have any Personal Information permanently deleted from Joist systems, you must make an official request in writing by emailing <em>privacy@joist.com</em> and include the specific information that you would like permanently deleted from Joist systems. Note that if you request removal of your Personal Information you will no longer have access to any existing Joists account and will not be able to use any Joist product or service. Joist reserves the right to retain certain account information for its recordkeeping or compliance purposes.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li>Visitors and any other individuals who have submitted Personal Information directly to Joist via the Sites may request that their Personal Information be removed from all Joist systems once per year. To have your Personal Information permanently deleted from Joist systems, you must make an official request in writing by emailing <em>privacy@joist.com</em> and include specific information that you would like permanently deleted from Joist systems. Joist reserves the right to retain certain account information for its recordkeeping or compliance purposes.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li>End Customers and other consumers who do business with Contractors utilizing Joist Services can request that the Contractor provide you with access to the Personal Information Joist stores on its behalf, that it make changes to that Personal Information, and/or that the Personal Information be deleted from Joist systems. Joist cannot honor such requests directly from End Customers and other consumers, but will assist Contractors with honoring them in a timely manner.</li>
</ul>
</li>
</ul>
<p>Note that use of the system delete function by a Contractor to remove any data related to End Customers or potential End Customers (such deleting a record or deleting an estimate), or to remove any personal data about your company or its authorized Contractor Users, only restricts viewing that data from any system interface and prevents utilizing that data for any system function. It does not permanently delete the data from Joist systems. To have any personal data permanently deleted from Joist systems, you must make an official request in writing, to the address provided below or by emailing <em>privacy@joist.com</em> that includes the specific information that you would like permanently deleted from Joist systems. Note that Joist will require you to verify your identity prior to executing any request to permanently delete data.</p>
<p>For individuals located in the European Economic Area, United Kingdom and/or Switzerland, please refer to the “Designated Countries Privacy Rights” section below.</p>
<p>If you have any questions about your Personal Information or this policy, or if you would like to make a complaint about how Joist processes your personal data (as related to a breach of APPs, PIPEDA, any other data privacy law, rule, or regulation, or for any other reason) please contact Joist by email at privacy@joist.com, or by using the contact details below.</p>
<ol start={13}>
<li>
<h2>CALIFORNIA PRIVACY RIGHTS</h2>
</li>
</ol>
<p>If you are a California resident, you may request and obtain from us a list of what personal information (if any) we disclosed to third parties for direct marketing purposes in the preceding calendar year and the names and addresses of those third parties. &nbsp;California law provides that you have the right to receive the following information: (a) the categories of information we disclosed to third parties for the third parties' direct marketing purposes during the preceding calendar year; and (b) the names and addresses of third parties that received such information, or if the nature of their business cannot be determined from the name, then examples of the products or services marketed.</p>
<p>You are entitled to receive a copy of this information in a standardized format and the information will not be specific to you individually. You may make such a request by emailing us at <em>privacy@joist.com</em>.</p>
<ol start={14}>
<li>
<h2>A NOTE ABOUT CHILDREN</h2>
</li>
</ol>
<p>We do not intentionally gather information about individuals who are under the age of 18 or the age of majority in the country where they reside. If you are under the age of 18 or the age of majority you should not use our Services. If a Contractor, Contractor User, or End Customer submits personal information who is suspected of being younger than 18 years of age or the age of majority, we will require the Contractor, Contractor User, or End Consumer to close his or her account and will not allow continued use of the Services. We will also take steps to delete the information as soon as possible, unless an exception applies. Please notify us if you know of any individuals under the age of 18 or age of majority using our Services so we can take action to prevent access to our Services.</p>
<ol start={15}>
<li>
<h2>DESIGNATED COUNTRIES PRIVACY RIGHTS</h2>
</li>
</ol>
<p>This section only applies to users of our Services that are located in the European Economic Area, United Kingdom and/or Switzerland (collectively, the “Designated Countries”) at the time of data collection.</p>
<p>We may ask you to identify which country you are located in when you use some of the Services, or we may rely on your IP address to identify which country you are located in. When we rely on your IP address, we cannot apply the terms of this section to any individual that masks or otherwise hides their location information from us so as not to appear located in the Designated Countries. If any terms in this section conflict with other terms contained in this Policy, the terms in this section shall apply to users in the Designated Countries.</p>
<ol>
<li><strong>Our Relationship to You.</strong> Joist is a data controller with regard to any personal information directly collected from Visitors, Contractors, or Contractor Users of its Sites and Services. A “data controller” is an entity that determines the purposes for which and the manner in which any personal information is processed. Any third parties that handle your personal information in accordance with our instructions are our service providers and are “data processors.”Joist acts as a service provider to Contractors and is a “data processor” with regard to any consumer Personal Information imported by Contractors or Contractor Users into our systems via our Services—whether that consumer Personal Information is entered directly into our systems by the consumer as an End Customer, entered or imported by an authorized Contractor User, or imported by an authorized Contractor third party integration. As a “data processor” Joist does not have any direct control over the purposes for which and the manner in which any End Customer or potential End Customer personal information is processed; instead processing of this Personal Information is governed by data processing agreements between Joist and our Contractors.</li>
</ol>
<ol>
<li><strong>Legal Bases for Processing Personal Information. </strong>Below is a chart indicating the legal basis we rely on as a data controller for processing personal information of Visitors, Contractors, and Contractor Users.</li>
</ol>
<table>
<tbody>
<tr>
<td width="264"><strong>Purposes of Processing</strong></td>
<td width="240"><strong>Legal Basis for Processing</strong></td>
</tr>
<tr>
<td width="264">-&nbsp;&nbsp;&nbsp;&nbsp; To Provide You with the Services<p></p>
<p>-0.287467px&nbsp;&nbsp;&nbsp;&nbsp; To Enforce Our Terms, Agreements and Policies</p>
<p>-&nbsp;&nbsp;&nbsp;&nbsp; To Provide You with Service-related Communications</p>
<p>-&nbsp;&nbsp;&nbsp;&nbsp; For Security Purposes</p>
<p>-&nbsp;&nbsp;&nbsp;&nbsp; To Provide Customer Service</p>
<p>-&nbsp;&nbsp;&nbsp;&nbsp; Service Providers</p>
<p>-&nbsp;&nbsp;&nbsp;&nbsp; Business Transfers</p></td>
<td width="240">Processing is based on our contract obligations or to take steps at the request of the individual prior to entering into a contract.</td>
</tr>
</tbody>
</table>
<table>
<tbody>
<tr>
<td width="264">-&nbsp;&nbsp;&nbsp;&nbsp; Joint Marketing Partners and Social Media Platforms<p></p>
<p>-&nbsp;&nbsp;&nbsp;&nbsp; To Enhance Your Experience on the Services</p>
<p>-&nbsp;&nbsp;&nbsp;&nbsp; To Conduct Research and Development</p>
<p>-&nbsp;&nbsp;&nbsp;&nbsp; Business Transfers</p></td>
<td width="240">Processing is based on our legitimate interest to better understand you, to maintain and improve the accuracy of the information we store about you, and to better promote or optimize our Services.</td>
</tr>
<tr>
<td width="264">-&nbsp;&nbsp;&nbsp;&nbsp; To Maintain Legal/Regulatory Compliance and to Prevent Fraud<p></p>
<p>-&nbsp;&nbsp;&nbsp;&nbsp; Compliance, Safety and Protection</p></td>
<td width="240">Processing is necessary for compliance with our legal obligations, the public interest, or in your vital interests.</td>
</tr>
</tbody>
</table>
<ol>
<li>We will only contact individuals located in the Designated Countries by electronic means (including email or SMS) based on our legitimate interests, as permitted by applicable law, or the individual's consent. When we rely on legitimate interest, we will only send you information about our Services that are similar to those which were the subject of a previous sale or negotiations of a sale to you or as otherwise permitted by applicable law.</li>
</ol>
<p>If you do not want us to use your personal information in this way, or to disclose your personal information to third parties for marketing purposes, please go to the email settings for your account to opt out, click an unsubscribe link in your emails, or contact us at <em>privacy@joist.com</em> You can object to direct marketing at any time and free of charge.</p>
<ol>
<li><strong>Individual Rights.</strong> We provide you with the rights described below when you use our Services. We may limit your individual rights requests (a) where denial of access is required or authorized by law; (b) when granting access would have a negative impact on other's privacy; (c) to protect our rights and properties; or (4) where the request is frivolous or burdensome.</li>
</ol>
<p>If you would like to exercise your rights under applicable law, please contact us at <em>privacy@joist.com</em>. We may seek to verify your identity when we receive an individual rights request from you to ensure the security of your personal information.</p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Right to Withdraw Consent.</strong> For any consent-based processing of your personal information, you have the right to withdraw your consent at any time. A withdrawal of consent will not affect the lawfulness of our processing or the processing of any third parties based on consent before your withdrawal.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Right of Access</strong>. Upon your request, we will provide you with a copy of your personal information in our files without undue delay and free of charge, unless we are permitted by law to charge a fee. Your access may be limited to the extent it would adversely affect the rights and freedoms of other individuals.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Right to Rectification (or “Correction”)</strong>. You may request to correct or update any of your personal information in our files. We may provide you with the ability to update some or all of your personal information directly via the Services.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Right to Erasure (or the “Right to be Forgotten”).</strong> Upon your request, we will erase any of your personal information in our files that: is no longer necessary in relation to the purposes for which it was collected or otherwise processed; was collected in relation to processing that you previously consented to, but later withdrew such consent; or was collected in relation to processing activities to which you object, and there are no overriding legitimate grounds for our processing.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Right to Restriction</strong>. You have the right to restrict our processing of your personal information where one of the following conditions applies:</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li>You contest the accuracy of your personal information that we processed. If you restrict processing based on this condition, you may experience an interruption of some or all of the Services during the period necessary for us to verify the accuracy of your personal information;</li>
<li>The processing is unlawful and you oppose the erasure of your personal information and request the restriction of its use instead;</li>
<li>We no longer need your personal information for the purposes of the processing, but it is required by you to establish, exercise or defense of legal claims; or</li>
<li>You have objected to processing, pending the verification whether the legitimate grounds of our processing override your rights.</li>
</ul>
</li>
</ul>
<p>During the time which restriction of processing applies, we will only process your restricted personal information with your consent or for the establishment, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest. We will inform you if or when the restriction is lifted.</p>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Right to Object to Processing.</strong> You may object to our processing at any time and as permitted by applicable law if we process your personal information on the legal bases of: consent; contract; or legitimate interests. We may continue to process your personal information if it is necessary for the defense of legal claims, or for any other exceptions permitted by applicable law.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Right to Data Portability.</strong> If we process your personal information based in a contract with you or based on your consent, or the processing is carried out by automated means, you may request to receive your personal information in a structured, commonly used and machine-readable format, and to have us transfer your personal information directly to another “controller,” where technically feasible, unless exercise of this right adversely affects the rights and freedoms of others. You right to data portability only applies to personal information provided by you to us.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Notification to Third Parties</strong>. When we fulfill your individual rights requests for correct (or rectification), erasure or restriction of processing, we will notify third parties also handling the relevant personal information unless this proves impossible or involves disproportionate effort. Upon your request, we will identify such third parties.</li>
</ul>
</li>
</ul>
<ul>
<li style={{ listStyleType: 'none' }}>
<ul>
<li><strong>Right to Lodge Complaint.</strong> We commit to resolve complaints about our collection or use of your personal information. Individuals with inquiries or complaints regarding our Policy should first contact us at <em>privacy@joist.com</em>. You also have a right to lodge a complaint with a competent supervisory authority situated in a Member State of your habitual residence, place of work, or place of alleged infringement.&nbsp; A list of Supervisory Authorities is available here:&nbsp; <span style={{ textDecorationLine: 'underline' }}>http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></li>
</ul>
</li>
</ul>
<ol start={16}>
<li>
<h2>INTERNATIONAL TRANSFERS</h2>
</li>
</ol>
<p>If you are accessing our Services from outside of the United States, any information provided will be transferred to us or our service providers in the United States and other locations outside of the country or jurisdiction where you are located. Such countries or jurisdictions may have data protection laws that are less protective than the laws of the jurisdiction in which you reside. Some information may also be stored locally on devices you use to interact with our Services. You consent to such transfer through your continued use of our Services and if you do not want your information transferred to or processed or maintained outside of the country or jurisdiction where you are located, you should not use our Services.</p>
<p>Please note that Joist processes information on behalf of its Contractors. Contractors may be responsible for obtaining your consents relating to the collection, use, transfer and other processing of your Personal Information, may provide additional notices affecting our processing of your Personal Information, and may provide for additional limitations or permissions with respect to our processing of your information in order to comply with applicable law.</p>
<p><strong>&nbsp;</strong></p>
<ol start={17}>
<li>
<h2>CONTACT<strong> US</strong></h2>
</li>
</ol>
<p>Joist Software Inc.</p>
<p>Attention: Data Protection Officer</p>
<p>Toronto Address: 300-490 Adelaide St. W,&nbsp; Toronto, ON&nbsp;&nbsp; M5V 1T2</p>
<p>Telephone No: 1 (800) 976-9261</p>
<p>E-mail address: privacy@joist.com[/vc_column_text][/vc_column][/vc_row]</p>

    </div>
  );
}
