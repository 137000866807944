/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {
  Alert,
  Backdrop,
  CircularProgress,
  Container,
  Snackbar,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import CallToActionButton from '../common/CallToActionButton';
import Banner from './Onboarding/Home/common/Banner';
import {
  OptimusEvent, selectGetOnboardingFormStatus,
  selectMemberSession, updateOnboardingLandingPageViewDone,
} from '../store/slices/onboardingSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  JoistSolutionOrgName, OnboardingEventType, SolutionOrgName,
} from '../common/constants';
import { TermsOfServiceWrapper } from './TermsOfServiceWrapper';
import { TermsOfServiceWrapperJoist } from './TermsOfServiceWrapperJoist';
import { PageTransition } from './Onboarding/Home/common/PageTransition';
import { useTracking } from '../common/useTracking';
import { useLogo } from '../config/useLogo';
import { useSolutionOrg } from '../config/useSolutionOrg';
import { enrollMemberToReward, getOnboardingForm } from '../store/thunks/onboardingThunk';
import { useCompanyEnrollmentInfo } from '../common/useCompanyEnrollmentInfo';
import { EnrollmentRequest } from '../common/models/EnrollmentRequest';
import { HOME_DEPOT_SETUP_OR_SKIP_ROUTE } from '../routes/namedRoutes';
import { useOnboardEventTracking } from '../common/useOnboardEventTracking';

export function Home() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const trackEvent = useTracking();
  const {
    memberId: storeMemberId,
    rewardProgramId: storeRewardProgramId,
    memberDeviceType: storeMemberDeviceType,
    memberDeviceVersion: storeMemberDeviceVersion,
  } = useAppSelector(selectMemberSession);
  const [searchParams] = useSearchParams();
  const memberId = searchParams.get('memberId') || storeMemberId;
  const rewardProgramId = searchParams.get('rewardProgramId') || storeRewardProgramId;
  const memberDeviceType = searchParams.get('deviceType') || storeMemberDeviceType;
  const memberDeviceVersion = searchParams.get('deviceVersion') || storeMemberDeviceVersion;
  const { ellipseMobile: EllipseMobile, ellipseDesktop: EllipseDesktop } = useLogo();
  const {
    solutionOrgName, envSolutionOrgFormId,
    solutionOrgSpecificAppName,
    envRewardProgramId,
    envSolutionOrgId,
    enrollOnSplashScreen,
  } = useSolutionOrg();
  const onboardFormId = envSolutionOrgFormId as string;
  const getOnboardingFormStatus = useAppSelector(selectGetOnboardingFormStatus);
  const trackOnboardEvent = useOnboardEventTracking();
  const [loading, setLoading] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  // check company enrollment status
  useCompanyEnrollmentInfo({ calledFromOnboardingComponent: true });

  useEffect(() => {
    // only fetch form if we are not completing enrollment on the splash screen
    if (!enrollOnSplashScreen) {
      dispatch(getOnboardingForm(
        {
          formId: onboardFormId,
          trackEvent,
        },
      ));
    }
    // generate a rewards splash screen loaded event
    const optimusEvent: OptimusEvent = {
      name: 'rewards_splash_screen_loaded',
    };

    optimusEvent.detail = {
      ...(memberId && { memberId }),
      ...(rewardProgramId && { rewardProgramId }),
      ...(memberDeviceType && { memberDeviceType }),
      ...(memberDeviceVersion && { memberDeviceVersion }),
    };
    trackEvent({
      optimusEventType: OnboardingEventType,
      optimusEvents: [optimusEvent],
      solutionOrg: solutionOrgName as SolutionOrgName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToOnboard = () => {
    const optimusEvent: OptimusEvent = {
      name: 'rewards_splash_screen_cta_clicked',
    };

    optimusEvent.detail = {
      ...(memberId && { memberId }),
      ...(rewardProgramId && { rewardProgramId }),
      ...(memberDeviceType && { memberDeviceType }),
      ...(memberDeviceVersion && { memberDeviceVersion }),
    };
    trackEvent({
      optimusEventType: OnboardingEventType,
      optimusEvents: [optimusEvent],
      solutionOrg: solutionOrgName as SolutionOrgName,
    });

    // onboarding landing page screen view done
    dispatch(updateOnboardingLandingPageViewDone(true));

    navigate('form');
  };

  const enrollToEdge = () => {
    // Enroll with home depot as partner without completing company and business goals forms.
    setLoading(true);
    trackOnboardEvent('rewards_splash_screen_cta_clicked');
    const request: EnrollmentRequest = {
      memberId,
      rewardProgramId: (envRewardProgramId as string) || '',
      member: {},
      forms: [],
      partners: [
        {
          partnerId: process.env.REACT_APP_HOME_DEPOT_PARTNER_ID || '',
        },
      ],
    };
    const solutionOrgId = (envSolutionOrgId as string) || '';
    dispatch(enrollMemberToReward({ solutionOrgId, request }))
      .then(unwrapResult)
      .then(() => {
        setOpenErrorSnackbar(false);

        // send a rewards everpro signup completed event
        trackOnboardEvent('rewards_everpro_signup_completed');

        // navigate to home depot setup screen
        navigate(HOME_DEPOT_SETUP_OR_SKIP_ROUTE);
      })
      .catch((error: unknown) => {
        setOpenErrorSnackbar(true);
        setLoading(false);

        // send a rewards everpro signup failed event
        trackOnboardEvent('rewards_everpro_signup_failed', { error });
      });
  };

  const handleErrorClose = () => {
    setOpenErrorSnackbar(false);
  };

  const LetsGoBtn = (<CallToActionButton
    text={enrollOnSplashScreen ? `${t('button.enrollWith')} ${solutionOrgSpecificAppName}` :
    t('button.go') as string}
    disable={loading}
    onBtnClick={enrollOnSplashScreen ? enrollToEdge : navigateToOnboard}
    sx={{
      background: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&:disabled': {
        backgroundColor: theme.palette.color1.main,
        opacity: '0.4',
        color: theme.palette.common.white,
      },
      position: 'static',
      width: '100%',
      borderRadius: '30px',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        marginLeft: '0px',
      },
      [theme.breakpoints.up('lg')]: {
        position: 'static',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '48px',
        marginBottom: '24px',
        width: '100%',
        maxWidth: '500px',
      },
    }}
  />);

  return (
    (getOnboardingFormStatus === 'pending' && !enrollOnSplashScreen) ? null :
      (
        <>
          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={4000}
            onClose={handleErrorClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {t('error.generalMessage')}
            </Alert>
          </Snackbar>
          <Backdrop
              sx={{ color: theme.palette.common.white, zIndex: () => theme.zIndex.drawer + 1 }}
              open={loading}>
              <CircularProgress color="inherit" />
          </Backdrop>
          {isDesktop ?
            <PageTransition>
              < Box
                sx={{
                  display: 'flex',
                  postion: 'relative',
                }
                }
              >
                {EllipseDesktop && <EllipseDesktop style={{
                  position: 'absolute',
                  top: -32,
                  right: 0,
                  width: '180px',
                  height: '108px',
                }} />}
                <Box
                  sx={{ flex: 1 }}
                >
                  <Box>
                    <Banner customHeight={'75vh'} centreAlign={true} />
                  </Box>
                  {LetsGoBtn}
                  {solutionOrgName === JoistSolutionOrgName ?
                    <TermsOfServiceWrapperJoist /> :
                    <TermsOfServiceWrapper />}
                </Box>
              </Box >
            </PageTransition > :
            <Box >
              {EllipseMobile && <EllipseMobile style={{
                position: 'absolute',
                top: -88,
                left: -60,
              }} />}
              <Container maxWidth='sm'
                sx={{
                  textAlign: 'center',
                  marginTop: theme.spacing(3),
                  paddingTop: theme.spacing(0),
                  paddingBottom: theme.spacing(4),
                  [theme.breakpoints.down('sm')]: {
                    paddingTop: theme.spacing(6),
                  },
                }}
              >
                <Banner isFullHeight centreAlign={true} />
                <Box
                  sx={{
                    paddingTop: theme.spacing(6),
                    [theme.breakpoints.up('lg')]: {
                      marginTop: '-64px',
                    },
                    [theme.breakpoints.down('sm')]: {
                      paddingTop: theme.spacing(3),
                    },
                  }}
                >
                  {LetsGoBtn}
                </Box>
                {solutionOrgName === JoistSolutionOrgName ?
                  <TermsOfServiceWrapperJoist /> :
                  <TermsOfServiceWrapper />}
              </Container>
            </Box>}
        </>
      )
  );
}
