import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import ReactGA from 'react-ga4';
import theme from './assets/theme';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './assets/i18n/config';
import { setupStore } from './store/storeSetup';
import { injectStore } from './api/apiConfig';
import { featureFlags } from './config/featureFlags';
import { FeatureFlagProvider } from './config/FeatureFlagsProvider';
import { initSentry } from './config/sentry';
import ReactHeap from './config/heap';
import { getGAMeasurementId } from './config/googleAnalytics';

// Initialize Google Analytics tracking
ReactGA.initialize(
  getGAMeasurementId(),
);

ReactHeap.initialize();

initSentry();

// redux store
const store = setupStore();
const persistor = persistStore(store);

// inject store in api config to enable retrieving of access token
injectStore(store);

// start up mock service worker in development env
// TODO: disable this once all api endpoints exist on backend
// alternatively could just disable the handlers instead
async function enableMocking() {
  if (process.env.NODE_ENV !== 'development' ||
  process.env.REACT_APP_ENABLE_API_MOCKING !== 'true') {
    return Promise.resolve();
  }

  const { worker } = await import('./api/mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    // disable the logging of matched requests in a browser's console
    quiet: false,
    // perform as-is any request that is not listed in the request handlers
    onUnhandledRequest: 'bypass',
  });
}

const root = ReactDOM.createRoot(
  // eslint-disable-next-line no-undef
  document.getElementById('root') as HTMLElement,
);

enableMocking().then(() => {
  root.render(
    <>
  <React.StrictMode>
    <Provider store={store}>
      {/* used PersistGate to delay the rendering of our app’s UI until the
      persisted data is available in the Redux store */}
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
          <FeatureFlagProvider featureFlags={featureFlags}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <App />
            </FeatureFlagProvider>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
  </>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
