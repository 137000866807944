// eslint-disable-next-line no-shadow
export enum HDSetupFlowSteps {
  None = 'none',
  Step1 = 'step1',
  Step2 = 'step2',
  Step3 = 'step3',
  AllInstructionsSeen = 'all instructions seen',
  EmailCaptured = 'email captured',
  Done = 'done'
}

// eslint-disable-next-line no-shadow
export enum HDVerificationStatus {
  Pending = 'pending',
  Completed = 'completed',
  Error = 'error',
}
