import {
  EVERPRO_DOMAIN,
  JOIST_DOMAIN, SERVICE_FUSION_DOMAIN, UNKNOWN_DOMAIN, getAppDomain,
  getJoistVersion,
} from './domains';
import {
  ServiceFusionSolutionOrgName, JoistSolutionOrgName, JoistCode,
  InvoiceSimpleSolutionOrgName,
} from '../common/constants';
import { dashboardQuestionsEverPro, dashboardQuestionsJoist } from '../assets/dashboardQuestions';
import { useFeatureFlags } from './FeatureFlagsProvider';

interface SolutionOrgs {
  [key: string]: {
    [key: string]: unknown;
    isIframe?: boolean;
  }
}

// manage solution org related resources based on the current domain
export function useSolutionOrg() {
  const { homeDepotOnlySN, homeDepotOnlyEverPro } = useFeatureFlags();

  const solutionOrgs: SolutionOrgs = {
    [JOIST_DOMAIN]: {
      onboardingVersion: getJoistVersion(),
      solutionOrgName: JoistSolutionOrgName,
      envSolutionOrgFormId: process.env.REACT_APP_JOIST_EDGE_FORM_ID,
      numberOfOnboardingForms: 2,
      envSolutionOrgTitle: process.env.REACT_APP_JOIST_SITE_TITLE,
      envRewardProgramId: process.env.REACT_APP_REWARD_PROGRAM_ID,
      envSolutionOrgId: process.env.REACT_APP_SOLUTION_ID,
      IsInAppExperience: true,
      programCode: JoistCode,
      FAQs: dashboardQuestionsJoist,
      supportEmail: 'hello@joist.com',
      isIframe: window !== window.parent,
      solutionOrgHasOnlyHomeDepot: true,
      useHomeDepotOnlyBannerText: true,
      solutionOrgSpecificAppName: 'Joist Edge',
      enrollOnSplashScreen: window !== window.parent,
    },
    [SERVICE_FUSION_DOMAIN]: {
      solutionOrgName: ServiceFusionSolutionOrgName,
      envSolutionOrgTitle: process.env.REACT_APP_SITE_TITLE,
      termsOfServiceUrl: 'https://servicenation.com/edge-tos/',
      envSolutionOrgFormId: process.env.REACT_APP_SF_FORM_ID,
      numberOfOnboardingForms: homeDepotOnlySN ? 2 : 3,
      envRewardProgramId: process.env.REACT_APP_SF_REWARD_PROGRAM_ID,
      envEnableMarketoDataSubmission: process.env.REACT_APP_ENABLE_MARKETO_DATA_SUBMISSION === 'true',
      envSolutionOrgId: process.env.REACT_APP_SF_SOLUTION_ID,
      // SN Edge will also use the Joist code when enrolling in Home Depot
      programCode: JoistCode,
      supportEmail: 'support@servicefusion.com',
      solutionOrgHasOnlyHomeDepot: homeDepotOnlySN,
      hasDateBasedPartnerListCard: homeDepotOnlySN,
      solutionOrgSpecificAppName: 'Service Fusion Edge',
      enrollOnSplashScreen: window !== window.parent && homeDepotOnlySN,
    },
    [EVERPRO_DOMAIN]: {
      solutionOrgName: InvoiceSimpleSolutionOrgName,
      envSolutionOrgTitle: process.env.REACT_APP_EVER_PRO_SITE_TITLE,
      termsOfServiceUrl: 'https://servicenation.com/edge-tos/',
      envSolutionOrgFormId: process.env.REACT_APP_INVOICE_SIMPLE_FORM_ID,
      numberOfOnboardingForms: homeDepotOnlyEverPro ? 2 : 3,
      envRewardProgramId: process.env.REACT_APP_INVOICE_SIMPLE_REWARD_PROGRAM_ID,
      envSolutionOrgId: process.env.REACT_APP_INVOICE_SIMPLE_SOLUTION_ID,
      // Everpro Edge will also use the Joist code when enrolling in Home Depot
      programCode: JoistCode,
      solutionOrgSpecificAppName: 'EverPro Edge',
      // TODO: update these flags
      FAQs: dashboardQuestionsEverPro,
      supportEmail: 'support@invoicesimple.com',
      solutionOrgHasOnlyHomeDepot: homeDepotOnlyEverPro,
      hasDateBasedPartnerListCard: homeDepotOnlyEverPro,
      isIframe: window !== window.parent,
      enrollOnSplashScreen: window !== window.parent && homeDepotOnlyEverPro,
    },
    [UNKNOWN_DOMAIN]: {
      main: null,
    }, // for backward compatibility with web.dev.optimusapp.io and web.staging.optimusapp.io
  };

  const appDomain = getAppDomain();

  return solutionOrgs[appDomain];
}
