import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'libphonenumber-js';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
import {
  Select,
  Typography,
  Alert,
  MenuItem,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import _isEqual from 'lodash/isEqual';
import {
  changeFormIndex,
  selectCurrentFormIndex,
  selectCompanyInformationQuestions,
  selectCompanyRoleQuestions,
  selectPersonalInformationQuestions,
  selectCompanyRoleOptions,
  selectMemberInformation,
  updateFormValidState,
  addMemberInformation,
} from '../../../store/slices/onboardingSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { roleAtCompany } from '../../../common/constants';
import {
  ONBOARDING_FORMS,
  ValidFieldPattern,
} from '../constants';
import InputComponent from '../../../common/QuestionComponent';
import {
  Question,
} from '../../../common/models/Question';
import { Member } from '../../../common/models/Member';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';

export type PatternValidation = {
  value: boolean | number | string | RegExp;
  message: string;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    borderRadius: '13px',
    alignItems: 'center !important',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white !important',
      top: '18px !important',
    },
    '& .MuiOutlinedInput-notchedOutline ': {
      display: 'none',
    },
    '& .MuiStack-root .MuiBox-root:last-of-type .MuiInputBase-root:before': {
      border: 'none !important',
    },
    '& .MuiList-root': {
      width: '100%',
    },
    '& .MuiStack-root:nth-of-type(2)': {
      padding: '0px !important',
    },
  },
}));

export interface OnboardingCompanyInformationProps {
  companyFormId?: string;
  onSubmit?: (data: FieldValues) => void;
  disableEmail?: boolean;
  setIsDirty?: (controllerName: string, value: boolean) => void;
  setIsFormStateValid?: (value: boolean) => void;
  isFetchingAccountInformation?: boolean;
}

export function OnboardingCompanyInformation({
  companyFormId,
  onSubmit,
  disableEmail,
  setIsDirty,
  setIsFormStateValid,
  isFetchingAccountInformation,
}: OnboardingCompanyInformationProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const currentFormIndex = useAppSelector(selectCurrentFormIndex);
  const memberInformation = useAppSelector(selectMemberInformation);
  const companyInformationQuestions = useAppSelector(selectCompanyInformationQuestions);
  const personalInformationQuestions = useAppSelector(selectPersonalInformationQuestions);
  const companyRoleQuestions = useAppSelector(selectCompanyRoleQuestions);
  const companyRoleOptions = useAppSelector(selectCompanyRoleOptions);
  const questionTitleAndIdMap = new Map<string, string>();
  const trackOnboardEvent = useOnboardEventTracking();

  personalInformationQuestions.forEach((question: Question, index: number) => {
    questionTitleAndIdMap.set(question.meta?.memberProperty as string ||
      question.title + index, question.id);
  });

  companyRoleQuestions.forEach((question: Question, index: number) => {
    questionTitleAndIdMap.set(question.meta?.memberProperty as string ||
      question.title + index, question.id);
  });

  companyInformationQuestions.forEach((question: Question, index: number) => {
    questionTitleAndIdMap.set(question.meta?.memberProperty as string ||
      question.title + index, question.id);
  });

  const {
    control,
    handleSubmit,
    formState,
    trigger,
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (setIsFormStateValid) {
      setIsFormStateValid(formState.isValid);
    }
    dispatch(updateFormValidState(formState.isValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingAccountInformation]);

  const getMemberCompanyInformation = (meta?: {
    [k: string]: unknown;
  }) => {
    const prop = meta?.memberProperty;
    if (memberInformation && memberInformation[prop as keyof Member]) {
      return memberInformation[prop as keyof Member];
    }
    return null;
  };

  const phoneNumberValidation = (phnNumber: string) => {
    if (phnNumber && !isValidPhoneNumber(phnNumber, 'US')) {
      return `${t('error.invalidPhoneNumber')}`;
    }
    return null;
  };

  const companyRoleValidation = (title: string) => {
    if (title === roleAtCompany) {
      return `${t('error.required')}`;
    }
    return null;
  };

  const mapCompanyRoleOptions = (options: {
    id: string;
    name: string;
    description: string | null;
  }[]) => options.map((option) => {
    const formattedData = {
      value: option.id,
      display: option.name,
    };
    return formattedData;
  });

  const getValidationRules = (
    rules: {
      [k: string]: unknown;
    } = {},
    meta: {
      [k: string]: unknown;
    } = {},
  ) => {
    if (meta?.behavior === 'phoneInput') {
      const result = {
        validate: (value: string) => phoneNumberValidation(value),
      };
      return result;
    }
    if (meta?.memberProperty === 'companyRole') {
      const result = {
        validate: (value: string) => companyRoleValidation(value),
      };
      return result;
    }
    const result = JSON.parse(JSON.stringify(rules));
    if (rules && 'pattern' in rules) {
      const patternValidation = result.pattern as PatternValidation;
      const prop = patternValidation.value;
      patternValidation.value = new RegExp(
        ValidFieldPattern[prop as keyof typeof ValidFieldPattern],
      );
    }
    return result;
  };

  const defaultOnSubmit = (data: FieldValues) => {
    if (data) {
      const updatedMemberInfo = { ...memberInformation };
      Object.keys(data).forEach((key: string) => {
        const questionId = questionTitleAndIdMap.get(key);
        const question =
          [...companyInformationQuestions, ...personalInformationQuestions, ...companyRoleQuestions]
            .find((item) => item.id === questionId) as Question;
        const prop = question?.meta?.memberProperty;
        if (question?.meta?.memberProperty && data[key]) {
          updatedMemberInfo[prop as keyof Member] = data[key] || '';
        } else {
          delete updatedMemberInfo[prop as keyof Member];
        }
      });

      // remove null properties from member
      Object.keys(updatedMemberInfo).forEach((key: string) => {
        if (updatedMemberInfo[key as keyof Member] === null) {
          delete updatedMemberInfo[key as keyof Member];
        }
      });
      dispatch(addMemberInformation(updatedMemberInfo));
      dispatch(changeFormIndex(currentFormIndex + 1));
      trackOnboardEvent('rewards_everpro_account_details_updated');
    }
  };

  return (
    <Box>
      <form id={companyFormId || ONBOARDING_FORMS.companyInformation.id}
        onSubmit={handleSubmit(onSubmit || defaultOnSubmit)}
        className={classes.root}
      >
        <Typography sx={{ mt: 6, textAlign: 'left', color: theme.palette.color2.main }}>
          {t('onboarding.account.heading')}
        </Typography>
        <Box
          sx={{
            width: '100%',
            mt: '2px',
          }}
        >
          <Stack
            spacing={0}
            sx={() => ({
              borderRadius: theme.spacing(1),
              backgroundColor: theme.palette.common.white,
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              paddingTop: theme.spacing(1),
            })}
          >
            {
              personalInformationQuestions?.map((question: Question, index: number) => {
                const controllerName = question.meta?.memberProperty as string ||
                  question.title + index;
                questionTitleAndIdMap.set(controllerName, question.id);
                return (
                  <Box key={question.id}>
                    <Controller
                      name={controllerName}
                      control={control}
                      rules={getValidationRules(question.meta?.rules as {
                        [k: string]: unknown;
                      }, question.meta)}
                      defaultValue={getMemberCompanyInformation(question.meta)}
                      render={(
                        {
                          field: {
                            onChange, value,
                          },
                        },
                      ) => (
                        <div>
                          <InputComponent
                            type={question.type}
                            meta={question.meta}
                            onChange={(e) => {
                              if (setIsDirty) {
                                setIsDirty(controllerName, !(_isEqual(
                                  (e as { target: { value: string } })?.target?.value || e,
                                  getMemberCompanyInformation(question.meta),
                                )));
                              }
                              onChange(e); // Ensure React Hook Form's internal state is updated
                            }}
                            inputValue={value}
                            placeholderText={question.title ? question.title : ''}
                            textFieldDisabled={question?.meta?.memberProperty === 'email' ? disableEmail : false}
                          />
                        </div>
                      )
                      }
                    />
                    {formState.errors[controllerName]?.message &&
                      <Alert
                        sx={{
                          backgroundColor: theme.palette.common.white,
                          color: theme.palette.error.main,
                          p: 0,
                        }}
                        severity="error">
                        {formState.errors[controllerName]?.message?.toString()}
                      </Alert>}
                  </Box>
                );
              })
            }
          </Stack>
          <Stack
            spacing={0}
            sx={() => ({
              borderRadius: theme.spacing(1),
              backgroundColor: theme.palette.common.white,
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              marginTop: theme.spacing(2),
            })}
          >
            {
              companyRoleQuestions?.map((question: Question, index: number) => {
                const controllerName = question.meta?.memberProperty as string ||
                  question.title + index;
                questionTitleAndIdMap.set(controllerName, question.id);
                return (
                  <Box key={question.id}>
                    <Controller
                      name={controllerName}
                      control={control}
                      rules={getValidationRules(question.meta?.rules as {
                        [k: string]: unknown;
                      }, question.meta)}
                      defaultValue={getMemberCompanyInformation(question.meta)}
                      render={(
                        {
                          field: {
                            onChange, value,
                          },
                        },
                      ) => (
                        <div>
                          <Select
                            displayEmpty
                            label={question.title}
                            value={value || roleAtCompany}
                            className={classes.root}
                            onChange={(e) => {
                              if (setIsDirty) {
                                setIsDirty(controllerName, !(_isEqual(
                                  (e as { target: { value: string } })?.target?.value || e,
                                  getMemberCompanyInformation(question.meta),
                                )));
                              }
                              onChange(e); // Ensure React Hook Form's internal state is updated
                            }}
                            sx={{
                              width: '100%',
                              color: !value ? theme.palette.color2.main : 'rgba(0, 0, 0, 0.87);',
                            }}
                          >
                            <MenuItem value={roleAtCompany} disabled sx={{
                              display: 'none',
                            }}>
                              {roleAtCompany}
                            </MenuItem>
                            {mapCompanyRoleOptions(companyRoleOptions).map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                onChange={onChange}
                                sx={() => ({
                                  padding: theme.spacing(2),
                                })}
                              >
                                {option.display}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      )
                      }
                    />
                    {formState.errors[controllerName]?.message &&
                      <Alert
                        sx={{
                          backgroundColor: theme.palette.common.white,
                          color: theme.palette.error.main,
                          p: 0,
                        }}
                        severity="error">
                        {formState.errors[controllerName]?.message?.toString()}
                      </Alert>}
                  </Box>
                );
              })
            }
          </Stack>
        </Box>
        <Typography sx={{ mt: 6, textAlign: 'left', color: theme.palette.color2.main }}>
          {t('onboarding.company.heading')}
        </Typography>
        <Box
          sx={{
            width: '100%',
            mt: '2px',
          }}
        >
          <Stack
            spacing={0}
            sx={() => ({
              borderRadius: theme.spacing(1),
              backgroundColor: theme.palette.common.white,
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              paddingTop: theme.spacing(1),
            })}
          >
            {companyInformationQuestions.map((question: Question, index: number) => {
              const controllerName = question.meta?.memberProperty as string ||
                question.title + index;
              questionTitleAndIdMap.set(controllerName, question.id);
              return (
                <Box key={question.id}>
                  <Controller
                    name={controllerName}
                    control={control}
                    rules={getValidationRules(question.meta?.rules as {
                      [k: string]: unknown;
                    }, question.meta)}
                    defaultValue={getMemberCompanyInformation(question.meta)}
                    render={(
                      {
                        field: {
                          onChange, value,
                        },
                      },
                    ) => (
                      <div>
                        <InputComponent
                          type={question.type}
                          meta={question.meta}
                          onChange={(e) => {
                            if (setIsDirty) {
                              setIsDirty(controllerName, !(_isEqual(
                                (e as { target: { value: string } })?.target?.value || e,
                                getMemberCompanyInformation(question.meta),
                              )));
                            }
                            onChange(e); // Ensure React Hook Form's internal state is updated
                          }}
                          inputValue={value}
                          placeholderText={question.title ? question.title : ''}
                        />
                      </div>
                    )
                    }
                  />
                  {formState.errors[controllerName]?.message &&
                    <Alert
                      sx={() => ({
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.error.main,
                        p: 0,
                      })}
                      severity="error">
                      {formState.errors[controllerName]?.message?.toString()}
                    </Alert>}
                </Box>
              );
            })}
          </Stack>
        </Box>
      </form>
    </Box>
  );
}
