/* eslint-disable max-len */
import {
  Box, CircularProgress, Container, Theme, Typography, useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { verifyPaypalEmail } from '../api/onboardingAPI';
import { useLogo } from '../config/useLogo';

interface VerificationProps {
  CustomLogo?: (() => JSX.Element) | null
}
export function Verification({ CustomLogo }: VerificationProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const isDesktopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [searchParams] = useSearchParams();
  const { secondaryOne: Logo } = useLogo();

  useEffect(() => {
    async function verifyToken(token: string) {
      try {
        const response = await verifyPaypalEmail(token);
        if (response.status === 200) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }
      } catch (err) {
        console.log(err);
        setIsSuccess(false);
      }
      setIsLoading(false);
    }

    const token = searchParams.get('token');
    if (isLoading) {
      verifyToken(token as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    isLoading ?
      <Container maxWidth={isDesktopScreen ? 'lg' : 'xs'} sx={{
        marginTop: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}>
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress color='inherit' size={50} />
        </Box>
      </Container> :
      <Container maxWidth={isDesktopScreen ? 'lg' : 'xs'} sx={{
        marginTop: '24px',
      }}>
        {CustomLogo ? <CustomLogo /> : (Logo && <Logo />)}
        <Box>
          {isSuccess ?
            <Box sx={{ marginTop: '24px' }}>
              <Typography variant='h2'>{t('success.paypal')}</Typography>
              <Typography sx={{ marginTop: '12px' }}>{t('verification.thankYouMessage')}</Typography>
            </Box> :
            <Box sx={{ marginTop: '24px' }}>
              <Typography variant='h2'>{t('error.paypal')}</Typography>
              <Typography sx={{ marginTop: '12px', marginBottom: '12px' }}>{t('verification.sorryMessage')}</Typography>
              <Typography>{t('verification.troubleShooting.title')}</Typography>
              <ul>
                <li style={{ marginBottom: '16px' }}><span style={{ fontWeight: '700' }}>{t('verification.troubleShooting.point1.description1')}</span> {t('verification.troubleShooting.point1.description2')}</li>
                <li><span style={{ marginBottom: '16px', fontWeight: '700' }}>{t('verification.troubleShooting.point2.description1')}</span>{t('verification.troubleShooting.point2.description2')}</li>
              </ul>
              <Typography>{t('verification.troubleShooting.resubmitMessage')}</Typography>
            </Box>
          }
        </Box>
      </Container>
  );
}
