import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import CallToActionButton from '../common/CallToActionButton';
import { OptimusEvent, updatePartnerOnboardingState } from '../store/slices/onboardingSlice';
import { useAppDispatch } from '../store/hooks';
import { ReactComponent as Step1Icon } from '../assets/images/step1_icon.svg';
import { ReactComponent as Step2Icon } from '../assets/images/step2_icon.svg';
import { ReactComponent as Step3Icon } from '../assets/images/step3_icon.svg';
import { ReactComponent as HomedepotLogo } from '../assets/images/homedepot_logo.svg';
import { TermsOfServiceJoist } from './TermsOfServiceJoist';
import { PrivacyPolicy } from './PrivacyPolicy';
import { JoistSolutionOrgName, OnboardingEventType } from '../common/constants';
import { useTracking } from '../common/useTracking';
import { ReactComponent as JoistRewardsMainLogo }
  from '../assets/images/joist_rewards_main_logo.svg';
import { useCompanyEnrollmentInfo } from '../common/useCompanyEnrollmentInfo';

const containerStyle = (theme: Theme) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2),
} as const);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
})) as typeof Dialog;

export function HomeJoist() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [tosOpen, setTosOpen] = useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const trackEvent = useTracking();

  // check company enrollment status
  useCompanyEnrollmentInfo({
    calledFromOnboardingComponent: true,
    redirectPath: '/in-app/dashboard',
  });

  useEffect(() => {
    // this is an iframe
    if (window !== window.parent) {
      // send a rewards splash screen loaded event to JOIST
      const optimusEvent: OptimusEvent = {
        name: 'rewards_splash_screen_loaded',
      };
      window.parent.postMessage({
        type: 'OPTIMUS_REWARDS_SPLASH_SCREEN_LOADED',
        payload: {
          event: optimusEvent,
        },
      }, '*');
      const memberId = searchParams.get('memberId');
      const rewardProgramId = searchParams.get('rewardProgramId');
      const memberDeviceType = searchParams.get('deviceType');
      const memberDeviceVersion = searchParams.get('deviceVersion');
      if (memberId && rewardProgramId && memberDeviceType && memberDeviceVersion) {
        optimusEvent.detail = {
          memberId, rewardProgramId, memberDeviceType, memberDeviceVersion,
        };
      }
      trackEvent({
        optimusEventType: OnboardingEventType,
        optimusEvents: [optimusEvent],
        solutionOrg: JoistSolutionOrgName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToOnboard = () => {
    // this is an iframe
    if (window !== window.parent) {
      const optimusEvent: OptimusEvent = {
        name: 'rewards_splash_screen_cta_clicked',
      };
      window.parent.postMessage({
        payload: {
          event: optimusEvent,
        },
        type: 'OPTIMUS_REWARDS_SPLASH_SCREEN_CTA_CLICKED',
      }, '*');

      const memberId = searchParams.get('memberId');
      const rewardProgramId = searchParams.get('rewardProgramId');
      const memberDeviceType = searchParams.get('deviceType');
      const memberDeviceVersion = searchParams.get('deviceVersion');
      if (memberId && rewardProgramId && memberDeviceType && memberDeviceVersion) {
        optimusEvent.detail = {
          memberId, rewardProgramId, memberDeviceType, memberDeviceVersion,
        };
      }
      trackEvent({
        optimusEventType: OnboardingEventType,
        optimusEvents: [optimusEvent],
        solutionOrg: JoistSolutionOrgName,
      });

      window.parent.postMessage({
        payload: { hideOptimusApp: true },
        type: 'OPTIMUS_HIDE_REQUEST',
      }, '*');
    }

    // set PartnerOnboardingState to true so that
    // when come back from Home Depot, it will redirect to onboarding form
    dispatch(updatePartnerOnboardingState(true));
    navigate('/in-app/onboarding/form');
  };

  const handleTosClose = () => setTosOpen(false);
  const handleTosOpen = () => setTosOpen(true);
  const handlePrivacyPolicyClose = () => setPrivacyPolicyOpen(false);
  const handlePrivacyPolicyOpen = () => setPrivacyPolicyOpen(true);

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
      </Box>
      <Container maxWidth='xs' sx={containerStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <JoistRewardsMainLogo />
          <Box>
            <Box>
              <Typography sx={{
                mt: '16px', textAlign: 'left', paddingLeft: '10px', paddingRight: '10px',
              }}>{t('home.heading')}</Typography>
              <Box
                sx={{
                  width: '100%',
                  mt: 2,
                }}
              >
                <Stack
                  spacing={0}
                  sx={() => ({
                    borderRadius: theme.spacing(1),
                    backgroundColor: theme.palette.common.white,
                    padding: theme.spacing(2),
                    marginBottom: theme.spacing(5),
                    marginTop: theme.spacing(2),
                  })}
                >
                  <Box sx={{ display: 'flex', paddingLeft: '12px' }}>
                    <Box>
                      <HomedepotLogo />
                    </Box>
                    <Box sx={{ marginLeft: '15px', textAlign: 'left' }}>
                      <Typography variant='body4'>{t('home.description1')}</Typography>
                      <Typography variant='body4'>{t('home.description2')}</Typography>
                      <Typography sx={{ marginTop: '0px' }}>{t('home.description3')}</Typography>
                    </Box>
                  </Box>
                  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem sx={{
                      border: '1px solid #DADADA',
                      borderRadius: '13px',
                    }}>
                      <ListItemAvatar>
                        <Step1Icon />
                      </ListItemAvatar>
                      <ListItemText primary={<div style={{ fontSize: '16px', marginTop: '0px', marginBottom: '0px' }}><div style={{ fontSize: '11px', color: theme.palette.grey56.main, fontWeight: 700 }}>{t('home.step1.heading')}</div><div><span style={{ fontWeight: '600' }}>{t('home.step1.description1')}</span> {t('home.step1.description2')} <span style={{ fontWeight: '600' }}>{t('home.step1.description3')}</span> {t('home.step1.description4')}</div> <div style={{ fontSize: '14px', color: theme.palette.grey56.main, fontWeight: 400 }}>{t('home.step1.description5')}</div></div>} />
                    </ListItem>
                    <ListItem sx={{
                      border: '1px solid #DADADA',
                      borderRadius: '13px',
                      marginTop: '8px',
                    }}>
                      <ListItemAvatar>
                        <Step2Icon />
                      </ListItemAvatar>
                      <ListItemText primary={<div style={{ fontSize: '16px', marginTop: '0px', marginBottom: '0px' }}><div style={{ fontSize: '11px', color: theme.palette.grey56.main, fontWeight: 700 }}>{t('home.step2.heading')}</div><div style={{ fontWeight: '600' }}>{t('home.step2.description1')}</div> <div style={{ fontSize: '14px', color: theme.palette.grey56.main, fontWeight: 400 }}>{t('home.step2.description2')}</div></div>} />
                    </ListItem>
                    <ListItem sx={{
                      border: '1px solid #DADADA',
                      borderRadius: '13px',
                      marginTop: '8px',
                    }}>
                      <ListItemAvatar>
                        <Step3Icon />
                      </ListItemAvatar>
                      <ListItemText primary={<div style={{ fontSize: '16px', marginTop: '0px', marginBottom: '0px' }}><div style={{ fontSize: '11px', color: theme.palette.grey56.main, fontWeight: 700 }}>{t('home.step3.heading')}</div><div><span style={{ fontWeight: '600' }}>{t('home.step3.description1')}</span> {t('home.step3.description2')}</div> <div style={{ fontSize: '14px', color: theme.palette.grey56.main, fontWeight: 400 }}>{t('home.step3.description3')}</div></div>} />
                    </ListItem>
                  </List>
                </Stack>
                {/* </form> */}
              </Box>
            </Box>
            <Box>
              <CallToActionButton
                text={t('button.go') as string}
                onBtnClick={navigateToOnboard}
                sx={() => ({
                  position: 'static',
                  width: '100%',
                  marginTop: theme.spacing(-3),
                  [theme.breakpoints.up('sm')]: {
                    width: '100%',
                    marginLeft: '0px',
                  },
                })}
              />
            </Box>
            <Typography component={'div'} sx={{
              marginTop: '26px', marginBottom: '24px', textAlign: 'center', fontSize: '11px', color: theme.palette.color2.main,
            }}>{t('home.footer')}<Typography component={'div'} sx={{
              textAlign: 'center', fontSize: '11px', color: theme.palette.color2.main,
            }}><span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleTosOpen}>{t('home.tosHeading')}</span> {t('onboarding.common.and')} <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handlePrivacyPolicyOpen}>{t('home.privacyPolicyHeading')}</span>.
              </Typography></Typography>
            <BootstrapDialog
              onClose={handleTosClose}
              aria-labelledby="customized-dialog-title"
              open={tosOpen}
            >
              <DialogTitle sx={{ m: 0, p: 2 }}>
                <IconButton
                  aria-label="close"
                  onClick={handleTosClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: () => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <TermsOfServiceJoist />
              </DialogContent>
            </BootstrapDialog>
            <BootstrapDialog
              onClose={handlePrivacyPolicyClose}
              aria-labelledby="customized-dialog-title"
              open={privacyPolicyOpen}
            >
              <DialogTitle sx={{ m: 0, p: 2 }}>
                <IconButton
                  aria-label="close"
                  onClick={handlePrivacyPolicyClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: () => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <PrivacyPolicy />
              </DialogContent>
            </BootstrapDialog>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
