import {
  Theme, Skeleton, Grid,
} from '@mui/material';

export default function ContentCardHeaderSkeleton() {
  return (
    <Grid container textAlign={'center'} justifyContent={'space-between'}
      margin={0}
      marginTop={2}
      color="text.secondary"
    >
      <Grid item xs={1} sm={2} >
        <Skeleton animation="wave" variant="circular"
          sx={(theme: Theme) => ({
            bottom: 12,
            width: 90,
            height: 90,
            [theme.breakpoints.down('sm')]: {
              width: 60,
              height: 60,
            },
          })}
        />
      </Grid>
      <Grid item xs={4} sm={5} >
        <Skeleton
          animation="wave"
          height={15}
          width="100%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={15}
          width="50%"
        />
      </Grid>
      <Grid item xs={3} sm={3}>
        <Skeleton
          animation="wave"
          height={24}
          variant="rectangular"
          sx={{
            borderRadius: '16px',
          }}
        />
      </Grid>
    </Grid>
  );
}
