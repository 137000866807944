import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useTheme } from '@mui/material';

interface SideMenuProps {
  menuItems: {
    title: string;
  }[],
  setRenderIndx: (indx: number) => void,
  renderIndx: number
}
export default function SideMenu({ menuItems, setRenderIndx, renderIndx }:
  SideMenuProps) {
  const theme = useTheme();
  return (
    <Paper elevation={0} sx={{
      width: '100%',
      maxWidth: '100%',
      backgroundColor: theme.palette.grey[100],
    }}>
      <MenuList sx={{
        paddingTop: '0px',
        paddingBottom: '0px',
        '& .MuiButtonBase-root.MuiMenuItem-root + .MuiDivider-root': {
          marginTop: '0px',
          marginBottom: '0px',
        },
      }}>
        {menuItems.map((item, indx) => ([
          <MenuItem key={indx} onClick={() => setRenderIndx(indx)}
            sx={{
              backgroundColor: theme.palette.common.white,
              color: renderIndx === indx ? theme.palette.secondary.main : theme.palette.grey[600],
              height: theme.spacing(7),
              '&:hover': {
                backgroundColor: theme.palette.grey[200],
              },
              ...(indx === 0 && {
                borderTopLeftRadius: theme.spacing(2.25),
                borderTopRightRadius: theme.spacing(2.25),
              }),
              ...(indx === menuItems.length - 1 && {
                borderBottomLeftRadius: theme.spacing(2.25),
                borderBottomRightRadius: theme.spacing(2.25),
              }),
            }}
          >
            <ListItemText
              primary={
                <Typography
                  sx={{
                    fontSize: theme.spacing(2.5),
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>{item.title}
                  <ArrowForwardIosOutlinedIcon style={{
                    color: renderIndx === indx ? theme.palette.secondary.main :
                      theme.palette.grey[600],
                    fontSize: theme.spacing(2.5),
                  }} />
                </Typography>
              }
            />
          </MenuItem>,
          null,
        ]
        ))}
      </MenuList>
    </Paper>
  );
}
