/* eslint-disable no-param-reassign */
import { CreateAnswersInput, CreateOrUpdateMemberInput } from '@optimus/models';
import { axiosInstance, uninterceptedAxiosInstance } from './apiConfig';
import { EnrollmentRequest } from '../common/models/EnrollmentRequest';
import { OptimusEvent } from '../store/slices/onboardingSlice';
import { UpdatePartnersOfMemberRequest } from '../common/models/UpdatePartnersOfMemberRequest';
import { BusinessTrade } from '../common/models/Member';

export const getOnboardingForm = async (formId: string | undefined) => {
  const { data } = await axiosInstance.get(`/forms/${formId}`);
  return data;
};

export const getMemberInformation =
  async (memberId: string | undefined) => {
    const { data } = await axiosInstance.get(`/members/${memberId}`);
    return data;
  };

export const getCompanyEnrollmentInformation =
  async (companyId: string, rewardProgramId: string) => {
    const { data } = await axiosInstance.get(
      `/reward-programs/${rewardProgramId}/companies/${companyId}`,
    );
    return data;
  };

export const enrollMemberToReward =
  async (
    solutionOrgId: string | undefined,
    request: EnrollmentRequest,
  ) => {
    const { data } = await axiosInstance.post(`/v2/onboarding/${solutionOrgId}/enrollment`, request);
    return data;
  };

export const verifyPaypalEmail =
  async (
    token: string,
  ) => {
    const response = await uninterceptedAxiosInstance.post(`/members/payment-method-verification?token=${token}`);
    return response;
  };

export const sendEventToOptimusBackend = async (
  optimusEvents: OptimusEvent[],
  eventPath: string,
) => {
  try {
    await axiosInstance.post(`/events/${eventPath}`, optimusEvents);
  } catch (err) {
    // TODO: implement a retry policy mechanism
    console.log(err);
  }
};

export const updatePartnersOfMember =
  async (
    memberId: string | undefined,
    request: UpdatePartnersOfMemberRequest,
  ) => {
    const { data } = await axiosInstance.post(`/members/${memberId}/partners`, request);
    return data;
  };

export const getCompanyRoles =
  async () => {
    const { data } = await axiosInstance.get('/members/company-roles');
    return data;
  };

export const updateMemberInformation = async (
  request: CreateOrUpdateMemberInput,
  memberId: string,
) => {
  const { data } = await axiosInstance.put(`/members/${memberId}`, request);
  return data;
};

export const updateBusinessTrade = async (
  businessTrades: BusinessTrade[] | undefined,
  companyId: string,
) => {
  const { data } = await axiosInstance.post(`/companies/${companyId}/business-trades`, businessTrades);
  return data;
};

export const updateBusinessAnswer = async (value: string, answerId: string) => {
  const { data } = await axiosInstance.put(`/answers/${answerId}`, {
    value,
  });
  return data;
};

export const createBusinessAnswer = async (value: CreateAnswersInput) => {
  const { data } = await axiosInstance.post('/answers', {
    ...value,
  });
  return data;
};
