export const updateIsFormDirtyState = (
  isDirtyMap: Map<string, boolean>,
  key: string,
  value: boolean,
  setIsDirtyMap: (map: Map<string, boolean>) => void,
  setIsFormDirty: (isFormDirty: boolean) => void,
) => {
  // Create a shallow copy of the original map
  const newisDirtyMap = new Map(isDirtyMap);
  // Add or update the key-value pair in the copy
  newisDirtyMap.set(key, value);
  // Update the state with the new map
  setIsDirtyMap(newisDirtyMap);

  // Initialize the result to false
  let result = false;
  // Iterate over all values in the map and perform OR operation
  for (const val of newisDirtyMap.values()) {
    result = result || val;
  }

  setIsFormDirty(result);
};
