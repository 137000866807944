/* eslint-disable indent */
/* eslint-disable no-tabs */
import {
	Typography, Box, Theme, useMediaQuery,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { UpdateIsBackNavigation, selectContentList } from '../../store/slices/contentSlice';
import PodcastListenSubscribeSheet from './PodcastListenSubscribeSheet';
import {
	ContentPodcast, Content,
} from '../../common/models/content/Content';

interface ContentCardPodcastProps {
	banner?: {
		url: string | undefined;
		width: number | undefined;
		height: number | undefined;
	} | null,
	title?: string | null,
	heading?: string,
	shortDescription?: string,
	OnclickListener?: () => void,
	embedVideoUrl?: string | null,
	contentId: number,
	isContentDetailScreen?: boolean,
	backUrl?: string;
	contentCreatorName?: string | null
	logo?: string | null,
	contentTitle?: string | undefined,
	content?: Content,
	podcast?: ContentPodcast,
}

const expandedShortDescriptionStyle = (theme: Theme) => ({
	marginTop: theme.spacing(1.5),
	[theme.breakpoints.down('sm')]: {
		marginTop: theme.spacing(0.75),
	},
	maxHeight: 'none',
	position: 'relative',
	color: theme.palette.color6.main,
} as const);

const podCastWrapperStyle = (theme: Theme) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: theme.spacing(2.5),
	[theme.breakpoints.down('sm')]: {
		flexWrap: 'wrap',
		gap: theme.spacing(1.25),
		width: '84vw',
	},
} as const);

export default function ContentCardPodcast(props: ContentCardPodcastProps) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const isDesktopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
	const contentList = useAppSelector(selectContentList);
	const content = contentList.find((item) => item.id === props.contentId);

	const addShortDescription = (description: string) => {
		const count = isDesktopScreen ? 120 : 80;
		return description?.length > count ? `${description?.substring(0, count)}...` : description;
	};

	const goBack = () => {
		dispatch(UpdateIsBackNavigation(true));
		navigate(props.backUrl || '/notfound');
	};

	return (
		<div>
			<Box sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}>
				<Box sx={(theme: Theme) => ({
					position: 'relative',
					margin: props.isContentDetailScreen ? theme.spacing(-2) : 0,
					marginBottom: '0px',
					flex: 1,
				})}>
					<Box sx={podCastWrapperStyle}
					>
						<Box
							sx={(theme: Theme) => ({
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
								cursor: 'pointer',
								width: theme.spacing(66),
								flexWrap: 'wrap',
								[theme.breakpoints.down('sm')]: {
									width: '100%',
								},
							})}
						>
							<PodcastListenSubscribeSheet
								contentId={props.contentId}
								contentCreatorName={props.contentCreatorName}
								contentTitle={props.contentTitle}
								podcast={content?.attributes?.podcast}
							/>
						</Box>
					</Box>
					{props.isContentDetailScreen ? (<div>
						<Box onClick={goBack} sx={(theme: Theme) => ({
							position: 'absolute',
							display: 'flex',
							top: '0px',
							margin: theme.spacing(2),
							alignItems: 'center',
							cursor: 'pointer',
						})} >
							<ArrowBackIosIcon fontSize="small" titleAccess='Back' htmlColor='white' />
							<Typography component="div" color="white">
								{t('button.back')}
							</Typography>
						</Box>
						<Typography variant="h6" component="div" color="white"
							sx={(theme: Theme) => ({
								position: 'absolute',
								bottom: theme.spacing(2),
								left: theme.spacing(2),
								borderRadius: theme.spacing(0.625),
							})}>
							{props.heading}
						</Typography>
					</div>) : null}
				</Box>
				<Box
					sx={(theme: Theme) => ({
						flex: 1,
						marginLeft: theme.spacing(2.5),
						marginTop: theme.spacing(-1.5),
						width: theme.spacing(37.5),
					})}
				>
				</Box>
			</Box>
			<div>
				<Box>
					<Typography
						sx={expandedShortDescriptionStyle}
						component='div'
					>
						<Typography
							sx={(theme: Theme) => ({
								width: '100%',
								margin: `${theme.spacing(2.5)} 0px`,
							})}
							component='div'
						>
							{props.shortDescription && addShortDescription(props.shortDescription as string)}
						</Typography>
					</Typography>
				</Box>
			</div>
		</div>
	);
}
