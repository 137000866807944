import React from 'react';
import {
  Box, List, ListItem, ToggleButton, useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { QuestionOption } from './models/Question';

type PollQuestionComponentProps = {
  options?: QuestionOption[];
  onClick?: (...event: unknown[]) => void;
};

const MCQuestionTextBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  fontSize: theme.spacing(2),
  fontWeight: 400,
  lineHeight: 1,
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px', // Define a different font size for smaller screens
  },
}));

export default function PollQuestionComponent({
  options,
  onClick,
}: PollQuestionComponentProps) {
  const theme = useTheme();
  const handleClick = (value: string) => {
    if (onClick) {
      // pass data to the parent component
      onClick(value);
    }
  };

  return (
    <Box
      sx={{
        minWidth: 275,
        width: '100%',
        padding: `0px ${theme.spacing(2)}`,
      }}>
      <List sx={{ marginTop: '16px' }}>
        {options &&
          options.map((item, index) => (
            <ListItem disablePadding key={index}
              sx={{
                mb: theme.spacing(2.5),
                padding: `0px ${theme.spacing(2)}`,
              }}>
              <ToggleButton
                value={item.value}
                selected={item.isSelected}
                onChange={() => handleClick(item.value)}
                sx={{
                  height: '56px',
                  width: '100%',
                  backgroundColor: `${theme.palette.common.white} !important`,
                  border: '2.5px solid #65A7CF',
                  justifyContent: 'center',
                  padding: '5px 8px',
                  borderRadius: '28px',
                  color: theme.palette.common.black,
                  [theme.breakpoints.down('sm')]: {
                    height: '50px',
                  },
                }}
              >
                <MCQuestionTextBox >
                  {item.display}
                </MCQuestionTextBox>
              </ToggleButton>
            </ListItem>
          ))
        }
      </List>
    </Box>
  );
}
