import { selectMemberInformation } from '../store/slices/onboardingSlice';
import { useSolutionOrg } from '../config/useSolutionOrg';
import { OnboardingEventType, SolutionOrgName } from './constants';
import { useTracking } from './useTracking';
import { useAppSelector } from '../store/hooks';

export const useOnboardEventTracking = () => {
  const trackEvent = useTracking();
  const { solutionOrgName } = useSolutionOrg();
  const memberInformation = useAppSelector(selectMemberInformation);

  return (
    name: string,
    detail?: {
      [k: string]: unknown;
    },
    apiResponseErrorStatus?: number,
  ) => {
    // Populate user event
    trackEvent({
      optimusEventType: OnboardingEventType,
      optimusEvents: [{
        name,
        detail: {
          ...detail,
          email: memberInformation.email,
          firstName: memberInformation.firstName,
          lastName: memberInformation.lastName,
          companyRoleId: memberInformation?.companyRoleId,
        },
      }],
      solutionOrg: solutionOrgName as SolutionOrgName,
      apiResponseErrorStatus,
    });
  };
};
