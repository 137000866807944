/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosResponse } from 'axios';
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import * as Sentry from '@sentry/react';
import { TokenRefreshResponse } from '../common/models/AuthenticationRequest';
import { ExponentialBackoff, extractTokenExpiry } from '../common/utils/loginUtils';
import { addAccessToken, addRefreshToken, addTokenExpiry } from '../store/slices/onboardingSlice';
import { AppStore } from '../store/storeSetup';
import { logout } from '../store/thunks/authenticationThunk';
import { isOnboarding } from '../routes/namedRoutes';

const MAX_RETRIES = 3;
const backoff = new ExponentialBackoff(1000, 30000); // 1 sec for base delay, 30 sec for max delay

let store: AppStore;

export const injectStore = (_store: AppStore) => {
  store = _store;
};

// Create axios instance that will not have any request or response interceptors.
// This will be used by login/logout requests
export const uninterceptedAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

// Create axios instance that will be used by all other requests in the app
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Determine if request's 401/403 response should be skipped by auth refresh interceptor
const shouldSkipAuthRefresh = (url: string | undefined) => {
  // urls to be skipped by auth refresh interceptor
  const skippedUrls: string[] = [];
  // Skipping auth refresh for Joist requests as well for now. Might want to revisit this
  return !url || skippedUrls.includes(url);
};

// Add generic request interceptor for configuring request headers
axiosInstance.interceptors.request.use((config) => {
  const accessToken = store?.getState?.()?.onboarding?.memberSession?.accessToken;

  if (!accessToken) {
    // abort request if there's no access token, and log out user
    store.dispatch(logout({ isOnboarding: isOnboarding() }));
    throw new axios.Cancel(`${config.method} ${config.url} canceled`);
  }

  // Add access token to header
  config.headers['X-Optimus-Authorization'] = `Bearer ${accessToken}`;

  // check if 401/403 response of this request should be skipped by Auth Refresh Interceptor
  if (shouldSkipAuthRefresh(config.url)) {
    (config as AxiosAuthRefreshRequestConfig).skipAuthRefresh = true;
  }
  return config;
}, (error) => Promise.reject(error));

// refresh call
const refreshCall = async (retryCount: number): Promise<unknown> => {
  try {
    const requestBody = {
      refreshToken: store?.getState?.()?.onboarding?.memberSession?.refreshToken,
    };
    const { data } = await uninterceptedAxiosInstance.post('/auth/refresh', requestBody);

    // persist access token and refresh token to store
    const newAccessToken = (data as TokenRefreshResponse)?.accessToken;
    const newRefreshToken = (data as TokenRefreshResponse)?.refreshToken;
    store.dispatch(addAccessToken(newAccessToken));
    const tokenExpiry = extractTokenExpiry(newAccessToken);
    store.dispatch(addTokenExpiry(tokenExpiry as string));
    store.dispatch(addRefreshToken(newRefreshToken as string));

    return Promise.resolve();
  } catch (error: unknown) {
    // Retry on network error only
    // Note: we do not retry for 500 and 429 errors because of the following reasons:
    // 1. 500 is probably db error, or we deploy some problematic code.
    //     - If it is a db timeout, we don't want more retry since it will add more load to the db.
    //     - If there is a problem in the code, retry won't help,
    // 2. Normal user would most likely not reach the rate limit of refresh endpoint to trigger 429.
    //    - With the axios-auth-refresh library, multiple requests cannot trigger
    //      duplicate refresh calls. They will all wait for the pending refresh call
    if (retryCount < MAX_RETRIES && !((error as AxiosError)?.response)) {
      const waitTime = backoff.getWaitTime(retryCount);
      console.log(`Network error refreshing token. Retry count: ${retryCount}. wait time: ${waitTime}`);
      // Retry the refresh api call with exponential backoff
      await backoff.wait(waitTime);
      await refreshCall(retryCount + 1);
      return Promise.resolve();
    }

    // reject error
    return Promise.reject(error);
  }
};

// Logic executed by Auth refresh interceptor to refresh the access token
const refreshAuthLogic = async (failedRequest: AxiosError): Promise<unknown> => {
  try {
    await refreshCall(0);

    return Promise.resolve();
  } catch (error: unknown) {
    console.log(error);

    // logout user
    store.dispatch(logout({ isOnboarding: isOnboarding() }));

    // reject error
    return Promise.reject(error);
  }
};

// Add Auth refresh interceptor
createAuthRefreshInterceptor(
  axiosInstance,
  refreshAuthLogic,
  {
    shouldRefresh: (error) => (error?.response?.status === 401 ||
      error?.response?.status === 403
    ),
  },
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response && ![401, 403].includes(error.response.status)) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      Sentry.captureException((error.response.data as {message: string })?.message);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      Sentry.captureException(error.message);
    }
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);
