import LoginLayout from './LoginLayout';

interface OnboardingLoginProps{
  afterOnboardingLoginPath?: string

}
export function OnboardingLogin({ afterOnboardingLoginPath }: OnboardingLoginProps) {
  return (
    <LoginLayout afterLoginPath={afterOnboardingLoginPath || '/onboarding'} />
  );
}
