export const ONBOARDING_FORMS = {
  // Note: for every new form added, increment index
  companyInformation: {
    index: 1,
    id: 'company-information-form',
  },
  businessInformation: {
    index: 2,
    id: 'business-information-form',
  },
  partnersInformation: {
    index: 3,
    id: 'partners-information-form',
  },
};

// TODO: These regex pateern will go to meta of each each question
export const ValidFieldPattern = {
  // eslint-disable-next-line no-useless-escape, no-control-regex
  validEmailPattern: /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/gm,
};
