export const JOIST_DOMAIN = 'joist';
export const SERVICE_FUSION_DOMAIN = 'service fusion';
export const EVERPRO_DOMAIN = 'Everpro';
export const UNKNOWN_DOMAIN = 'unknown domain';

export const getAppDomain = () => {
  // can configure what domain to focus on during local development through env file
  const appDomain = (
    process.env.REACT_APP_ENVIRONMENT === 'local' &&
    process.env.REACT_APP_LOCAL_DOMAIN) || window.location.hostname;

  switch (appDomain) {
    case 'joist.dev.everproedge.com':
    case 'joist.staging.everproedge.com':
    case 'joist.uat.everproedge.com': // UAT
    case 'joist.local.everproedge.com': // for local development only (localhost)
    case 'joist.everproedge.com':
    case 'web.optimusapp.io': // TODO: remove once web.optimusapp.io is decomissioned
      return JOIST_DOMAIN;
    case 'servicenation.dev.everproedge.com': // TODO: remove servicenation domain cases
    case 'servicenation.staging.everproedge.com':
    case 'servicenation.uat.everproedge.com': // UAT
    case 'servicenation.local.everproedge.com': // for local development only (localhost)
    case 'edge.servicenation.com':
    case 'servicefusion.dev.everproedge.com':
    case 'servicefusion.staging.everproedge.com':
    case 'servicefusion.uat.everproedge.com': // UAT
    case 'servicefusion.local.everproedge.com': // for local development only (localhost)
    case 'servicefusion.everproedge.com':
      return SERVICE_FUSION_DOMAIN;
    case 'dev.everproedge.com':
    case 'staging.everproedge.com':
    case 'uat.everproedge.com':
    case 'local.everproedge.com':
    case 'everproedge.com':
      return EVERPRO_DOMAIN;
    case 'localhost':
    case 'web.dev.optimusapp.io':
    case 'web.staging.optimusapp.io':
      return JOIST_DOMAIN;
    default:
      return UNKNOWN_DOMAIN;
  }
};

export const getJoistVersion = () => {
  const appDomain = getAppDomain();
  if (appDomain !== JOIST_DOMAIN) {
    return undefined;
  }
  return window.location.pathname?.toLowerCase()?.includes('/v2/') ? 'v2' : 'v1';
};
