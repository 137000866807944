/* eslint-disable camelcase */
import { faker } from '@faker-js/faker';
import axios from 'axios';
import { CustomerSupportFormInput } from '@optimus/models';
import {
  LoginMemberRequest, LoginMemberResponse, LoginSessionResponse, LogoutResponse,
} from '../common/models/AuthenticationRequest';
import { uninterceptedAxiosInstance } from './apiConfig';
import { SubmitSupportFormResponse } from '../common/models/SubmitSupportFormResponse';

export const loginUser =
  async (clientId: string, clientSecret: string) => {
    const solutionId = process.env.REACT_APP_SOLUTION_ID;
    const rewardProgramId = process.env.REACT_APP_REWARD_PROGRAM_ID;
    const partnerId = process.env.REACT_APP_PARTNER_ID;
    const authUrl = process.env.REACT_APP_AUTH_URL as string;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    // Get access token
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append('scope', 'optimus/solutionOrgAdmin');
    params.append('client_id', clientId);
    params.append('client_secret', clientSecret);

    const { data: { access_token } } = await axios.post(
      authUrl,
      params,

      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    if (!access_token) throw new Error('missing access token');

    // create dummy member
    const firstName = faker.name.firstName();
    const lastName = faker.name.lastName();
    const { data: { id } } = await axios.post(
      `${baseUrl}/onboarding/${solutionId}/members`,
      {
        firstName,
        lastName,
        email: faker.internet.email(firstName, lastName, 'ever.com'),
        solutionOrgMemberKey: faker.datatype.uuid(),
        companyName: faker.company.name(),
        companyAddressStreet: faker.address.streetAddress(),
        companyAddressCity: faker.address.cityName(),
        companyAddressState: faker.address.stateAbbr(),
        companyAddressZipCode: faker.address.zipCode(),
        companyEmail: faker.internet.email(),
        companyWebsiteUrl: faker.internet.url(),
      },
      {
        headers: {
          'X-Optimus-Authorization': `Bearer ${access_token}`,
        },
      },
    );

    if (!id) throw new Error('member not created');

    // Get app url
    const { data: { iframeUrl } } = await axios.get(`${baseUrl}/onboarding/${solutionId}/app-url?memberId=${id}&rewardProgramId=${rewardProgramId}&partnerId=${partnerId}`, {
      headers: {
        'X-Optimus-Authorization': `Bearer ${access_token}`,
      },
    });

    if (!iframeUrl) throw new Error('app url not retrieved');

    const homeUrl = iframeUrl.replace('?accessToken', 'homeWeb/?accessToken');

    // const homeUrl = iframeUrl.replace('https://web.dev.optimusapp.io', 'http://localhost:3000/homeWeb');

    return homeUrl;
  };

export const generateTemporaryLoginCode = async (email: string) => {
  const { data } = await uninterceptedAxiosInstance.post('/auth/login-session', { email });
  return data as LoginSessionResponse;
};

// uses access token
export const generateTemporaryLoginCodeSso = async (accessToken: string) => {
  const { data } = await uninterceptedAxiosInstance.post(
    '/auth/login-session/sso',
    undefined,
    {
      headers: {
        'X-Optimus-Authorization': `Bearer ${accessToken}`,
      },
    },
  );
  return data as LoginSessionResponse;
};

export const loginMember = async (request: LoginMemberRequest) => {
  const { data } = await uninterceptedAxiosInstance.post('/auth/login', { ...request });
  return data as LoginMemberResponse;
};

export const logoutMember = async (refreshToken: string | null) => {
  const { data } = await uninterceptedAxiosInstance.post(
    '/auth/logout',
    {
      refreshToken,
    },
  );
  return data as LogoutResponse;
};

export const submitCustomerSupportForm = async (request: CustomerSupportFormInput) => {
  const { data } = await uninterceptedAxiosInstance.post('/customer-support', { ...request });
  return data as SubmitSupportFormResponse;
};
