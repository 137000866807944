import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React from 'react';

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText('#FE2C55'),
  borderRadius: '10px',
  height: 60,
  top: '45vh',
  width: '90%',
  position: 'absolute',
  textTransform: 'none',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  '&.MuiButton-contained': {
    fontWeight: 600,
    fontSize: '18px',
  },
  '&:disabled': {
    backgroundColor: theme.palette.primary.main,
    opacity: '0.4',
    color: theme.palette.common.white,
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: '5vw',
    width: '40%',
  },
}));

type CallToActionButtonProps = {
  text: string | JSX.Element;
  disable?: boolean;
  onBtnClick?: () => void;
  optionalStackStyles? : {
    [key:string]: unknown
  }
} & ButtonProps;

export default function CallToActionButton({
  text,
  disable = false,
  onBtnClick,
  optionalStackStyles,
  ...props
}: CallToActionButtonProps) {
  const handleOnClick = () => {
    if (onBtnClick) {
      onBtnClick();
    }
  };
  return (
    <Stack spacing={2} direction="row" sx={{ ...(optionalStackStyles && optionalStackStyles) }}>
      <ColorButton variant="contained" onClick={handleOnClick} {...props} disabled={disable}>
        {text}
      </ColorButton>
    </Stack>
  );
}
