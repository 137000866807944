import { Grid } from '@mui/material';
import { useEffect } from 'react';
import ContentHome from '../Content/ContentHome';
import { getMemberInformation } from '../../store/thunks/onboardingThunk';
import { useAppDispatch } from '../../store/hooks';
import { useTracking } from '../../common/useTracking';

export function SavedContent() {
  const dispatch = useAppDispatch();
  const trackEvent = useTracking();

  useEffect(() => {
    dispatch(getMemberInformation({ trackEvent }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 4, md: 4 }}
      >
        <ContentHome onlySavedContent contentViewBaseUrl={'/saved'} />
      </Grid>
  );
}
