import { Content, ContentHeroFormat } from '../../common/models/content/Content';

export const getImageUrl = (url: string | undefined) => {
  const imageBaseUrl = process.env.REACT_APP_STRAPI_URL;
  if (url && !url.includes('http')) {
    return imageBaseUrl?.concat(url);
  }
  return url;
};

export const getHeaderImageUrl = (content: Content | undefined) => {
  let url;
  // display collection icon in case of podcast
  if (content?.attributes?.content_collection?.data) {
    url = content?.attributes?.content_collection?.data?.attributes?.icon?.data?.attributes?.url;
  } else {
    url = content?.attributes?.content_owner?.data?.attributes?.icon?.data?.attributes?.url;
  }
  return getImageUrl(url);
};

export const getHeading = (content: Content | undefined) => {
  let heading;
  // display collection name in case of podcast
  if (content?.attributes?.content_collection?.data) {
    heading = content?.attributes?.content_collection?.data?.attributes?.title;
  } else {
    heading = content?.attributes?.content_owner?.data?.attributes?.display_name;
  }
  return heading;
};

export const getHeroFormat = (content: Content | undefined) => (
  content?.attributes?.hero?.data?.attributes?.formats?.medium ||
  content?.attributes?.hero?.data?.attributes?.formats?.small ||
  content?.attributes?.hero?.data?.attributes?.formats?.thumbnail
) as ContentHeroFormat;
