/* eslint-disable no-nested-ternary */
import {
  CardMedia, Typography, Box, Theme, useMediaQuery,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectMemberSession } from '../../store/slices/onboardingSlice';
import ContentYoutubePlayer from './ContentYoutubeVideo';
import { UpdateIsBackNavigation } from '../../store/slices/contentSlice';
import ContentLinkedinPlayer from './ContentLinkedinVideo';

interface ContentHeroBannerProps {
  logo?: string | null;
  banner?: {
    url: string | undefined;
    width: number | undefined;
    height: number | undefined;
  } | null,
  title?: string | null,
  heading?: string,
  shortDescription?: string,
  OnclickListener?: () => void,
  embedVideoUrl?: string | null,
  contentId: number,
  isContentDetailScreen?: boolean,
  backUrl?: string;
  contentCreatorName?: string | null
}

export default function ContentHeroBanner(props: ContentHeroBannerProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { memberId } = useAppSelector(selectMemberSession);
  const isDesktopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const extractVideoId = (url: string) => {
    const lowerCaseUrl = url.toLowerCase();
    let regex: RegExp | undefined;
    if (lowerCaseUrl.includes('youtube')) {
      regex = /[?&]v=([a-zA-Z0-9_-]+)|\/shorts\/([a-zA-Z0-9_-]+)/;
    } else if (lowerCaseUrl.includes('tiktok')) {
      regex = /\/video\/(\d+)/;
    }
    if (!regex) return null;
    const match = url.match(regex);
    return match ? match[1] || match[2] : null;
  };

  const addShortDescription = (description: string) => {
    const count = isDesktopScreen ? 120 : 80;
    return description?.length > count ? `${description?.substring(0, count)}...` : description;
  };

  const goBack = () => {
    dispatch(UpdateIsBackNavigation(true));
    navigate(props.backUrl || '/notfound');
  };

  return (
    <div>
      <div style={{
        position: 'relative',
        margin: `${props.isContentDetailScreen ? -16 : 0}px`,
        marginBottom: '0px',
      }}>
        {((props.title || '').toUpperCase() !== 'VIDEO' ||
          props?.embedVideoUrl?.toLowerCase()?.includes('tiktok')) && props.banner ? (<CardMedia
            sx={(theme: Theme) => ({
              paddingLeft: '0px',
              paddingTop: '56.25%', // 16:9 aspect ratio
              borderRadius: theme.spacing(2.25),
              cursor: 'pointer',
            })}
            component='div'
            image={props.banner.url}
            onClick={props.OnclickListener}
          />) : null}
        {props.isContentDetailScreen ? (<div>
          <Box onClick={goBack}
            sx={(theme: Theme) => ({
              position: 'absolute',
              display: 'flex',
              top: '0px',
              margin: theme.spacing(2),
              alignItems: 'center',
              cursor: 'pointer',
              borderRadius: theme.spacing(2.25),
            })} >
            <ArrowBackIosIcon fontSize="small" titleAccess='Back' htmlColor='white' />
            <Typography component="div" color="white">
              {t('button.back')}
            </Typography>
          </Box>
          <Typography variant="h6" component="div" color="white"
            sx={(theme: Theme) => ({
              position: 'absolute',
              bottom: theme.spacing(2),
              left: theme.spacing(2),
              borderRadius: theme.spacing(0.625),
            })}>
            {props.heading}
          </Typography>
        </div>) : null}
      </div>
      {
        (props.title || '').toUpperCase() === 'VIDEO' ?
          (props?.embedVideoUrl?.toLowerCase()?.includes('youtube') ?
            <Box>
              <ContentYoutubePlayer
                videoId={extractVideoId(props.embedVideoUrl) || ''}
                contentId={props.contentId}
                memberId={memberId}
                contentTitle={props.heading}
                contentCreatorName={props.contentCreatorName}
              />
            </Box> : (
              (
                props?.embedVideoUrl?.toLowerCase()?.includes('linkedin') ?
                  <ContentLinkedinPlayer
                    videoUrl={props.embedVideoUrl || ''}
                    contentId={props.contentId}
                    memberId={memberId}
                    contentTitle={props.heading}
                    contentCreatorName={props.contentCreatorName}
                  /> :
                  null)
            )
          ) :
          null
      }
      <Box>
        <Typography
          sx={(theme: Theme) => ({
            width: '100%',
            margin: `${theme.spacing(2.5)} 0px`,
          })}
          component='div'
        >
          {props.shortDescription && addShortDescription(props.shortDescription as string)}
        </Typography>
      </Box>
    </div>
  );
}
