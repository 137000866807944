import {
  Backdrop, Box, CircularProgress, Container, Grid, useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Content } from '../../common/models/content/Content';
import { useAppDispatch } from '../../store/hooks';
import { ResetContentList, UpdateIsContentPreview, UpdatePreviewContentId } from '../../store/slices/contentSlice';
import { getContentById } from '../../store/thunks/contentThunk';
import AuthenticatedRoute from '../AuthenticatedRoute';
import ContentCard from './ContentCard';
import {
  getHeaderImageUrl, getHeading, getHeroFormat, getImageUrl,
} from './ContentCommonFunctions';
import { useCompanyEnrollmentInfo } from '../../common/useCompanyEnrollmentInfo';

export default function ContentPreview() {
  const { contentId } = useParams();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState<Content>();
  const contentViewBaseUrl = '/home';

  // check company enrollment status
  useCompanyEnrollmentInfo({ calledFromOnboardingComponent: false });

  useEffect(() => {
    dispatch(UpdateIsContentPreview(true));
    dispatch(UpdatePreviewContentId(Number(contentId)));
    dispatch(getContentById(Number(contentId))).then((res) => {
      const result = res.payload as Content;
      setContent(result);
      dispatch(ResetContentList([result])); // initial content list state
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayContentView = (
    contentItem: Content,
  ) => {
    if (content?.attributes?.type?.toUpperCase() !== 'PODCAST') {
      if (content?.attributes?.external_link) {
        window.open(content?.attributes?.external_link.target, '_blank');
      } else if (content?.attributes?.video_url) {
        window.open(content?.attributes?.video_url, '_blank');
      } else {
        navigate(`${contentViewBaseUrl}/content/${contentId}`);
      }
    }
  };

  return (
    <AuthenticatedRoute>
      <Container maxWidth={'sm'}>
        <Grid
          sx={{
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
              paddingTop: theme.spacing(2),
              paddingLeft: theme.spacing(2),
            },
          }}
        >
          <Backdrop
            sx={{ color: theme.palette.common.white, zIndex: theme.zIndex.drawer + 1 }}
            open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box>
            {content && <ContentCard
              contentId={Number(contentId)}
              contentHeroListener={() => displayContentView(content)}
              contentText={content?.attributes?.title || ''}
              contentShortDescription={content?.attributes?.short_description || ''}
              categoryText={content?.attributes?.type}
              categoryColor={theme.palette.primary.main}
              banner={
                {
                  url: getImageUrl(
                    getHeroFormat(content)?.url
                    ,
                  ),
                  width: getHeroFormat(content)?.width,
                  height: getHeroFormat(content)?.height,
                }}
              authorTitle={getHeading(content)}
              publishedDate={content?.attributes?.source_published_date ||
                content?.attributes?.publishedAt}
              authorText={content?.attributes?.banner?.short_description}
              logo={getHeaderImageUrl(content)}
              videoUrl={content?.attributes?.video_url}
              contentLikeStatus={content?.likeStatus}
              formId={content?.attributes?.form_id || null}
            />
            }
          </Box>
        </Grid>
      </Container>
    </AuthenticatedRoute >
  );
}
