import { Box, Theme, Typography } from '@mui/material';
import { useLogo } from '../config/useLogo';
import { useSolutionOrg } from '../config/useSolutionOrg';

export default function ContactUs() {
  const { secondaryOne: Logo } = useLogo();
  const { supportEmail } = useSolutionOrg();
  return (
    <Box
      sx={(theme: Theme) => ({
        background: theme.palette.common.white,
        borderRadius: '13px',
        padding: theme.spacing(2.5),
        [theme.breakpoints.down('sm')]: {
          marginTop: '16px',
        },
      })}
    >
      <Typography variant='h3' marginBottom={'16px'}>Contact us</Typography>
      {Logo && <Logo width={150} height={32.6} />}
      <Typography variant='h3' marginTop={'16px'} marginBottom={'48px'}>Email: {supportEmail as string}</Typography>
    </Box>
  );
}
