import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
} from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import React, { CSSProperties, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  DroppableProvided,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { QuestionOption } from '../models/Question';
import { DroppableComponent } from './DroppableComponent';

const grid = 8;

const reorder = (list: QuestionOption[], startIndex: number, endIndex: number):
  QuestionOption[] => {
  const tempResult = Array.from(list);
  const [removed] = tempResult.splice(startIndex, 1);
  tempResult.splice(endIndex, 0, removed);
  const result = tempResult.map((item: QuestionOption, index: number) => (
    {
      display: item.display,
      value: item.value,
      order: index + 1,
    }
  ));
  return result;
};

type DraggableListComponentProps = {
  options?: QuestionOption[];
  onChange: (...event: unknown[]) => void;
}

function DraggableListComponent({
  options = [],
  onChange,
}: DraggableListComponentProps) {
  const [items, setState] = useState(options);
  const theme = useTheme();

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems: QuestionOption[] = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    setState(newItems);

    // dispatch new order to parent component
    if (typeof onChange === 'function') {
      onChange(newItems);
    }
  };

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ): CSSProperties | undefined => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 0 ${grid}px 0`,
    borderRadius: 105,

    // change background colour if dragging
    background: isDragging ? 'lightgrey' : theme.palette.common.white,

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <DroppableComponent droppableId='droppable'>
        {(droppableProvided: DroppableProvided) => (
          <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            {items.map((item, index) => (
              <Draggable key={item.value} draggableId={item.value} index={index}>
                {(
                  draggableProvided: DraggableProvided,
                  draggableSnapshot: DraggableStateSnapshot,
                ) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={getItemStyle(
                      draggableSnapshot.isDragging,
                      draggableProvided.draggableProps.style,
                    )}
                  >
                    <ListItem sx={{ paddingLeft: '8px' }}
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments" sx={{
                          color: '#B2B2B2',
                          m: 0,
                        }}>
                          <MenuRoundedIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Box
                          sx={{
                            width: 40,
                            height: 40,
                            textAlign: 'center',
                            color: theme.palette.common.white,
                            borderRadius: 105,
                            fontWeight: 600,
                            fontSize: 24,
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                          }}
                        >
                          {index + 1}
                        </Box>
                      </ListItemAvatar>
                      <ListItemText primary={item.display} />
                    </ListItem>
                  </div>
                )}
              </Draggable>
            ))
            }
            {droppableProvided.placeholder}
          </div>
        )}
      </DroppableComponent>
    </DragDropContext >
  );
}

export default DraggableListComponent;
