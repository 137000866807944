import {
  Box, SvgIcon, Theme, Typography, useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { SxProps } from '@mui/system';

export interface DashboardNavElementProps {
  sx: SxProps<Theme>;
  name: string;
  path: string;
  outlineIcon: typeof SvgIcon;
  filledIcon: typeof SvgIcon;
  isSelected: boolean;
  onClick?: () => void;
}

const NavElementStyle = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.color2.main,
  '&:hover': {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
} as const);

const NavIconStyle = () => ({
  width: '36px',
  height: '31px',
} as const);

const NavTypographyStyle = (theme: Theme) => ({
  marginLeft: '16px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '23px',
  },
} as const);

export function DashboardNavElement(props: DashboardNavElementProps) {
  const theme = useTheme();
  const { sx } = props;

  const Icon = () => (props.isSelected ?
      <props.filledIcon sx={[NavIconStyle]} /> :
      <props.outlineIcon sx={[NavIconStyle]} />);

  return (<Link to={props.path} style={{ textDecoration: 'none' }}>
    <Box
      sx={[
        NavElementStyle,
        props.isSelected ? { color: theme.palette.secondary.main } : {},
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={props.onClick}
    >
      <Icon />
      <Typography variant='h5' sx={NavTypographyStyle}>{props.name}</Typography>
    </Box>
  </Link>);
}
