import {
  Dialog, Typography, DialogContent, DialogTitle, IconButton,
  useTheme,
} from '@mui/material';
// import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TermsOfService } from './TermsOfService';

/* const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
})) as typeof Dialog; */

export function TermsOfServiceWrapper() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [tosOpen, setTosOpen] = useState(false);
  const handleTosOpen = () => setTosOpen(true);
  const handleTosClose = () => setTosOpen(false);

  return (
    <>
    <Typography component={'div'} sx={() => ({
      marginBottom: '24px',
      marginTop: '24px',
      fontSize: '11px',
      color: theme.palette.color2.main,
      [theme.breakpoints.up('lg')]: {
        marginTop: '0px',
        display: 'flex',
        justifyContent: 'center',
      },
    })}>{t('home.tosAgreement')}&nbsp;<span style={{
      fontSize: '11px',
      color: theme.palette.color2.main,
      textDecoration: 'underline',
      cursor: 'pointer',
    }} onClick={handleTosOpen}>{t('home.tosHeading')}</span>.
      </Typography>
    <Dialog
    onClose={handleTosClose}
    aria-labelledby="customized-dialog-title"
    open={tosOpen}
    PaperProps={{
      sx: {
        maxWidth: '800px',
        height: '100%',
      },
    }}
    maxWidth="md"
    fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={handleTosClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: () => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: 0, height: '100%' }}>
        <TermsOfService />
      </DialogContent>
    </Dialog>
    </>
  );
}
