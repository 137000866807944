import {
  Box, List, ListItem, ListItemAvatar,
  ListItemText, Stack, Typography, useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Step1Icon } from '../../../assets/images/step1_icon.svg';
import { ReactComponent as Step2Icon } from '../../../assets/images/step2_icon.svg';
import { ReactComponent as Step3Icon } from '../../../assets/images/step3_icon.svg';
import CallToActionButton from '../../../common/CallToActionButton';
import { useAppDispatch } from '../../../store/hooks';
import { HDSetupFlowSteps } from '../../../common/enums';
import { UpdateHDSetupFlowStepsCompletionState } from '../../../store/slices/dashboardSlice';
import { useSolutionOrg } from '../../../config/useSolutionOrg';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  alignSelf: 'flex-start',
  paddingTop: theme.spacing(1),
})) as typeof ListItemAvatar;

const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey2.main}`,
  borderRadius: theme.spacing(1.625),
}));

export interface HDSetupInstructionsCardProps {
  dashboardLayout: boolean;
}

export const HDOnboardingActions = {
  step1: {
    name: HDSetupFlowSteps.Step1,
    url: 'https://www.homedepot.com/auth/view/signin',
    eventName: 'clicked-hd-card-sign-in',
  },
  step2: {
    name: HDSetupFlowSteps.Step2,
    url: 'https://www.homedepot.com/myaccount/payments',
    eventName: 'clicked-hd-card-add-credit-card',
  },
  step3: {
    name: HDSetupFlowSteps.Step3,
    url: 'https://www.homedepot.com/myaccount/proXtraPricing',
    eventName: 'clicked-hd-card-enter-code',
  },
};

export function HDSetupInstructionsCard({ dashboardLayout }: HDSetupInstructionsCardProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { programCode } = useSolutionOrg();
  const trackOnboardEvent = useOnboardEventTracking();

  const goToStep = (step: string) => {
    const { name, url, eventName } = HDOnboardingActions[step as keyof typeof HDOnboardingActions];
    window.open(url, '_blank');
    dispatch(UpdateHDSetupFlowStepsCompletionState(name));
    // Populate user event
    trackOnboardEvent(eventName);
  };

  const GoToStepButtonComponent = (step: string) => (<CallToActionButton
    text={t('button.goToStep') as string}
    onBtnClick={() => goToStep(step)}
    sx={{
      fontSize: '12px !important',
      fontWeight: '700 !important',
      background: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      position: 'static',
      borderRadius: theme.spacing(3.75),
      boxShadow: 'none',
      height: 'auto',
      width: dashboardLayout ? '55% !important' : '55%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginLeft: '0px',
      },
      [theme.breakpoints.up('lg')]: {
        position: 'static',
        marginRight: 'auto',
        maxWidth: '500px',
      },
    }}
  />);

  const goToDetail = () => {
    navigate('/home/hdInstruction');
    // Populate user event
    trackOnboardEvent('clicked-hd-card-learn-more');
  };

  return (
    <>
      <Box
        sx={{
          marginBottom: theme.spacing(1.5),
          marginTop: theme.spacing(1.5),
          color: theme.palette.grey56.main,
        }}
      >
        <Typography variant='body1'>{t('homeDepot.instructions.subHeading')}</Typography>
      </Box>
      <Stack
        spacing={0}
      >
        <List sx={{
          width: '100%',
          bgcolor: 'background.paper',
        }}>
          <StyledListItem>
            <StyledListItemAvatar >
              <Step1Icon />
            </StyledListItemAvatar>
            <Box
              sx={{ minWidth: '75%' }}
            >
              <ListItemText primary={<div style={{ fontSize: dashboardLayout ? theme.spacing(2) : theme.spacing(2.25), marginTop: '0px', marginBottom: '0px' }}><div style={{ fontSize: theme.spacing(1.3), color: theme.palette.grey56.main, fontWeight: 700 }}>{t('home.step1.heading')}</div><div><span style={{ fontWeight: '600' }}>{t('home.step1.description1')}</span> {t('home.step1.description2')} <span style={{ fontWeight: '600' }}>{t('home.step1.description3')}</span> {t('home.step1.description4')}</div> <div style={{ fontSize: theme.spacing(1.75), color: theme.palette.grey56.main, fontWeight: 400 }}>{t('home.step1.description5')}</div></div>} />
              {GoToStepButtonComponent(HDSetupFlowSteps.Step1)}
            </Box>
          </StyledListItem>
          <StyledListItem sx={{
            marginTop: theme.spacing(1),
          }}>
            <StyledListItemAvatar >
              <Step2Icon />
            </StyledListItemAvatar>
            <Box
              sx={{ minWidth: '75%' }}
            >
              <ListItemText primary={<div style={{ fontSize: dashboardLayout ? theme.spacing(2) : theme.spacing(2.25), marginTop: '0px', marginBottom: '0px' }}><div style={{ fontSize: theme.spacing(1.3), color: theme.palette.grey56.main, fontWeight: 700 }}>{t('home.step2.heading')}</div><div style={{ fontWeight: '600' }}>{t('home.step2.description1')}</div> <div style={{ fontSize: theme.spacing(1.75), color: theme.palette.grey56.main, fontWeight: 400 }}>{t('home.step2.description2')}</div></div>} />
              {GoToStepButtonComponent(HDSetupFlowSteps.Step2)}
            </Box>
          </StyledListItem>
          <StyledListItem sx={{
            marginTop: theme.spacing(1),
          }}>
            <StyledListItemAvatar>
              <Step3Icon />
            </StyledListItemAvatar>
            <Box
              sx={{ minWidth: '75%' }}
            >
              <ListItemText primary={<div style={{ fontSize: dashboardLayout ? theme.spacing(2) : theme.spacing(2.25), marginTop: '0px', marginBottom: '0px' }}><div style={{ fontSize: theme.spacing(1.3), color: theme.palette.grey56.main, fontWeight: 700 }}>{t('homeDepot.instructions.step3.heading')}</div><div>{t('homeDepot.instructions.step3.description1')}<span style={{ fontWeight: '600' }}> {t('homeDepot.instructions.step3.description2')}</span> {t('homeDepot.instructions.step3.description3')}</div> <div style={{ fontSize: theme.spacing(1.75), color: theme.palette.grey56.main, fontWeight: 400 }}>{t('homeDepot.instructions.step3.description4')}<span style={{ fontWeight: '600' }}> {programCode as string}</span></div></div>} />
              {GoToStepButtonComponent(HDSetupFlowSteps.Step3)}
            </Box>
          </StyledListItem>
        </List>
        <Box
          sx={{
            alignSelf: 'center',
          }}>
          <Typography
            variant='h4'
            onClick={goToDetail}
            sx={{
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(3),
              color: theme.palette.secondary.main,
              cursor: 'pointer',
            }}>{t('homeDepot.instructions.learnMore')}</Typography>
        </Box>
      </Stack>
    </>
  );
}
