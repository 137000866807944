export interface BusinessTrade {
    id?: string;
    name: string;
    order: number;
}

export interface Member {
    id: string;
    businessMainIndustry?: string;
    companyName?: string;
    companyAddressStreet?: string;
    companyAddressCity?: string;
    companyAddressState?: string;
    companyAddressZipCode?: string;
    companyEmail?: string;
    companyWebsiteUrl?: string;
    companyPhoneNumber?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    companyRoleId?: string;
    companyRole?: {
        id: string,
        name: string,
    };
    company?: {
        id: string;
        name: string;
        businessTrades?: BusinessTrade[];
    }
}

export interface MemberConfig {
    id: string;
    memberId: string;
    showFollowTopicsCard: boolean;
}

export const MemberLoginType = { OTP: 'otp' };

export const MemberLoginResponseMessage = { INVALID_OTP: 'invalid otp', EXPIRED_OTP: 'expired otp', MISSING_SESSION_ID: 'Missing session id' };
