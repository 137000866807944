import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useSolutionOrg } from '../config/useSolutionOrg';
import { selectMemberSession } from '../store/slices/onboardingSlice';
import { ResponseError, getCompanyEnrollmentInformation } from '../store/thunks/onboardingThunk';
import { useOnboardEventTracking } from './useOnboardEventTracking';
import { HOME_ROUTE, ONBOARDING_BASE_ROUTE } from '../routes/namedRoutes';

interface useCompanyEnrollmentInfoProps {
  calledFromOnboardingComponent: boolean;
  redirectPath?: string;
}
export function useCompanyEnrollmentInfo({
  calledFromOnboardingComponent,
  redirectPath,
}: useCompanyEnrollmentInfoProps) {
  const trackOnboardEvent = useOnboardEventTracking();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { envRewardProgramId } = useSolutionOrg();
  const memberSession = useAppSelector(selectMemberSession);

  useEffect(() => {
    dispatch(getCompanyEnrollmentInformation(
      {
        rewardProgramId: envRewardProgramId as string,
        memberId: memberSession.memberId,
      },
    ))
      .then(unwrapResult)
      .then((companyEnrollmentInfoResponse) => {
        if (companyEnrollmentInfoResponse?.status && calledFromOnboardingComponent) {
          // user already enrolled
          navigate(redirectPath || HOME_ROUTE);
        } else if (!(companyEnrollmentInfoResponse?.status) &&
          !calledFromOnboardingComponent) {
          // user has not yet enrolled
          navigate(redirectPath || ONBOARDING_BASE_ROUTE);
        }
      })
      .catch((error: ResponseError) => {
        trackOnboardEvent('get-company-enrollment-information-failed', { error }, error.apiResponseErrorStatus);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
