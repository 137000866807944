import { Box, CircularProgress } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useRef } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { MemberLoginType } from '../../common/models/Member';
import { useAppDispatch } from '../../store/hooks';
import { generateTemporaryLoginCodeSso } from '../../api/loginAPI';
import LoginDesktopLayout from './LoginDesktopLayout';
import { loginMember } from '../../store/thunks/authenticationThunk';
import { Login } from './Login';
import NavHeader from '../../common/NavHeader';

export interface OnboardingLayoutProps {
  afterLoginPath?: string
}
export default function LoginLayout({
  afterLoginPath,
}: OnboardingLayoutProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const initializedQueryLogin = useRef(false);
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const urlAccessToken = searchParams.get('accessToken');
  const urlAfterLoginPath = searchParams.get('afterLoginPath');
  const computedAfterLoginPath = useMemo(
    () => urlAfterLoginPath || afterLoginPath || '/home',
    [urlAfterLoginPath, afterLoginPath],
  );

  // handle passing of sessionId and otp token as query parameters
  useEffect(() => {
    async function autoLogin() {
      if (!initializedQueryLogin.current && urlAccessToken) {
        console.log('auto login triggered');
        initializedQueryLogin.current = true;
        try {
          const loginSessionResponse = await generateTemporaryLoginCodeSso(urlAccessToken);
          if (loginSessionResponse?.id && loginSessionResponse?.otp) {
            dispatch(loginMember({
              type: MemberLoginType.OTP,
              password: loginSessionResponse.otp,
              sessionId: loginSessionResponse.id,
            }))
              .then(unwrapResult)
              .then(() => {
                navigate(computedAfterLoginPath);
              })
              .catch((err: unknown) => {
                console.log(err);
                navigate(`/login?afterLoginPath=${computedAfterLoginPath}`);
              });
          } else {
            console.log('failed to get session for auto login');
            navigate(`/login?afterLoginPath=${computedAfterLoginPath}`);
          }
        } catch (err: unknown) {
          console.log(err);
          navigate(`/login?afterLoginPath=${computedAfterLoginPath}`);
        }
      }
    }

    autoLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {urlAccessToken ?
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}>
          <CircularProgress color="inherit" size={40} />
        </Box> : <>
          <NavHeader />
          {
            isDesktop ? <LoginDesktopLayout afterLoginPath={afterLoginPath} /> :
              <Login afterLoginPath={afterLoginPath} />
          }
        </>}
    </>
  );
}
