import { Navigate, useParams } from 'react-router-dom';

export function Redirect() {
  const { '*': path } = useParams();
  const pathRewrite = `/${path}`;
  console.log('Redirecting to:', pathRewrite);
  return (
    <Navigate to={pathRewrite}/>
  );
}
