import {
  Box, Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSolutionOrg } from '../../../../config/useSolutionOrg';
import { QuestionOption } from '../../../../common/models/Question';
import { useLogo } from '../../../../config/useLogo';

export interface BannerLogoProps {
  activePartners: QuestionOption[] | undefined
}
export function BannerLogos({ activePartners }: BannerLogoProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScrren = useMediaQuery(() => theme.breakpoints.down('sm'));
  const {
    onlyHDOnboardingHomeDepot: OnlyHDOnboardingHomeDepotLogo,
    main: Logo,
  } = useLogo();
  const { solutionOrgHasOnlyHomeDepot } = useSolutionOrg();

  const AllPartners = () => (
    <Grid container justifyContent="center" spacing={{
      xs: 6, sm: 1, md: 1, lg: 2,
    }} columns={{
      xs: 12, sm: 12, md: 12, lg: 12,
    }}
      rowSpacing={{
        lg: 3, md: 2, sm: 2, xs: 4,
      }}
      sx={{
        alignItems: 'center',
        mb: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          // marginTop: theme.spacing(2),
          marginBottom: theme.spacing(5),
          // fontSize: '18px',
        },
      }}
    >
      {activePartners &&
        activePartners.map((item, index) => (
          <Grid item xs={6} sm={4} md={4} lg={4} key={item.value}
            sx={{
              alignItems: 'center',
              [theme.breakpoints.down('sm')]: {
                paddingRight: theme.spacing(2),
              },
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <img src={item.whiteImageUrl} alt={item.display} />
            </Box>
          </Grid >
        ))}
    </Grid >
  );

  const OnlyHomeDepotPartner = () => (
    <>
      {/* {activePartners && activePartners.length > 0 && */}
      {/* commenting this as no info about activePartners on login */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: theme.spacing(2),
          [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
          },
        }}
      >
        <Box sx={{
          width: '50%',
          padding: theme.spacing(2.5),
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
          borderRadius: theme.spacing(1),
          background: theme.palette.color12.main,
          marginRight: theme.spacing(2.5),
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(4),
          },
        }}>
          {/* TODO: consider adding this image to the home depot option on BE */}
          {OnlyHDOnboardingHomeDepotLogo &&
            <OnlyHDOnboardingHomeDepotLogo
              width={isSmallScrren ? '84px' : '150px'}
              height={isSmallScrren ? '84px' : '150px'}
              title={
                activePartners?.[0]?.display || 'home depot'
              } />}
        </Box>
        {Logo &&
          <Box sx={{
            padding: theme.spacing(1.25),
            borderRadius: theme.spacing(1),
            background: theme.palette.color12.main,
            width: '50%',
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(4),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}>
            <Box
              sx={{
                mt: theme.spacing(10),
                [theme.breakpoints.down('sm')]: {
                  mt: theme.spacing(0),
                },
              }}
            >
              <Logo
                width={isSmallScrren ? '80px' : '200px'}
                height={'30px'}
                title={'more partners coming'}
              />
              <Box
                sx={{
                  borderRadius: theme.spacing(15.5),
                  backgroundColor: theme.palette.common.black,
                  color: theme.palette.common.white,
                  pb: theme.spacing(0.5),
                  pt: theme.spacing(0.5),
                  width: '75%',
                  display: 'inline-block',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                }}
              >
                <Typography variant='h10' sx={{
                  [theme.breakpoints.down('sm')]: {
                    fontSize: theme.spacing(1),
                  },
                }}>
                  + {t('home.banner.morePartners')}
                </Typography>
              </Box>
            </Box>
          </Box>
        }
      </Box>
      {/* } */}
    </>
  );

  return (
    <>{
      solutionOrgHasOnlyHomeDepot ? <OnlyHomeDepotPartner /> : <AllPartners />
    }
    </>);
}
