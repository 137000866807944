/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import {
  Alert, Snackbar, Box, Typography, TextField, Theme, useMediaQuery, useTheme, Checkbox,
  Grid,
  Divider,
  Link,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { CreateOrUpdatePaymentMethodInput } from '@optimus/models';
import { unwrapResult } from '@reduxjs/toolkit';
import _isEqual from 'lodash/isEqual';
import CallToActionButton from './CallToActionButton';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectGetPayPalAccountState, selectPayPalAccount, selectSetPayPalAccountIdState } from '../store/slices/dashboardSlice';
import { getPayPalAccount, setPayPalAccountId } from '../store/thunks/dashboardThunk';
import { selectMemberSession } from '../store/slices/onboardingSlice';
import { resendVerficationEmail } from '../api/dashboardAPI';
import { JoistSolutionOrgName, OnboardingEventType, emailRegex } from './constants';
import { useTracking } from './useTracking';
import { useSolutionOrg } from '../config/useSolutionOrg';
import { ReactComponent as PayPalLogo } from '../assets/images/paypal_logo.svg';
import { ReactComponent as PendingBadge } from '../assets/images/pending_badge.svg';
import { ReactComponent as CompletedBadge } from '../assets/images/completed_badge.svg';

const PaymentMethodStatus = {
  UNVERIFIED: 'unverified',
  PENDING: 'pending',
  VERIFIED: 'verified',
};

const cardStyle = (theme: Theme) => ({
  background: theme.palette.common.white,
  borderRadius: theme.spacing(1.625),
  paddingLeft: theme.spacing(3.5),
  paddingRight: theme.spacing(3.5),
  paddingTop: theme.spacing(3),
  marginTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
} as const);

const buttonStyle = (theme: Theme) => ({
  position: 'static',
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  background: theme.palette.secondary.main,
  borderRadius: theme.spacing(3.75),
  fontWeight: '600',
  fontSize: theme.spacing(2.3),
  '&:hover': {
    backgroundColor: theme.palette.color1.main,
  },
  '&:disabled': {
    backgroundColor: theme.palette.color1.main,
    opacity: '0.4',
    color: theme.palette.common.white,
  },
  [theme.breakpoints.up('sm')]: {
    width: '100%',
    marginLeft: '0px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '60% !important',
    marginLeft: '0px',
  },
} as const);

interface IFormInputs {
  email: string
  acknowledged: boolean
}

interface VerificationEmailCardProps {
  optionalCardStyle: (theme: Theme) => {
    [key: string]: unknown;
  },
  optionalBtnStyles?: {
    [k: string]: unknown;
  };
  emailInputStyles?: {
    [k: string]: unknown;
  };
  editMode?: boolean;
}

interface VerificationEmailCardHeaderProps{
  children: JSX.Element
}

export default function VerificationEmailCard({
  optionalCardStyle,
  optionalBtnStyles,
  emailInputStyles,
  editMode,
}: VerificationEmailCardProps) {
  const { t } = useTranslation();
  const getPayPalAccountState = useAppSelector(selectGetPayPalAccountState);
  const setPayPalAccountIdState = useAppSelector(selectSetPayPalAccountIdState);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector(selectMemberSession);
  const payPalAccount = useAppSelector(selectPayPalAccount);
  const isDesktopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const theme = useTheme();
  const [isDirty, setIsDirty] = useState(false);
  const trackEvent = useTracking();
  const { IsInAppExperience } = useSolutionOrg();
  const [disableAcknowledge, setDisableAcknowledge] = useState(false);
  const [checkAcknowledge, setCheckAcknowledge] = useState(false);
  const [updateEmailTriggered, setUpdateEmailTriggered] = useState(false);
  // TODO: use regex pattern in library module (Need to fix import issue of utils
  // library for this to work)

  useEffect(() => {
    if (accessToken && !(!editMode && payPalAccount?.status === PaymentMethodStatus.VERIFIED)) {
      dispatch(getPayPalAccount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const {
    handleSubmit,
    control,
    formState,
  } = useForm<IFormInputs>({ mode: 'onChange' });

  const onSubmit = handleSubmit((data: IFormInputs) => {
    if (data.email) {
      // no need to update if email has not changed and user's paypal is
      // already verified
      if (data.email === payPalAccount?.meta?.paypalAccountId &&
        payPalAccount?.status === PaymentMethodStatus.VERIFIED
      ) {
        setUpdateEmailTriggered(false);
        return;
      }

      const request: CreateOrUpdatePaymentMethodInput = {
        type: 'paypal',
        paypalAccountId: data.email,
        source: 'member',
        acknowledged: data.acknowledged,
      };

      dispatch(setPayPalAccountId(request))
        .then(unwrapResult)
        .then(() => {
          setOpenErrorSnackbar(false);
          setUpdateEmailTriggered(false);
        })
        .catch((error: unknown) => {
          setOpenErrorSnackbar(true);
        });
    }
  });

  const handleClose = () => {
    setOpenErrorSnackbar(false);
  };

  const onResendEmail = async () => {
    setIsResending(true);
    try {
      await resendVerficationEmail(payPalAccount?.memberId, payPalAccount?.id);
    } catch (err) {
      console.log(err);
      trackEvent({
        optimusEventType: OnboardingEventType,
        optimusEvents: [{
          name: 'resend-verification-email-failed',
          detail: {
            error: err,
            memberId: payPalAccount?.memberId,
            paymethodMethodId: payPalAccount?.id,
          },
        }],
        solutionOrg: JoistSolutionOrgName,
      });
    }
    setIsResending(false);
  };

  useEffect(() => {
    if (payPalAccount?.acknowledgedAt) {
      setCheckAcknowledge(true);
      setDisableAcknowledge(true);
    } else {
      setCheckAcknowledge(false);
      setDisableAcknowledge(false);
    }
  }, [payPalAccount?.acknowledgedAt]);

  const VerificationEmailCardHeader = ({ children }:VerificationEmailCardHeaderProps) => (
    <Box display={'flex'} sx={{ marginBottom: theme.spacing(3) }}>
            <Box display={'flex'} alignItems={'center'}>
              <PayPalLogo width={'48px'} height={'48px'}/>
              <Typography variant='h4' component='div' sx={{
                marginBottom: theme.spacing(1.5),
                marginLeft: theme.spacing(2.5),
                marginRight: theme.spacing(2.5),
              }}>
                {t('verification.heading')}
              </Typography>
            </Box>
            {children}
          </Box>
  );

  return (
    <>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {t('error.generalMessage')}
        </Alert>
      </Snackbar>
      {getPayPalAccountState === 'pending' ? null : (!(payPalAccount?.meta?.paypalAccountId) ||
        payPalAccount?.status === PaymentMethodStatus.UNVERIFIED ||
        updateEmailTriggered) ? <Box
          sx={isDesktopScreen ? [cardStyle, optionalCardStyle] : [cardStyle]}
        >
        <VerificationEmailCardHeader>
          <PendingBadge style={{ marginTop: '8px' }}/>
        </VerificationEmailCardHeader>
        {/* uses the default variant body1 */}
        <Typography
          sx={{
            color: theme.palette.color2.main,
            ...(editMode && { marginBottom: theme.spacing(3) }),
          }}>
          {
              <>
                {t('verification.description1')} <a target="_blank" rel="noopener noreferrer"
                  style={{ color: theme.palette.color2.main }}
                  href="https://www.paypal.com/business/getting-started">{t('verification.description2')}</a> {t('verification.description3')}
              </>

          }
        </Typography>
        <form onSubmit={onSubmit}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box>
                <Controller
                  name="acknowledged"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: props }) => (
                    <Checkbox
                      icon={<Box sx={{
                        border: '2px solid #CDD6DA',
                        width: theme.spacing(2.25),
                        height: theme.spacing(2.25),
                      }}></Box>}
                      sx={{
                        '&.MuiCheckbox-root': {
                          paddingTop: theme.spacing(0),
                          paddingLeft: theme.spacing(0),
                          width: theme.spacing(4.125),
                          height: theme.spacing(4.125),
                          '&.Mui-checked': {
                            color: theme.palette.secondary.main,
                          },
                        },
                      }}
                      {...props}
                      onChange={
                        (e) => {
                          props.onChange(e);
                          setCheckAcknowledge(e.target.checked);
                        }
                      }
                      checked={checkAcknowledge}
                      disabled={disableAcknowledge}
                    />
                  )}
                />
              </Box>
              <Typography sx={{ color: theme.palette.color2.main }}>
                {t('verification.acknowledgemnet')}
              </Typography>
            </Box>
            <Grid container
              sx={{
                alignItems: 'center',
                textAlign: 'center',
                pt: theme.spacing(1),
                // marginTop: theme.spacing(3),
                // marginBottom: theme.spacing(3),
              }}
              >
              <Grid item xs={12} sm={7} md={8} lg={7.5}
              sx={{
                [theme.breakpoints.between('sm', 'md')]: {
                  ml: theme.spacing(13),
                },
                [theme.breakpoints.down('sm')]: {
                  mt: theme.spacing(3),
                },
              }}
              >
                <Controller
                  name="email"
                  control={control}
                  defaultValue={payPalAccount?.meta?.paypalAccountId || ''}
                  render={({
                    field: {
                      onChange, onBlur, value, ref,
                    },
                  }) => <TextField
                      onChange={(event) => {
                        if (editMode) {
                          setIsDirty(!(_isEqual(
                            event.target.value,
                            payPalAccount?.meta?.paypalAccountId,
                          )));
                        }
                        onChange(event);
                        setCheckAcknowledge(false);
                        setDisableAcknowledge(false);
                      }}
                      placeholder={'Paypal email address'}
                      onBlur={onBlur}
                      value={value}
                      InputProps={{
                        sx: {
                          borderRadius: '104.8px',
                          border: '1px solid #CDD6DA',
                          background: '#FFFEFE',
                          fontWeight: '400',
                          fontSize: '16px',
                          paddingLeft: '17px',
                          ...(editMode && {
                            width: '100%',
                          }),
                        },
                      }}
                      sx={{
                        width: '100%',
                        ...(emailInputStyles && emailInputStyles),
                      }}
                    />}
                  rules={{
                    required: 'Email is required',
                    pattern: {
                      value: emailRegex,
                      message: `${t('error.invalidEmail')}`,
                    },
                  }}
                />
                {(formState?.errors?.email && formState.errors.email.type !== 'required') &&
                  <Alert
                    sx={{
                      background: theme.palette.common.white,
                      color: theme.palette.error.main,
                      p: 0,
                      ...(editMode && {
                        '&.MuiAlert-root': {
                          marginBottom: theme.spacing(-5),
                        },
                      }),
                    }}
                    severity='error'>
                    {formState?.errors.email.message}
                  </Alert>}
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={4}
              sx={{
                ml: 'auto',
                [theme.breakpoints.down('md')]: {
                  marginTop: theme.spacing(2),
                },
              }}
              >
                <CallToActionButton
                text={setPayPalAccountIdState === 'pending' ?
                  <CircularProgress color="inherit" size={24} /> : `${t('button.confirm')}`}
                type={'submit'}
                optionalStackStyles={editMode ? {
                  display: 'flex',
                  justifyContent: 'center',
                } : undefined}
                sx={[buttonStyle, ...(optionalBtnStyles ? [optionalBtnStyles] : [])]}
                disable={!checkAcknowledge ||
                  !formState.isValid || setPayPalAccountIdState === 'pending'}
              />
            </Grid>
          </Grid>
        </form >
      </Box > : (payPalAccount?.status === PaymentMethodStatus.PENDING ? <Box
        sx={isDesktopScreen ? [cardStyle, optionalCardStyle] : [cardStyle]}
      >
        <VerificationEmailCardHeader>
          <PendingBadge style={{ marginTop: '8px' }}/>
        </VerificationEmailCardHeader>
        <Box sx={{
          marginBottom: theme.spacing(2),
          color: theme.palette.color2.main,
        }}>
          <Typography component={'span'}>
            {t('verification.emailSentMessage')}
          </Typography>
          <Typography component='span' sx={{ fontWeight: 600 }}>
            {payPalAccount?.meta?.paypalAccountId || ''}
          </Typography>
          <Typography component={'span'}>
            {t('verification.emailSentMessage2')}
          </Typography>
        </Box>
        <Typography
        sx={{
          color: theme.palette.color2.main,
          marginBottom: theme.spacing(1),
        }}
        >
          {t('verification.emailSentMessage3')}
        </Typography>
        <Grid container
          sx={{
            alignItems: 'center',
            textAlign: 'center',
            pt: theme.spacing(1),
          }}
          >
          <Grid item xs={12} sm={7} md={8} lg={7.5}
          sx={{
            [theme.breakpoints.between('sm', 'md')]: {
              ml: theme.spacing(13),
            },
            [theme.breakpoints.down('sm')]: {
              mt: theme.spacing(3),
            },
          }}
          >
            <CallToActionButton
              text={isResending ?
                <CircularProgress color="inherit" size={24} /> : t('verification.resendEmail') || ''}
              type={'submit'}
              optionalStackStyles={editMode ? {
                display: 'flex',
                justifyContent: 'center',
              } : undefined}
              onClick={onResendEmail}
              sx={[
                buttonStyle,
                ...(optionalBtnStyles ? [optionalBtnStyles] : []),
                {
                  [theme.breakpoints.between('sm', 'md')]: {
                    width: '100% !important',
                    marginLeft: '0px',
                  },
                },
              ]}
              disable={isResending}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={4}
            sx={{
              ml: 'auto',
              [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(0),
              },
            }}
            >
              <CallToActionButton
                  text={t('verification.editEmail') || ''}
                  type={'submit'}
                  optionalStackStyles={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  onClick={() => setUpdateEmailTriggered(true)}
                  sx={[
                    buttonStyle,
                    ...(optionalBtnStyles ? [optionalBtnStyles] : []),
                    ...([{
                      backgroundColor: theme.palette.common.white,
                      color: theme.palette.secondary.main,
                      border: `1px solid ${theme.palette.secondary.main}`,
                      '&:hover': {
                        backgroundColor: theme.palette.common.white,
                      },
                    }]),
                  ]}
                  disableRipple
                />
          </Grid>
        </Grid>
      </Box> : ((editMode && payPalAccount?.status === PaymentMethodStatus.VERIFIED) ?
      <Box
      sx={isDesktopScreen ? [cardStyle, optionalCardStyle] : [cardStyle]}
    >
      <VerificationEmailCardHeader>
        <CompletedBadge style={{ marginTop: '8px' }}/>
      </VerificationEmailCardHeader>
      <Box sx={{
        marginBottom: theme.spacing(3),
        color: theme.palette.color2.main,
      }}>
            <Typography component='span'>
              {t('verification.completedMessage')}
            </Typography>
            <Typography component='span' sx={{ fontWeight: 600 }}>
            {payPalAccount?.meta?.paypalAccountId || ''}
            </Typography>
            <Typography component='span'>
              {t('verification.completedMessage2')}
            </Typography>
      </Box>
      <Divider sx={{ marginBottom: theme.spacing(3) }}/>
      <Box
        sx={{
          mb: theme.spacing(3),
        }}>
        <Typography component='span' sx={{
          marginRight: theme.spacing(1),
        }}>
          {t('verification.unlinkMessage')}
        </Typography>
        <Typography component='span'>
          <Link
            component="button"
            variant="body2"
            color={theme.palette.secondary.main}
            onClick={() => setUpdateEmailTriggered(true)}
          >
            {t('verification.unlinkMessage2')}
          </Link>
        </Typography>
      </Box>
    </Box> : null))}
    </>
  );
}
