/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme,
} from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import orderBy from 'lodash/orderBy';
import { useAppSelector } from '../../store/hooks';
import { selectTopTopics, selectGetTopTopicsState } from '../../store/slices/contentSlice';
import { useTracking } from '../../common/useTracking';
import { ContentEventType } from '../../common/constants';
import { selectMemberSession } from '../../store/slices/onboardingSlice';

interface ITopic {
  id: string
  name: string
  displayName?: string
  category: string
}

const topics: ITopic[] = [
  {
    id: '1',
    name: 'Scale my business',
    displayName: 'Scale my business',
    category: 'Business Goal',
  },
  {
    id: '2',
    name: 'Better Work Life Balance',
    displayName: 'Better Work/Life Balance',
    category: 'Business Goal',
  },
  {
    id: '3',
    name: 'Control cash flow',
    displayName: 'Control cash flow',
    category: 'Business Goal',
  },
  {
    id: '4',
    name: 'Finances & Taxes',
    category: 'Topic',
  },
  {
    id: '5',
    name: 'Leadership',
    category: 'Topic',
  },
];

export function TopicsCard() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const trackEvent = useTracking();
  const [searchParams] = useSearchParams();
  const memberSession = useAppSelector(selectMemberSession);
  const memberId = memberSession.memberId || searchParams.get('memberId');
  const navigationSelectedIndex = location?.state?.selectedIndex;
  const navigationSelectedAllTopicsIndex = location?.state?.selectedAllTopicsIndex;
  const navigationShowAllTopicsState = location?.state?.showAllTopicsState;
  const [selectedIndex, setSelectedIndex] = useState(navigationSelectedIndex || -1);
  const [selectedAllTopicsIndex, setSelectedAllTopicsIndex] =
    useState(navigationSelectedAllTopicsIndex || -1);
  const [showAllTopics, setShowAllTopics] = useState(navigationShowAllTopicsState || false);
  const getAllTopicsState = useAppSelector(selectGetTopTopicsState);
  const allTopics = useAppSelector(selectTopTopics);
  const sortedTopTopics =
    useMemo(() => {
      const topTopics = allTopics.slice(0, 17);
      return orderBy(topTopics, 'name').sort();
    }, [allTopics]);
  const [topicsToShow, setTopicsToShow] = useState(sortedTopTopics);

  useEffect(() => {
    if (typeof navigationSelectedIndex === 'number' && !Number.isNaN(navigationSelectedIndex)) {
      setSelectedIndex(navigationSelectedIndex);
    }
    if (typeof navigationSelectedAllTopicsIndex === 'number' && !Number.isNaN(navigationSelectedAllTopicsIndex)) {
      setSelectedAllTopicsIndex(navigationSelectedAllTopicsIndex);
    }
  }, [navigationSelectedIndex, navigationSelectedAllTopicsIndex]);

  useEffect(() => {
    if (showAllTopics) {
      setTopicsToShow(sortedTopTopics);
    } else {
      setTopicsToShow(sortedTopTopics.slice(0, 5));
    }
  }, [showAllTopics, sortedTopTopics]);

  const handleListItemClick = (value: ITopic, index: number) => {
    const params = new URLSearchParams();
    if (value.category === 'Topic') {
      params.append('topic', value.name || '');
    } else if (value.category === 'Business Goal') {
      params.append('businessGoal', value.name || '');
    }
    const route = `/search?${params.toString()}`;
    navigate(route, {
      state: {
        selectedIndex: index,
        selectedAllTopicsIndex: -1,
      },
    });
  };

  const handleAllTopicClick = (topic: string, index: number) => {
    const params = new URLSearchParams();
    params.append('topic', topic || '');
    const route = `/search?${params.toString()}`;
    navigate(route, {
      state: {
        selectedAllTopicsIndex: index,
        selectedIndex: -1,
      },
    });
    // fire an event with topic name
    trackEvent({
      optimusEventType: ContentEventType,
      optimusEvents: [
        {
          name: 'topic_clicked',
          detail: {
            memberId,
            topicName: topic,
            category: 'Topic',
          },
        },
      ],
    });
  };

  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: '13px',
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        width: '100%',
        height: 'fit-content',
      }}
    >
      <Box
        sx={{
          cursor: 'default',
        }}
      >
        <Typography variant='h7'>{t('dashboard.spotlightTopics.heading')}</Typography>
      </Box>
      <List>
        {topics.map((topic, index) => (
          <ListItem
            key={index}
            onClick={() => {
              setSelectedIndex(index);
              handleListItemClick(topic, index);
            }}
            sx={{
              color:
                index === selectedIndex ? theme.palette.secondary.main : theme.palette.color6.main,
              '&:hover': {
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.common.white,
              },
            }}
          >
            <ListItemText
              sx={{ flex: '0 1 auto' }}
              primary={topic.displayName ? topic.displayName : topic.name} />
            <ListItemIcon sx={{ minWidth: theme.spacing(0) }}>
              <NavigateNextIcon />
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
      {getAllTopicsState === 'pending' ? null :
        <Box>
          <Box
            sx={{
              cursor: 'default',
            }}
          >
            <Typography variant='h7'>{t('dashboard.spotlightTopics.allTopics.heading')}</Typography>
          </Box>
          <List>
            {topicsToShow.map((topic, index) => (
              <ListItem
                key={index}
                onClick={() => {
                  setSelectedIndex(-1);
                  setSelectedAllTopicsIndex(index);
                  handleAllTopicClick(topic.name, index);
                }}
                sx={{
                  color:
                    index ===
                      selectedAllTopicsIndex ? theme.palette.secondary.main :
                      theme.palette.color6.main,
                  '&:hover': {
                    color: theme.palette.secondary.main,
                    backgroundColor: theme.palette.common.white,
                  },
                }}
              >
                <ListItemText
                  sx={{
                    flex: '0 1 auto',
                  }}
                  primary={topic.name} />
                <ListItemIcon sx={{ minWidth: theme.spacing(0) }}>
                  <NavigateNextIcon />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        </Box>
      }
      <Box
        sx={{
          color: theme.palette.secondary.main,
        }}
      >
        <Typography variant='body1'
          onClick={() => setShowAllTopics(!showAllTopics)}
        > {showAllTopics ? t('dashboard.spotlightTopics.showLess') : t('dashboard.spotlightTopics.showMore')}
        </Typography>
      </Box>
    </Box>
  );
}
