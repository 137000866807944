import useMarketo from './useMarketo';

export interface MarketoFormProps{
  baseUrl: string;
  formId: number;
  munchkinId: string;
}
export function MarketoForm({ baseUrl, formId, munchkinId }:MarketoFormProps) {
  useMarketo({ baseUrl, munchkinId, formId });

  return <form id={`mktoForm_${formId}`} style={{ display: 'none' }}></form>;
}
