import { Box, Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardImage2 from '../assets/images/dashboard_image4.png';

interface RewardsBannerProps {
  logo?: JSX.Element,
  mainTextBox: JSX.Element;
  upperTextBox: JSX.Element;
  lowerTextBox: JSX.Element;
  gotoUrl?: string;
  optionalBackgroundColor?: string;
}

export default function RewardsBanner({
  logo, lowerTextBox, upperTextBox, gotoUrl, optionalBackgroundColor, mainTextBox,
}: RewardsBannerProps) {
  const navigate = useNavigate();
  return (
    <Box
      sx={(theme:Theme) => ({
        background: optionalBackgroundColor || 'radial-gradient(104.99% 53.33% at 50% 46.67%, #DD7589 0%, #FE2C55 100%)',
        borderRadius: '26px',
        paddingLeft: '20px',
        paddingTop: '68px',
        display: 'flex',
        flexDirection: 'column',
        height: '600px',
        cursor: 'pointer',
        [theme.breakpoints.up('lg')]: {
          height: 'fit-content',
        },
      })}
      onClick={() => {
        if (gotoUrl) {
          navigate(gotoUrl);
        }
      }}
    >
      {logo}
      {mainTextBox}
      {upperTextBox}
      <Box sx={(theme: Theme) => ({
        alignSelf: 'end',
        [theme.breakpoints.up('lg')]: {
          transform: 'scale(1.40)',
          transformOrigin: 'right bottom',
        },
      })}>
        <img src={DashboardImage2} alt="Logo" />
      </Box>
      {lowerTextBox}
    </Box>
  );
}
