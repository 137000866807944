import {
  Box, Container, Drawer, Theme, useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { DashboardNavElement } from './DashboardNavElement';
import { useFeatureFlags } from '../../config/FeatureFlagsProvider';
import { useLogo } from '../../config/useLogo';

interface DashboardMobileLayoutProps {
  children: JSX.Element;
  navElems: {
    name: string;
    path: string;
    Outline: typeof SvgIcon;
    Filled: typeof SvgIcon;
    isCurrent?: boolean;
  }[];
}

const sxAlignRight = {
  marginLeft: 'auto',
};

const sxIconStyle = (theme: Theme) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  padding: '8px',
  color: theme.palette.color2.main,
} as const);

export function DashboardMobileLayout({
  children,
  navElems,
}:DashboardMobileLayoutProps) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { searchFlag } = useFeatureFlags();
  const { secondaryOne: NavLogo } = useLogo();

  const goToSearch = () => {
    setOpen(false);
    navigate('/search');
  };

  const DrawerList = (
      <Box sx={{ paddingLeft: theme.spacing(5) }}>
      {
        navElems.map((elem, index) => (
          <DashboardNavElement
            key={index}
            sx={{ marginBottom: '36px' }}
            name={elem.name}
            path={elem.path}
            outlineIcon={elem.Outline}
            filledIcon={elem.Filled}
            isSelected={elem.isCurrent ?? false}
            onClick={() => setOpen(false)}
          />
        ))
      }
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.12),0px 0px 4px 0px rgba(0, 0, 0, 0.14)',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          zIndex: 1201,
        }}
        >
          <Box sx={[{
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '12px',
            height: theme.spacing(6),
            width: theme.spacing(6),
            padding: theme.spacing(1),
          }, { ...(open && { background: '#EEEEEE' }) }]}>
            <MenuIcon sx={[{
              width: theme.spacing(4),
              height: theme.spacing(4),
              cursor: 'pointer',
              color: theme.palette.color2.main,
              fontWeight: 500,
            }, { ...(open && { color: theme.palette.secondary.main }) }]}
            onClick={() => setOpen(!open)}
            />
          </Box>
          {NavLogo && <NavLogo
                height={34}
                style={{
                  marginTop: theme.spacing(3), marginBottom: theme.spacing(3), marginLeft: '11px', width: '158px',
                }}
              />}
        { searchFlag && <Box sx={[sxAlignRight, sxIconStyle]}
            onClick={goToSearch}>
            <SearchIcon sx={{ width: '100%', height: '100%' }}/>
          </Box> }
      </Box>
      <Drawer open={open} onClose={() => setOpen(false)} PaperProps={{
        sx: { width: '100%', paddingTop: '120px' },
      }} >
            {DrawerList}
      </Drawer>
      <Container maxWidth={'sm'}
      sx={{ marginTop: theme.spacing(3) }}
      >
        {children}
      </Container>
    </>
  );
}
