import axios from 'axios';
import { AddOrUpdateMemberToTagInput } from '@optimus/models';
import { Content } from '../common/models/content/Content';
import { GetContentRequest } from '../common/models/content/GetContentRequest';
import { SubmitSurveyFormRequest } from '../common/models/content/SubmitSurveyFormRequest';
import { axiosInstance } from './apiConfig';

export const getContent = async (request: GetContentRequest) => {
  const endpoint = request.useContentFromOpenSearchFlag ? '/content/v3' : '/content/v2';

  const { data } = await axiosInstance.get(endpoint, {
    params: {
      pageSize: request.pageSize,
      page: request.page,
      randomSeed: request.randomContentSeed,
    },
  });

  return data;
};

export const getSavedContent = async (request: GetContentRequest) => {
  const { data } = await axiosInstance.get('/content/saved', {
    params: {
      pageSize: request.pageSize,
      page: request.page,
    },
  });
  return data;
};

export async function getSearchContent(request: GetContentRequest): Promise<Content[]> {
  const { data } = await axiosInstance.get('/content/search', {
    params: {
      pageSize: request.pageSize,
      page: request.page,
      searchTerm: request.searchTerm,
      topic: request.topic,
      businessGoal: request.businessGoal,
    },
  });
  return data;
}

export const getContentById = async (contentId: number) => {
  const { data } = await axiosInstance.get(`/content/${contentId}`);
  return data;
};

export const getContenStatus = async (contentIds: string) => {
  const { data } = await axiosInstance.get(`/content/state?ids=${contentIds}`);
  return data;
};

export const getContentForm = async (formId: string | undefined) => {
  const { data } = await axiosInstance.get(`/forms/${formId}`);
  return data;
};

export const getFormAnswers =
  async (formId: string | undefined, memberId: string | undefined) => {
    const { data } = await axiosInstance.get('/answers', {
      params: {
        formId,
        memberId,
      },
    });
    return data;
  };

export const saveContentFormAnswers = async (request: SubmitSurveyFormRequest) => {
  const { data } = await axiosInstance.post('/answers', request);
  return data;
};

export const getTiktokEmbeddedCode = async (tiktokUrl: string) => {
  const { data } = await axios.get(`https://www.tiktok.com/oembed?url=${tiktokUrl}`);
  return data;
};

export const getPollResults = async (questionId: string | undefined) => {
  const { data } = await axiosInstance.get(`/polls/${questionId}/results`);
  return data;
};

export const getPollTotalVotes = async (questionId: string | undefined) => {
  const { data } = await axiosInstance.get(`/polls/${questionId}/totalVotes`);
  return data;
};

export const deletePollAnswer = async (answerId: string | undefined) => {
  const { data } = await axiosInstance.delete(`/answers/${answerId}`);
  return data;
};

export const addFollowTopic = async (
  topicName: string,
  memberId: string,
  request: AddOrUpdateMemberToTagInput,
) => {
  const { data } = await axiosInstance.put(`/members/${memberId}/tags/${topicName}`, request);
  return data;
};

export const deleteFollowTopic = async (topicName: string, memberId: string) => {
  const { data } = await axiosInstance.delete(`/members/${memberId}/tags/${topicName}`);
  return data;
};

export const getFollowTopics = async (memberId: string) => {
  const { data } = await axiosInstance.get(`/members/${memberId}/tags`);
  return data;
};

export const getTopTopics = async () => {
  const { data } = await axiosInstance.get('/content/top-topics');
  return data;
};
