/* eslint-disable no-nested-ternary */
import {
  Alert, Snackbar, Box, Typography, TextField, Theme, useMediaQuery, useTheme,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { AddMemberToPartnerInput } from '@optimus/models';
import { unwrapResult } from '@reduxjs/toolkit';
import _isEqual from 'lodash/isEqual';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import CallToActionButton from './CallToActionButton';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectGetLennoxMemberRelationState, selectLennoxMemberRelation, selectSetLennoxMemberKeyState } from '../store/slices/dashboardSlice';
import { getLennoxMemberRelation, setLennoxMemberKey } from '../store/thunks/dashboardThunk';
import { selectMemberSession } from '../store/slices/onboardingSlice';
import LennoxAccountNumberCardSkeleton from './LennoxAccountNumberCardSkeleton';

const cardStyle = (theme: Theme) => ({
  background: theme.palette.common.white,
  borderRadius: '13px',
  paddingLeft: '28px',
  paddingRight: '28px',
  paddingTop: '24px',
  marginTop: '24px',
} as const);

interface IFormInputs {
  lennoxAccountNumber: string
}

interface LennoxAccountNumberCardProps {
  optionalCardStyle: (theme: Theme) => {
    [key: string]: unknown;
  },
  optionalBtnStyles?: {
    [k: string]: unknown;
  };
  inputStyles?: {
    [k: string]: unknown;
  };
  editMode?: boolean;
}

export default function LennoxAccountNumberCard({
  optionalCardStyle,
  optionalBtnStyles,
  inputStyles,
  editMode,
}: LennoxAccountNumberCardProps) {
  const { t } = useTranslation();
  const getLennoxMemberRelationState = useAppSelector(selectGetLennoxMemberRelationState);
  const setLennoxMemberKeyState = useAppSelector(selectSetLennoxMemberKeyState);
  const lennoxMemberRelation = useAppSelector(selectLennoxMemberRelation);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const dispatch = useAppDispatch();
  const { accessToken, memberId } = useAppSelector(selectMemberSession);
  const isDesktopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const theme = useTheme();
  const [isDirty, setIsDirty] = useState(false);

  const lennoxNumberRegex = /^[a-zA-Z0-9][0-9]{5}$/;

  useEffect(() => {
    if (accessToken && !(lennoxMemberRelation?.partnerMemberKey && !editMode)) {
      dispatch(getLennoxMemberRelation());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const {
    handleSubmit,
    control,
    formState,
  } = useForm<IFormInputs>({ mode: 'onChange' });

  const onSubmit = handleSubmit((data: IFormInputs) => {
    if (data.lennoxAccountNumber) {
      const request: AddMemberToPartnerInput = {
        partnerMemberKey: data.lennoxAccountNumber,
      };

      dispatch(setLennoxMemberKey({
        memberId,
        partnerId: process.env.REACT_APP_LENNOX_PARTNER_ID || '',
        data: request,
      }))
        .then(unwrapResult)
        .then(() => {
          setOpenSuccessSnackbar(true);
          setOpenErrorSnackbar(false);
          if (editMode) {
            setIsDirty(false);
          }
        })
        .catch((error: unknown) => {
          setOpenSuccessSnackbar(false);
          setOpenErrorSnackbar(true);
        });
    }
  });

  const handleClose = () => {
    setOpenErrorSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  return (
    <>
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {t('success.dashboard')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {t('error.generalMessage')}
        </Alert>
      </Snackbar>
      {getLennoxMemberRelationState === 'pending' ? <LennoxAccountNumberCardSkeleton /> :
        (!lennoxMemberRelation || (lennoxMemberRelation.partnerMemberKey && !editMode) ? null : <Box
          sx={isDesktopScreen ? [cardStyle, optionalCardStyle] : [cardStyle]}
        >
          <Typography sx={{
            fontWeight: 600,
            fontSize: '18px',
            marginBottom: theme.spacing(1.5),
          }}>
             {t('lennox.accountNumberInput')}
          </Typography>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              color: theme.palette.color2.main,
              marginBottom: '24px',
            }}>
            {t('lennox.description')}
          </Typography>
          <form onSubmit={onSubmit}>
            <Box sx={{
              marginTop: '8px',
            }}>
              <Controller
                name="lennoxAccountNumber"
                control={control}
                defaultValue={lennoxMemberRelation.partnerMemberKey || ''}
                render={({
                  field: {
                    onChange, onBlur, value,
                  },
                }) => <TextField
                    onChange={(event) => {
                      if (editMode) {
                        setIsDirty(!(_isEqual(
                          event.target.value,
                          lennoxMemberRelation.partnerMemberKey,
                        )));
                      }
                      onChange(event);
                    }}
                    onBlur={onBlur}
                    value={value}
                    type="text"
                    InputProps={{
                      sx: {
                        borderRadius: '104.8px',
                        border: '1px solid #CDD6DA',
                        background: theme.palette.common.white,
                        fontWeight: '400',
                        fontSize: '16px',
                        paddingLeft: '17px',
                        ...(editMode && {
                          width: '100%',
                          [theme.breakpoints.up('sm')]: {
                            width: '60%',
                            alignSelf: 'center',
                          },
                        }),
                      },
                    }}
                    sx={{
                      width: '100%',
                      ...(inputStyles && inputStyles),
                    }}
                  />}
                rules={{
                  required: `${t('lennox.requiredRule')}`,
                  pattern: {
                    value: lennoxNumberRegex,
                    message: `${t('lennox.error')}`,
                  },
                }}
              />
              {(formState?.errors?.lennoxAccountNumber &&
                formState.errors.lennoxAccountNumber.type !== 'required') &&
                <Alert
                  sx={{
                    background: theme.palette.common.white,
                    color: theme.palette.error.main,
                    p: 0,
                    ...(editMode && {
                      '&.MuiAlert-root': {
                        [theme.breakpoints.up('sm')]: {
                          width: '60%',
                          margin: '0 auto',
                          marginBottom: '-16px',
                        },
                      },
                    }),
                  }}
                  severity='error'>
                  {formState?.errors.lennoxAccountNumber.message}
                </Alert>}
            </Box>
            <CallToActionButton
              text={setLennoxMemberKeyState === 'pending' ?
                <CircularProgress color="inherit" size={24} /> : (
                  (editMode && lennoxMemberRelation.partnerMemberKey && !isDirty) ?
                    <Box sx={{
                      display: 'flex', justifyContent: 'center', alignItems: 'center',
                    }}>
                      <CheckCircleSharpIcon sx={{ marginRight: '4px' }} /><Box>Thank you</Box></Box> :
                    'Confirm'
                )}
              type={'submit'}
              optionalStackStyles={editMode ? {
                display: 'flex',
                justifyContent: 'center',
              } : undefined}
              sx={{
                position: 'static',
                width: '100%',
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(3),
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
                background: theme.palette.color1.main,
                borderRadius: theme.spacing(3.75),
                fontWeight: '600',
                fontSize: '18.864px',
                '&:hover': {
                  backgroundColor: theme.palette.color1.main,
                },
                '&:disabled': {
                  backgroundColor: theme.palette.color1.main,
                  opacity: '0.4',
                  color: theme.palette.common.white,
                },
                [theme.breakpoints.up('sm')]: {
                  width: '100%',
                  marginLeft: '0px',
                },
                ...(optionalBtnStyles && optionalBtnStyles),
                ...(editMode && {
                  [theme.breakpoints.up('sm')]: {
                    width: '60%',
                    marginLeft: '0px',
                  },
                }),
              }}
              disable={(editMode && !isDirty) || !formState.isValid || setLennoxMemberKeyState === 'pending'}
            />
          </form>
        </Box>)}
    </>
  );
}
