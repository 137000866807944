import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import {
  Alert,
  Typography,
  Theme,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { SystemStyleObject } from '@mui/system';
import { useSearchParams } from 'react-router-dom';
import CallToActionButton from '../../../common/CallToActionButton';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectCurrentFormIndex,
  selectCurrentFormValidState,
  selectEnrollMemberToRewardStatus,
  selectMemberInformation,
  selectOnboardingFormAnswers,
} from '../../../store/slices/onboardingSlice';
import { getMemberInformation, getCompanyRoles, getOnboardingForm } from '../../../store/thunks/onboardingThunk';
import { ONBOARDING_FORMS } from '../constants';
import { useTracking } from '../../../common/useTracking';
import { OnboardingCompanyInformationProps } from '../CompanyInformation/OnboardingCompanyInformation';
import { useResetOnboarding } from '../../../common/useResetOnboarding';

interface OnboardFormProps {
  onboardFormId: string;
  optionalContainerStyle?: (theme: Theme) => SystemStyleObject<Theme>;
  optionalBtnStyles?: {
    [k: string]: unknown;
  };
  optionalBackgroundColor?: string;
  setIsOnboarding?: (value: boolean) => void;
  OptionalHeader?: JSX.Element;
  numberOfQuestions: number
  companyInformationComponent?: (props: OnboardingCompanyInformationProps) => JSX.Element;
  businessInformationComponent?: () => JSX.Element;
  createPartnersInformationComponent?: (value?: (text: string) => void) => () => JSX.Element;
  noFooterText?: boolean;
  customBusinessInfoBtnText?:string
}

export function OnboardForm(props: OnboardFormProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const currentFormIndex = useAppSelector(selectCurrentFormIndex);
  const isFormValid = useAppSelector(selectCurrentFormValidState);
  const enrollMemberToRewardStatus = useAppSelector(selectEnrollMemberToRewardStatus);
  const [formId, setFormId] = useState<string>();
  const [buttonText, setButtonText] = useState<string>(`${t('button.next')}`);
  const [FormComponent, setFormComponent] = useState<React.FC | null>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [searchParams] = useSearchParams();
  const memberId = searchParams.get('memberId') || undefined;
  const trackEvent = useTracking();
  const memberInformation = useAppSelector(selectMemberInformation);
  const onboardingFormAnswers = useAppSelector(selectOnboardingFormAnswers);
  const resetOnboarding = useResetOnboarding();

  useEffect(() => {
    // fetch question options on render
    switch (currentFormIndex) {
      case ONBOARDING_FORMS.companyInformation.index: {
        const getOnboardingFormResponse = dispatch(getOnboardingForm(
          {
            formId: props.onboardFormId,
            trackEvent,
          },
        ))
          .then(unwrapResult);
        const getMemberInformationResponse = dispatch(getMemberInformation(
          {
            id: memberId,
            trackEvent,
          },
        ))
          .then(unwrapResult);
        const getCompanyRolesResponse = dispatch(getCompanyRoles(
          {
            trackEvent,
          },
        ))
          .then(unwrapResult);
        Promise.all(
          [getOnboardingFormResponse, getMemberInformationResponse, getCompanyRolesResponse],
        )
          .then(() => {
            setOpenSnackbar(false);
            setFormId(ONBOARDING_FORMS.companyInformation.id);
            setFormComponent(() => props.companyInformationComponent || null);
          })
          .catch(() => {
            setOpenSnackbar(true);
          });
      }
        break;
      case ONBOARDING_FORMS.businessInformation.index:
        if (!(memberInformation?.email)) {
          // missing required information from previous step, so reset onboarding
          resetOnboarding();
        } else {
          setFormId(ONBOARDING_FORMS.businessInformation.id);
          setFormComponent(() => props.businessInformationComponent || null);
          if (props.numberOfQuestions === 2) {
            setButtonText(props.customBusinessInfoBtnText || `${t('button.done')}`);
          }
        }
        break;
      case ONBOARDING_FORMS.partnersInformation.index:
        if (!(memberInformation?.email) || !(memberInformation?.businessMainIndustry) ||
              onboardingFormAnswers.length === 0) {
        // missing required information from previous steps, so reset onboarding
          resetOnboarding();
        } else {
          setFormId(ONBOARDING_FORMS.partnersInformation.id);
          setFormComponent(() => props.createPartnersInformationComponent?.(setButtonText) || null);
          setButtonText(`${t('button.done')}`);
        }
        break;
      default:
        setFormId('');
        setFormComponent(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFormIndex]);

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {props.OptionalHeader || null}
      <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleClose}>
        <Alert severity="error" sx={{ width: '100%' }}>
          {t('error.generalMessage')}
        </Alert>
      </Snackbar>
      <Box>
        {FormComponent ? <FormComponent /> : null}
        <Box>
          <Typography
            sx={{
              mt: 4,
              textAlign: 'center',
              fontSize: '13px',
              marginBottom: '16px',
            }}
          >
            {!props.noFooterText && t('onboarding.footer')}
          </Typography>
          <CallToActionButton
            text={buttonText}
            disable={!FormComponent || !isFormValid || enrollMemberToRewardStatus === 'pending'}
            // dynamically change this value to the corresponding form id of the form to load
            form={formId}
            type='submit'
            sx={{
              position: 'static',
              width: '100%',
              marginTop: theme.spacing(2),
              [theme.breakpoints.up('sm')]: {
                width: '100%',
                marginLeft: '0px',
              },
              ...(props.optionalBtnStyles && props.optionalBtnStyles),
            }}
          />
        </Box>
      </Box>
      <Box display={'flex'} justifyContent='center' mt={2}>
        {[...Array(props.numberOfQuestions).keys()].map((val) => (
          <Box
            key={val}
            sx={{
              height: '9px',
              width: '9px',
              backgroundColor: '#bbb',
              ...(val + 1 === currentFormIndex && {
                backgroundColor: props.optionalBackgroundColor ?
                  props.optionalBackgroundColor : theme.palette.primary.main,
              }),
              borderRadius: '50%',
              marginRight: '12px',
              ...(val + 1 === props.numberOfQuestions && { marginRight: '0px' }),
            }}
          />
        ))}
      </Box>
    </>
  );
}
