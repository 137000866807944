import {
  Box, Grid, LinearProgress, linearProgressClasses,
  Skeleton,
  Theme, ThemeProvider, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { ContentTheme } from './ContentTheme';
import { QuestionOption } from '../../common/models/Question';
import { getPollResults, getPollTotalVotes } from '../../api/contentAPI';
import { PollResult } from '../../common/models/content/PollResult';

export interface ContentPollAnswerViewProps {
  formId: string
  questionId: string
  questionType: string
  title?: string
  selectedOption: string
  options: QuestionOption[]
  onGetTotalVotes: (...event: unknown[]) => void;
}

export default function ContentPollAnswerView(props: ContentPollAnswerViewProps) {
  const [pollResults, setPollResults] = useState<PollResult>();

  useEffect(() => {
    const getPollResultsResponse = getPollResults(props.questionId);
    const getPollTotalVotesResponse = getPollTotalVotes(props.questionId);
    Promise.all([getPollResultsResponse, getPollTotalVotesResponse])
      .then(([pollResultsResponse, totalVotesResponse]) => {
        setPollResults(pollResultsResponse);
        props.onGetTotalVotes(totalVotesResponse);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questionId]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 56,
    marginBottom: 8,
    borderRadius: 13,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.common.white,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 13,
      backgroundColor: theme.palette.mode === 'light' ? '#65A7CF' : theme.palette.common.white,
      animationDuration: '2s',
    },
  }));

  return (
    <ThemeProvider theme={ContentTheme}>
      <Box sx={(theme: Theme) => ({
        mt: 2,
        [theme.breakpoints.down('sm')]: {
          mt: 1,
        },
      })}>
        {pollResults ? (pollResults?.results.map((item, index) => (
          <Box key={index}>
            <BorderLinearProgress variant="determinate" value={item.percentage}
              sx={{
                [`& .${linearProgressClasses.bar}`]: {
                  backgroundColor: item.value as string ===
                    props.selectedOption as string ? '#65A7CF' : '#DADADA',
                },
              }}
            />
            <Grid container spacing={1} alignItems={'center'}
              sx={{
                p: 3,
                mt: '-74px',
                position: 'relative',
              }}>
              <Grid item xs={2} sm={6} md={6}>
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}>{`${item.percentage}%`}</Typography>
              </Grid>
              <Grid item xs={10} sm={6} md={6}>
                <Typography
                  sx={{
                    textAlign: 'right',
                    fontWeight: 600,
                  }}>{item.display}</Typography>
              </Grid>
            </Grid>
          </Box>
        ))) : (props.options?.map((item, index) => (
          <Skeleton
            key={index}
            animation="wave"
            height={10}
            variant="rectangular"
            sx={{
              borderRadius: '8px',
              marginBottom: '28px',
            }}
          />
        )))
        }
      </Box>
    </ThemeProvider>);
}
