export interface ContentLinkedinPlayerProps {
  videoUrl: string;
  contentId: number;
  memberId: string;
  contentTitle?: string;
  contentCreatorName?: string | null
}

export default function ContentLinkedinPlayer(
  {
    videoUrl, contentId, memberId, contentTitle, contentCreatorName,
  }:ContentLinkedinPlayerProps,
) {
  const opts = {
    height: '400',
    width: '100%',
  };

  return (
    <iframe
    src={videoUrl}
    height={opts.height}
    width={opts.width}
    frameBorder="0"
    allowFullScreen
    title={contentTitle}>
    </iframe>
  );
}
