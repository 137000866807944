import { Box, Container, Theme } from '@mui/material';
import { useLogo } from '../../../../config/useLogo';

export interface OnboardingMobileLayoutProps {
  children?: JSX.Element;
  reduceMobileTopPadding?: boolean;
}
export default function OnboardingMobileLayout({
  children,
  reduceMobileTopPadding,
}: OnboardingMobileLayoutProps) {
  const { secondaryOne: Logo, ellipseMobile: EllipseMobile } = useLogo();

  return (
    <Box
    sx={(theme: Theme) => ({
      position: 'relative',
      paddingTop: reduceMobileTopPadding ? theme.spacing(3) : theme.spacing(10),
    })}
    >
      {EllipseMobile && <EllipseMobile style={{
        position: 'absolute',
        top: -88,
        left: 0,
      }}/>}
      <Container maxWidth='xs'
      sx={(theme: Theme) => ({
        textAlign: 'center',
      })}
      >
        {Logo && <Logo style={{
          marginBottom: '48px',
          width: '60%',
          textAlign: 'center',
        }}
          />}
        {children}
    </Container>
  </Box>
  );
}
