/* eslint-disable indent */
/* eslint-disable no-tabs */
import {
	CardMedia, Typography, Box, Theme, useMediaQuery,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectMemberSession } from '../../store/slices/onboardingSlice';
import { UpdateIsBackNavigation } from '../../store/slices/contentSlice';
import { useTracking } from '../../common/useTracking';
import { ContentEventType } from '../../common/constants';

interface ContentCardArticleProps {
	banner?: {
		url: string | undefined;
		width: number | undefined;
		height: number | undefined;
	} | null,
	title?: string | null,
	heading?: string,
	shortDescription?: string,
	OnclickListener?: () => void,
	embedVideoUrl?: string | null,
	contentId: number,
	isContentDetailScreen?: boolean,
	backUrl?: string;
	contentCreatorName?: string | null
	logo?: string | null,
}

const expandedShortDescriptionStyle = (theme: Theme) => ({
	marginTop: theme.spacing(1.5),
	[theme.breakpoints.down('sm')]: {
		marginTop: theme.spacing(0.75),
	},
	maxHeight: 'none',
	position: 'relative',
	color: theme.palette.color6.main,
} as const);

const expandedLearnMoreStyle = (theme: Theme) => ({
	borderRadius: theme.spacing(7.5),
	color: theme.palette.color9.main,
	fontWeight: 600,
	border: `${theme.spacing(0.125)} solid ${theme.palette.color9.main}`,
	width: 'fit-content',
	padding: theme.spacing(0.5),
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	cursor: 'pointer',
	marginTop: theme.spacing(1.5),
	[theme.breakpoints.down('sm')]: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: `${theme.spacing(1.875)} 0`,
		width: '100%',
	},
} as const);

export default function ContentCardArticle(props: ContentCardArticleProps) {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { memberId } = useAppSelector(selectMemberSession);
	const isDesktopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
	const trackEvent = useTracking();

	const onLearnMoreClicked = () => {
		trackEvent({
			optimusEventType: ContentEventType,
			optimusEvents: [
				{
					name: 'content_view_more_clicked',
					detail: {
						type: 'open',
						contentId: props.contentId,
						memberId,
						contentTitle: props.heading || null,
						contentCreatorName: props.contentCreatorName || null,
					},
				},
			],
		});
		props.OnclickListener?.();
	};

	const addShortDescription = (description: string) => {
		const count = isDesktopScreen ? 120 : 80;
		return description?.length > count ? `${description?.substring(0, count)}...` : description;
	};

	const goBack = () => {
		dispatch(UpdateIsBackNavigation(true));
		navigate(props.backUrl || '/notfound');
	};

	return (
		<div>
			<Box sx={(theme: Theme) => ({
				display: 'flex',
				[theme.breakpoints.down('sm')]: {
					flexDirection: 'column',
				},
			})}>

				<Box sx={(theme: Theme) => ({
					position: 'relative',
					margin: props.isContentDetailScreen ? theme.spacing(-2) : 0,
					marginBottom: '0px',
					flex: 1,
				})}>
					{props.banner ? (<CardMedia
						sx={(theme: Theme) => ({
							paddingLeft: '0px',
							paddingTop: '56.25%', // 16:9 aspect ratio
							borderRadius: props.isContentDetailScreen ? 0 : theme.spacing(1.25),
							cursor: 'pointer',
							[theme.breakpoints.down('sm')]: {
								width: '100%',
							},
						})}
						component='div'
						image={props.banner.url}
						onClick={props.OnclickListener}
					/>) : null}
					{props.isContentDetailScreen ? (<div>
						<Box onClick={goBack}
							sx={(theme: Theme) => ({
								position: 'absolute',
								display: 'flex',
								top: '0px',
								margin: theme.spacing(2),
								alignItems: 'center',
								cursor: 'pointer',
							})} >
							<ArrowBackIosIcon fontSize="small" titleAccess='Back' htmlColor='white' />
							<Typography component="div" color="white">
								{t('button.back')}
							</Typography>
						</Box>
						<Typography variant="h6" component="div" color="white"
							sx={(theme: Theme) => ({
								position: 'absolute',
								bottom: theme.spacing(2),
								left: theme.spacing(2),
								borderRadius: theme.spacing(0.625),
							})}>
							{props.heading}
						</Typography>
					</div>) : null}
				</Box>
				<Box sx={(theme: Theme) => ({
					flex: 1,
					marginLeft: theme.spacing(2.5),
					marginTop: theme.spacing(-1.5),
					width: theme.spacing(37.5),
					[theme.breakpoints.down('sm')]: {
						margin: `${theme.spacing(1.875)} 0`,
						width: '100%',
					},
				})}
				>
					{!props.isContentDetailScreen ? (<Box>
						<Typography
							sx={expandedShortDescriptionStyle}
							component='div'>
							<Typography
								sx={{
									width: '100%',
								}}
								component='div'
							>
								{props.shortDescription && addShortDescription(props.shortDescription as string)}
							</Typography>
							<Typography
								sx={expandedLearnMoreStyle}
								component='div'
								onClick={onLearnMoreClicked}
							>
								{t('content.feed.viewMore').toUpperCase()}
							</Typography>
						</Typography>
					</Box>) : null}
				</Box>
			</Box>
		</div >
	);
}
