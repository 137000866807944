import { useCompanyEnrollmentInfo } from '../../common/useCompanyEnrollmentInfo';
import { useAppSelector } from '../../store/hooks';
import { selectIsContentPreview, selectPreviewContentId } from '../../store/slices/contentSlice';
import AuthenticatedRoute from '../AuthenticatedRoute';
import { ContentViewHome } from './ContentViewHome';

export default function ContentViewHomeWrapper() {
  const isPreview = useAppSelector(selectIsContentPreview);
  const previewContentId = useAppSelector(selectPreviewContentId);

  // check company enrollment status
  useCompanyEnrollmentInfo({ calledFromOnboardingComponent: false });

  return (
    <AuthenticatedRoute>
      <ContentViewHome
        backUrl={isPreview ? `/home/preview/${previewContentId}` : '/home'} />
    </AuthenticatedRoute>
  );
}
