import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import EnglishTranslation from './english/translation.json';
import SpanishTranslation from './spanish/translation.json';

const resources: Resource = {
  en: {
    translation: EnglishTranslation,
  },
  es: {
    translation: SpanishTranslation,
  },
};

const DETECTION_OPTIONS: DetectorOptions = {
  order: ['navigator'], // detect language of user's browser
};

i18next.use(initReactI18next)
  .use(LanguageDetector) // enable user langauge detection
  .init({
    resources,
    detection: DETECTION_OPTIONS,
    fallbackLng: 'en',
  });
