import {
  Container, Link, List, ListItem, Theme, Typography, useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HomedepotLogo } from '../../../assets/images/homedepot_logo.svg';
import Step1CreateAccount from '../../../assets/images/hd_step1_1.png';
import Step1ConvertAccount from '../../../assets/images/hd_step1_2.png';
import Step2CreditCard from '../../../assets/images/hd_step2.png';
import Step3NAtionalAccountIncentives from '../../../assets/images/hd_step3_1.png';
import Step3ValidateCode from '../../../assets/images/hd_step3_2.png';
import { HDOnboardingActions } from './HDSetupInstructionsCard';
import { HDSetupFlowSteps } from '../../../common/enums';
import { useCompanyEnrollmentInfo } from '../../../common/useCompanyEnrollmentInfo';

const containerStyle = (theme: Theme) => ({
  marginTop: theme.spacing(1),
  paddingBottom: theme.spacing(6),
} as const);

const imageBoxStyle = (theme: Theme) => ({
  maxWidth: '70%',
  margin: 'auto',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
} as const);

const headingTypographyStyle = (theme: Theme) => ({
  marginBottom: theme.spacing(1),
} as const);

const liststyle = (theme: Theme) => ({
  listStyleType: 'disc',
  padding: 'revert',
  paddingLeft: theme.spacing(3),
} as const);

const listItemStyle = () => ({
  display: 'list-item',
} as const);

export function HDSetupInstructionsDetailView() {
  const { t } = useTranslation();
  const theme = useTheme();

  // check company enrollment status
  useCompanyEnrollmentInfo({ calledFromOnboardingComponent: false });

  const GoToStepLink = (step: string) => {
    const { url } = HDOnboardingActions[step as keyof typeof HDOnboardingActions];
    return (
      <Link underline="none" sx={{ color: theme.palette.color9.main }}
        target='_blank' href={url}>
        {t('homeDepot.instructionsDetail.hereLink')}
      </Link>);
  };

  return (
    <Container maxWidth={'sm'} sx={[containerStyle]}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(6),
        backgroundColor: theme.palette.common.white,
        borderRadius: '13px',
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
      }}>
        <Box sx={{ display: 'flex' }}>
          <Box>
            <HomedepotLogo />
          </Box>
          <Box sx={{ marginLeft: '20px', textAlign: 'left' }}>
            <Typography variant='body6'
              sx={{
                lineHeight: 'normal',
              }}
            >{t('homeDepot.instructionsDetail.heading')}</Typography>
            <Typography sx={{ marginTop: '0px' }}>{t('homeDepot.instructions.subHeading')}</Typography>
          </Box>
        </Box>
        {/* step 1 */}
        <Box>
          <Box>
            <Typography sx={headingTypographyStyle} variant='h2'>{t('homeDepot.instructionsDetail.step1.name')}: {t('homeDepot.instructionsDetail.step1.heading')}
            </Typography>
            <Box>
              <div>
                {t('homeDepot.instructionsDetail.step1.description1')}
              </div>
              <div style={{ marginTop: theme.spacing(2) }}> {t('homeDepot.instructionsDetail.step1.description2')} {GoToStepLink(HDSetupFlowSteps.Step1)}
              </div>
            </Box>
            <Box sx={imageBoxStyle} >
              <img src={Step1CreateAccount} alt="Sign up"
                style={{ width: '100%' }} />
            </Box>
            < Box > {t('homeDepot.instructionsDetail.step1.description3')}
              <div style={{ marginTop: theme.spacing(2) }}>
                {t('homeDepot.instructionsDetail.step1.description4')}  {GoToStepLink(HDSetupFlowSteps.Step1)} {t('homeDepot.instructionsDetail.step1.description5')}
              </div>
            </Box>
            <Box sx={imageBoxStyle} >
              <img src={Step1ConvertAccount} alt="Log in"
                style={{ width: '100%' }} />
            </Box>
          </Box>
          {/* step 2 */}
          <Box>
            <Typography sx={headingTypographyStyle} variant='h2'>{t('homeDepot.instructionsDetail.step2.name')}: {t('homeDepot.instructionsDetail.step2.heading')}
            </Typography>
            <Typography>{t('homeDepot.instructionsDetail.step2.description1')}</Typography>
            <List sx={liststyle}>
              <ListItem sx={listItemStyle}>
                {t('homeDepot.instructionsDetail.step2.subStep1')} {GoToStepLink(HDSetupFlowSteps.Step2)}
              </ListItem>
              <Box sx={imageBoxStyle} >
                <img src={Step2CreditCard} alt="Confirm Your Credit Card"
                  style={{ width: '100%' }} />
              </Box>
              <ListItem sx={listItemStyle}>{t('homeDepot.instructionsDetail.step2.subStep2')}
              </ListItem>
            </List>
          </Box>
          {/* step 3 */}
          <Box>
            <Typography sx={headingTypographyStyle} variant='h2'>{t('homeDepot.instructionsDetail.step3.name')}: {t('homeDepot.instructionsDetail.step3.heading')}
            </Typography>
            <Typography>{t('homeDepot.instructionsDetail.step3.description1')}
            </Typography>
            <List sx={liststyle}>
              <ListItem sx={listItemStyle}>
                {t('homeDepot.instructionsDetail.step3.subStep1')} {GoToStepLink(HDSetupFlowSteps.Step3)}
              </ListItem>
              <ListItem sx={listItemStyle}>
                {t('homeDepot.instructionsDetail.step3.subStep2')}
              </ListItem>
              <Box sx={imageBoxStyle} >
                <img src={Step3NAtionalAccountIncentives} alt="National Account Incentives"
                  style={{ width: '100%' }} />
              </Box>
              <ListItem sx={listItemStyle}>{t('homeDepot.instructionsDetail.step3.subStep3')}
              </ListItem>
              <Box sx={imageBoxStyle} >
                <img src={Step3ValidateCode} alt="Validate"
                  style={{ width: '100%' }} />
              </Box>
            </List>
          </Box>
          <Box>
            <Typography variant='h2'>{t('homeDepot.instructionsDetail.endMessage')}</Typography>
          </Box>
        </Box>
      </Box>
    </Container >
  );
}
