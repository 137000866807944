import {
  Box,
  Typography, Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MetaItem } from '../../common/models/content/Content';

interface ContentCardHeaderProps {
  logo?: string | null;
  title?: string | null;
  heading?: string | null;
  contentHeading?: string | null;
  publishedDate?: string;
  categoryButtonText?: string | null;
  categoryButtonColor?: string | null;
  isLiked?: boolean;
  isBookmarked?: boolean;
  metadata?: MetaItem[];
}

const categoryButtonTextColor: { [key: string]: string } = {
  ARTICLE: '#008000',
  VIDEO: '#4B369D',
  POLL: '#FE2C55',
  PODCAST: '#AA39D6',
};

const useStyles = makeStyles((theme: Theme) => ({
  headingText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  imageStyle: {
    [theme.breakpoints.up('sm')]: {
      height: '93px',
      width: '95px',
      borderRadius: '50px',
    },
  },
  categoryButtonText: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '17px',
    },
  },
}));

export default function ContentCardHeader(props: ContentCardHeaderProps) {
  const classes = useStyles();

  const getMetaData = () => {
    if (!props.metadata || props.metadata.length === 0) return '';
    const { metadata } = props;
    const allTopics = metadata.filter((item: MetaItem) => item.category.toLowerCase() === 'topic');
    return allTopics.map((item: MetaItem) => item.name.toUpperCase()).join(' · ');
  };

  return (
    <>
      <Box display={'flex'}
        sx={(theme: Theme) => ({
          marginRight: theme.spacing(-0.5),
          marginLeft: theme.spacing(-1.5),
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(-1),
          },
        })}
      >
        <Box flexGrow={1} overflow={'hidden'}>
          <Typography className={classes.headingText} sx={(theme: Theme) => ({
            fontWeight: 500,
            lineHeight: 1,
            fontSize: theme.spacing(1.75),
            marginTop: theme.spacing(1),
            letterSpacing: '-0.25px',
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.spacing(2),
              marginTop: theme.spacing(1.5),
            },
          })}
          >
            {props.metadata &&
              getMetaData()}
          </Typography>
        </Box>
        <Box sx={(theme: Theme) => ({
          marginTop: theme.spacing(0.5),
          [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
          },
        })}>
          <Typography
            className={classes.categoryButtonText}
            sx={(theme: Theme) => ({
              fontSize: `${theme.spacing(1.75)} !important`,
              borderRadius: theme.spacing(2),
              backgroundColor: categoryButtonTextColor[
                (props.categoryButtonText || '').toUpperCase()
              ] || theme.palette.primary.main,
              color: theme.palette.common.white,
              maxWidth: 'fit-content',
              p: '2px 14px',
            })}
            component="div">
            {props.categoryButtonText?.toUpperCase()}
          </Typography>
        </Box>
      </Box>
      <Typography variant="h11" component="div"
        sx={(theme: Theme) => ({
          margin: `${theme.spacing(2)} 0px 0px ${theme.spacing(-1.5)}`,
          letterSpacing: '0px',
          fontSize: theme.spacing(3),
        })}
      >
        {props && props.contentHeading}
      </Typography>
    </>
  );
}
