import {
  Alert, Grid, Snackbar, Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyLinkIcon } from '../../assets/images/copy_link.svg';
import { ReactComponent as EmailIcon } from '../../assets/images/email.svg';
import { ReactComponent as FacebookIcon } from '../../assets/images/facebook.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/images/linkedin.svg';
import { ReactComponent as WhatsAppIcon } from '../../assets/images/whatsapp.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/twitter.svg';
import { ContentInteractionAction, ContentShareDestination } from '../../common/models/content/Content';

interface ContentShareSheetProps {
  logo?: string | null,
  title?: string | null,
  heading?: string | null,
  categoryButtonText?: string | null,
  categoryButtonColor?: string | null,
  isLiked?: boolean,
  isDisliked?: boolean,
  isBookmarked?: boolean,
  shareUrl: string,
  fireContentInteractionEvent: (action: ContentInteractionAction, options?: {
    [k: string]: unknown;
  }) => void
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
})) as typeof Typography;

export default function ContentShareSheet({
  isLiked = false,
  isDisliked = false,
  shareUrl,
  fireContentInteractionEvent,
  ...props
}
  : ContentShareSheetProps) {
  const { t } = useTranslation();
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);

  const fireShareActionEvent = (destination: string) => {
    fireContentInteractionEvent(
      ContentInteractionAction.Share,
      {
        share: {
          destination,
          url: shareUrl,
        },
      },
    );
  };

  const shareOnFacebook = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
    fireShareActionEvent(ContentShareDestination.Facebook);
    window.open(facebookShareUrl, '_blank');
  };

  const shareOnTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`;
    fireShareActionEvent(ContentShareDestination.Twitter);
    window.open(twitterShareUrl, '_blank');
  };

  const shareOnLinkedIn = () => {
    const linkedInShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(shareUrl)}`;
    fireShareActionEvent(ContentShareDestination.Linkedin);
    window.open(linkedInShareUrl, '_blank');
  };

  const shareOnWhatsApp = () => {
    const summary = 'Check out this awesome content';
    const whatsAppShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(summary)} ${encodeURIComponent(shareUrl)}`;
    fireShareActionEvent(ContentShareDestination.WhatsApp);
    window.open(whatsAppShareUrl, '_blank');
  };

  const shareOnEmail = () => {
    const subject = 'Check out this awesome content';
    const body = `I thought you might find this interesting: ${shareUrl}`;

    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the user's default email app
    window.location.href = mailtoLink;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl)
      .then(() => {
        fireShareActionEvent(ContentShareDestination.Copy);
        setOpenSuccessSnackbar(true);
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
      });
  };

  const handleClose = () => {
    setOpenSuccessSnackbar(false);
  };

  return (
    <Grid container textAlign={'center'} margin={0} marginTop={1} rowGap={3}>
      <Grid item xs={4} sm={2} md={2} lg={2}
        onClick={shareOnFacebook}
      >
        <FacebookIcon />
        <StyledTypography>{t('content.shareOptions.facebook')}</StyledTypography>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}
        onClick={shareOnTwitter}
      >
        <TwitterIcon />
        <StyledTypography>{t('content.shareOptions.twitter')}</StyledTypography>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}
        onClick={shareOnLinkedIn}
      >
        <LinkedInIcon />
        <StyledTypography>{t('content.shareOptions.linkedIn')}</StyledTypography>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}
        onClick={shareOnWhatsApp}
      >
        <WhatsAppIcon />
        <StyledTypography>{t('content.shareOptions.whatsApp')}</StyledTypography>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}
        onClick={shareOnEmail}
      >
        <EmailIcon />
        <StyledTypography>{t('content.shareOptions.email')}</StyledTypography>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}>
        <CopyLinkIcon
          onClick={copyToClipboard}
        />
        <StyledTypography>{t('content.shareOptions.copyLink')}</StyledTypography>
        <Snackbar
          open={openSuccessSnackbar}
          onClose={handleClose}
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            {t('success.copyToClipboard')}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid >
  );
}
