/* eslint-disable no-shadow */
export interface ContentHeroFormat {
    ext: string;
    url: string;
    hash: string;
    mime: string;
    name: string;
    path: string | null;
    size: number;
    width: number;
    height: number;
}
export interface ContentExternalLink {
    id: number,
    target: string
}

export interface ContentLikeStatus {
    like: boolean;
    dislike: boolean;
    save: boolean;
}

export interface ContentStatus {
    [key: string]: ContentLikeStatus;
}

export interface PodcastDestination {
    platform: string,
    url: string,
}

export interface ContentPodcast {
    destinations: PodcastDestination[],
}

export interface MetaItem {
  id: string;
  name: string;
  category: string;
}

export interface Content {
    id: number,
    attributes: {
        content_collection: {
            data: {
                attributes: {
                    title: string | null,
                    icon: {
                        data: {
                            attributes: {
                                url: string,
                            }
                        }
                    }
                }
            }
        },
        content_owner: {
            data: {
                attributes: {
                    display_name: string | null,
                    icon: {
                        data: {
                            attributes: {
                                url: string,
                            }
                        }
                    }
                }
            }
        },
        short_description: string,
        title: string,
        tags: string[],
        createdAt: string,
        updatedAt: string,
        publishedAt: string,
        published_date?: string,
        source_published_date?: string,
        type: string,
        video_url: string | null,
        scrap_url: string | null,
        body: string | null,
        form_id: string | null,
        hero?: {
            data: {
                id: number,
                attributes: {
                    name: number,
                    alternativeText: null,
                    caption: null,
                    width: number,
                    height: number,
                    formats: {
                        thumbnail: ContentHeroFormat,
                        medium: ContentHeroFormat,
                        small: ContentHeroFormat
                    },
                    hash: string,
                    ext: string,
                    mime: string,
                    size: number,
                    url: string,
                    previewUrl: string | null,
                    provider: string,
                    provider_metadata: string | null,
                    createdAt: string,
                    updatedAt: string
                }
            }
        },
        banner?: {
            id: number,
            short_description: string | null,
        },
        external_link: ContentExternalLink,
        podcast?: ContentPodcast,
        metadata?: MetaItem[]
    },
    likeStatus?: ContentLikeStatus
}

export enum ContentInteractionAction {
    Like = 'like',
    Dislike = 'dislike',
    RemoveLike = 'remove like',
    RemoveDislike = 'remove dislike',
    Save = 'save',
    Unsave = 'unsave',
    Share = 'share',
    Listen = 'listen',
}

export enum FeedInteractionAction {
    Visit = 'visit',
    ReachEnd = 'reach feed end',
}

export enum SearchInteractionAction {
    Start = 'start',
    ReachEnd = 'reach results end',
}

export enum ContentShareDestination {
    Facebook = 'facebook',
    Twitter = 'twitter',
    WhatsApp = 'whatsApp',
    Linkedin = 'linkedin',
    Copy = 'copy',
    Email = 'email'
}

export enum PollInteractionAction {
    RecastVote = 'recast vote',
    Responded = 'responded'
}

export enum PodcastChannelName {
    web = 'Web',
    spotify = 'Spotify',
    apple = 'Apple Podcasts',
    google = 'Google Podcasts',
    rss = 'RSS Feed',
}
