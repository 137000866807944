import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid, IconButton, Theme, Typography, useTheme, Box, CardMedia,
} from '@mui/material';
import { styled } from '@mui/system';

import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
// import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import ShareIcon from '../../assets/images/share_icon.png';
import DisabledShareIcon from '../../assets/images/share_icon_disabled.png';
import { ContentInteractionAction, ContentLikeStatus } from '../../common/models/content/Content';
import ContentShareSheet from './ContentShareSheet';
import { useAppSelector } from '../../store/hooks';
import { selectMemberSession } from '../../store/slices/onboardingSlice';
import { selectContentList, selectIsContentPreview } from '../../store/slices/contentSlice';
// import PodcastListenSubscribeSheet from './PodcastListenSubscribeSheet';
import { useTracking } from '../../common/useTracking';
import { ContentEventType } from '../../common/constants';
import { useFeatureFlags } from '../../config/FeatureFlagsProvider';

interface ContentFooterViewProps {
  contentId: number;
  contentLikeStatus?: ContentLikeStatus;
  contentTitle?: string;
  contentCreatorName?: string | null;
  logo?: string | null;
  OnclickListener?: () => void;
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  fontSize: '13px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '22px', // Define a different font size for smaller screens
  },
})) as typeof Typography;

const useStyles = makeStyles((theme: Theme) => ({
  shareDialog: {
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(2),
      margin: theme.spacing(3),
    },
    '& .MuiDialog-paper': {
      width: '800px',
    },
    '& .MuiDialogContent-root': {
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(8),
      },
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  },
  podcastChannelDialog: {
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(2),
      margin: theme.spacing(3),
    },
    '& .MuiDialog-paper': {
      width: '500px',
    },
    '& .MuiDialogContent-root': {
      padding: '0 0 40px 24px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 0 64px 0',
      },
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  },
}));

export default function ContentFooterView({
  contentId,
  contentLikeStatus,
  contentTitle,
  contentCreatorName,
  logo,
  OnclickListener,
}: ContentFooterViewProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const contentList = useAppSelector(selectContentList);
  const content = contentList.find((item) => item.id === contentId);
  const [isSaved, setIsSaved] = useState(contentLikeStatus?.save);
  const [isLiked, setIsLiked] = useState(contentLikeStatus?.like);
  const [isDisliked, setIsDisliked] = useState(contentLikeStatus?.dislike);
  const [shareUrl, setShareUrl] = useState('');
  const [shareSheetOpen, setShareSheetOpen] = useState(false);
  // const [isPodcast, setIsPodcast] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('Share');
  const [listenSubscribeSheetOpen, setListenSubscribeSheetOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const memberSession = useAppSelector(selectMemberSession);
  const memberId = memberSession.memberId || searchParams.get('memberId');
  const isPreview = useAppSelector(selectIsContentPreview);
  const classes = useStyles();
  const trackEvent = useTracking();
  const { saveContentCardFlag } = useFeatureFlags();

  useEffect(() => {
    if (contentLikeStatus) {
      setIsLiked(contentLikeStatus.like);
      setIsDisliked(contentLikeStatus.dislike);
      setIsSaved(contentLikeStatus.save);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentLikeStatus]);

  useEffect(() => {
    if (content?.attributes?.type.toUpperCase() === 'VIDEO') {
      setShareUrl(content.attributes?.video_url || '');
    } else if (content?.attributes?.type.toUpperCase() === 'PODCAST') {
      // setIsPodcast(true);
      setDialogTitle('Listen & Subscribe');
    } else {
      setShareUrl(content?.attributes?.external_link?.target || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fireContentInteractionEvent = (action: ContentInteractionAction, options?: {
    [k: string]: unknown;
  }) => {
    trackEvent({
      optimusEventType: ContentEventType,
      optimusEvents: [
        {
          name: 'content_interaction',
          detail: {
            action,
            contentId,
            memberId,
            contentTitle: contentTitle || null,
            contentCreatorName: contentCreatorName || null,
            ...options,
          },
        },
      ],
    });
  };

  const handleContentInteraction = (action: ContentInteractionAction) => {
    switch (action) {
      case ContentInteractionAction.Like:
        // handle mutual exclusiveness
        if (isDisliked) {
          setIsDisliked(false);
        }
        setIsLiked(true);
        // fire like event
        fireContentInteractionEvent(ContentInteractionAction.Like);
        break;
      case ContentInteractionAction.RemoveLike:
        setIsLiked(false);
        // fire remove like event
        fireContentInteractionEvent(ContentInteractionAction.RemoveLike);
        break;
      case ContentInteractionAction.Dislike:
        // handle mutual exclusiveness
        if (isLiked) {
          setIsLiked(false);
        }
        setIsDisliked(true);
        // fire dislike event
        fireContentInteractionEvent(ContentInteractionAction.Dislike);
        break;
      case ContentInteractionAction.RemoveDislike:
        setIsDisliked(false);
        // fire remove dislike event
        fireContentInteractionEvent(ContentInteractionAction.RemoveDislike);
        break;
      case ContentInteractionAction.Save:
        setIsSaved(true);
        // fire save event
        fireContentInteractionEvent(ContentInteractionAction.Save);
        break;
      case ContentInteractionAction.Unsave:
        setIsSaved(false);
        // fire unsave event
        fireContentInteractionEvent(ContentInteractionAction.Unsave);
        break;
      default:
        // fire event with 'action' input parameter interaction type
        fireContentInteractionEvent(action);
        break;
    }
  };

  const handleLikeIcon = () => {
    const action = isLiked ? ContentInteractionAction.RemoveLike : ContentInteractionAction.Like;
    handleContentInteraction(action);
  };

  const handleDislikeIcon = () => {
    const action = isDisliked ? ContentInteractionAction.RemoveDislike :
      ContentInteractionAction.Dislike;
    handleContentInteraction(action);
  };

  const handleSaveIcon = () => {
    const action = isSaved ? ContentInteractionAction.Unsave : ContentInteractionAction.Save;
    handleContentInteraction(action);
  };

  const openShareSheet = () => {
    setShareSheetOpen(true);
    handleContentInteraction(ContentInteractionAction.Share);
  };

  // const openListenSubscribeSheetOpen = () => {
  //   setListenSubscribeSheetOpen(true);
  //   handleContentInteraction(ContentInteractionAction.Listen);
  // };

  const handleSheetClose = () => {
    setShareSheetOpen(false);
    setListenSubscribeSheetOpen(false);
  };

  const gridItemColumnSize = saveContentCardFlag ? 3 : 4;

  return (
    <div>
      <Box>
        <Box sx={{
          marginRight: theme.spacing(2.25),
          marginTop: theme.spacing(2.5),
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
          [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(1.25),
            marginTop: theme.spacing(-0.25),
          },
        }}
          onClick={OnclickListener}
        >
          {logo ? <CardMedia
            sx={{
              height: theme.spacing(6.75),
              width: theme.spacing(6.625),
              borderRadius: theme.spacing(3.75),
              p: theme.spacing(1.875),
              marginRight: theme.spacing(1.25),
            }}
            image={logo || ''}
          /> : <Box sx={{
            height: theme.spacing(6.75),
            width: theme.spacing(6.625),
          }}></Box>}
          <Box>{contentCreatorName}</Box>
        </Box>
      </Box>
      {isPreview ?
        <Grid container textAlign={'center'} justifyContent={'space-between'}
          margin={0} marginTop={3} color="text.secondary" sx={{ color: '#8a8a8dd4' }}>
          {/* {isPodcast ? <Grid item xs={gridItemColumnSize}>
            <IconButton disabled><PlayCircleIcon /></IconButton>
            <StyledTypography>
              {t('content.action.listen')}
            </StyledTypography>
          </Grid> : null} */}
          <Grid item xs={gridItemColumnSize} >
            <IconButton disabled><ThumbUpIcon /></IconButton>
            <StyledTypography>
              {t('content.action.like')}
            </StyledTypography>
          </Grid>
          <Grid item xs={gridItemColumnSize}>
            <IconButton disabled><ThumbDownIcon /></IconButton>
            <StyledTypography>
              {t('content.action.dislike')}
            </StyledTypography>
          </Grid>
          {saveContentCardFlag && <Grid item xs={3}>
            <IconButton disabled><BookmarkIcon /></IconButton>
            <StyledTypography>
              {t('content.action.save')}
            </StyledTypography>
          </Grid>}
          {
            shareUrl !== '' ?
              <Grid item xs={gridItemColumnSize}>
                <img src={DisabledShareIcon} alt="DisabledShareIcon" style={{
                  alignSelf: 'end', height: '22px', marginTop: '9px',
                }} />
                <StyledTypography>
                  {t('content.action.share')}
                </StyledTypography>
              </Grid> : null
          }
        </Grid > : <Grid container textAlign={'center'} justifyContent={'space-between'}
          margin={0} marginTop={3} color="text.secondary"
          sx={{ color: '#8a8a8dd4', cursor: 'pointer' }}>
          {/* {isPodcast ? <Grid item xs={gridItemColumnSize}
          onClick={openListenSubscribeSheetOpen}>
            <PlayCircleIcon sx={{ color: theme.palette.secondary.main }} />
            <StyledTypography>
              {t('content.action.listen')}
            </StyledTypography>
          </Grid> : null} */}
          <Grid item xs={gridItemColumnSize} onClick={handleLikeIcon}>
            {isLiked ? <ThumbUpIcon
              sx={{ color: '#4B369D' }}
            /> : <ThumbUpOutlinedIcon />}
            <StyledTypography>
              {t('content.action.like')}
            </StyledTypography>
          </Grid>
          <Grid item xs={gridItemColumnSize} onClick={handleDislikeIcon}>
            {isDisliked ? <ThumbDownIcon
              sx={{ color: theme.palette.primary.main }}
            /> : <ThumbDownOutlinedIcon />}
            <StyledTypography>
              {t('content.action.dislike')}
            </StyledTypography>
          </Grid>
          {saveContentCardFlag && <Grid item xs={3} onClick={handleSaveIcon}>
            {isSaved ? <BookmarkIcon
              sx={{ color: '#4B369D' }}
            /> : <BookmarkBorderOutlinedIcon />}
            <StyledTypography>
              {t('content.action.save')}
            </StyledTypography>
          </Grid>}
          <Grid item xs={gridItemColumnSize} onClick={openShareSheet} >
            <img src={ShareIcon} alt="ShareIcon" style={{
              alignSelf: 'end', height: '22px', marginTop: '2px',
            }} />
            <StyledTypography>
              {t('content.action.share')}
            </StyledTypography>
          </Grid>
        </Grid >
      }
      <Dialog
        className={shareSheetOpen ? classes.shareDialog : classes.podcastChannelDialog}
        aria-labelledby="customized-dialog-title"
        open={shareSheetOpen || listenSubscribeSheetOpen}
      >
        <DialogTitle sx={{
          m: 0,
          p: 2,
          [theme.breakpoints.down('sm')]: {
            ml: theme.spacing(3),
          },
        }}>
          <Grid container margin={0} marginTop={1}>
            <Grid item xs={10} >
              <Typography sx={{
                fontSize: '20px',
                fontWeight: '500',
                ml: theme.spacing(3),
                [theme.breakpoints.down('sm')]: {
                  ml: 0,
                },
              }}>{dialogTitle}</Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                aria-label="close"
                onClick={handleSheetClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 15,
                  color: 'black',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {/* {isPodcast ? <PodcastListenSubscribeSheet
            contentId={contentId}
            contentCreatorName={contentCreatorName}
            contentTitle={contentTitle}
            podcast={content?.attributes?.podcast}
          /> : */}
          <ContentShareSheet shareUrl={shareUrl}
            fireContentInteractionEvent={fireContentInteractionEvent} />
          {/* } */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
