/* eslint-disable */
import {
    Grid, Box, useTheme, Typography
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import Ellipse from '../../assets/images/rewards_history_ellipses.svg';
import { useEffect, useState } from 'react';

const RewardEarned = ({
    isMobile,
    showRewardHistory,
    setShowRewardHistory,
    rewards,
}:
    {
        isMobile: boolean,
        showRewardHistory: boolean,
        setShowRewardHistory: (value: boolean) => void,
        rewards: { year: number, total: string }[]
    }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [hasRewards, setHasRewards] = useState(false);
    const [totalRewards, setTotalRewards] = useState(0);

    useEffect(() => {
        const rewardsTotal = rewards.reduce((accumulator, currentValue) => {
            return (accumulator + parseFloat(currentValue.total));
        }, 0);
        setHasRewards(rewardsTotal > 0)
        setTotalRewards(rewardsTotal);
    }, [rewards])

    return (
        <Box
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    color: theme.palette.color7.main,
                    maxWidth: theme.spacing(100),
                    borderRadius: theme.spacing(1.25),
                    borderBottomLeftRadius: showRewardHistory && rewards.length > 0 ? '0px' : theme.spacing(1.25),
                    borderBottomRightRadius: showRewardHistory && rewards.length > 0 ? '0px' : theme.spacing(1.25),
                    backgroundImage: `url(${isMobile ? Ellipse : Ellipse})`,
                    backgroundColor: theme.palette.secondary.main,
                    height: theme.spacing(18.75),
                    backgroundPosition: 'right bottom',
                    backgroundRepeat: `no-repeat`,
                }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography component='span' variant='body3'>
                            {
                                hasRewards ? showRewardHistory ? t('dashboard.totalRewards') : t('dashboard.earnedRewards')
                                    : t('dashboard.checkBackLater')
                            }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component='span' variant='h8'>
                            {
                                hasRewards ? `$${totalRewards}` : t('dashboard.noRewards')
                            }
                        </Typography>
                    </Grid>
                    {
                        hasRewards && (
                            <Grid onClick={() => setShowRewardHistory(!showRewardHistory)} item
                                sx={
                                    {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        cursor: 'pointer',
                                    }
                                }
                            >
                                <Typography component='span' variant='body7'
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        cursor: 'pointer',
                                    }}>
                                    {t('dashboard.viewPayoutHistory')}
                                    {showRewardHistory ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                                </Typography>
                            </Grid>
                        )
                    }

                </Grid>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {showRewardHistory && rewards.map((reward, index) => (
                    <Box
                        key={reward.year}
                        sx={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            maxWidth: '800px',
                            padding: theme.spacing(2.5),
                            backgroundColor: theme.palette.common.white,
                            borderRadius: '0',
                            borderBottomLeftRadius: index === rewards.length - 1 ? theme.spacing(1.25) : '0',
                            borderBottomRightRadius: index === rewards.length - 1 ? theme.spacing(1.25) : '0',
                            border: `1px solid ${theme.palette.color10.main}`,
                        }}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    width: '100%',
                                    color: theme.palette.color10.main,
                                }}
                            >
                                <Typography component='span' variant='body1'>
                                    {reward.year}
                                </Typography>
                                <Typography component='span' variant='body1'>
                                    ${reward.total}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default RewardEarned;
